// import react
import React from 'react'

// import custom modules from the global.js file
import { isFloatingNumber } from '../../../../global'

// import custom stylesheet
import './PrevCumulativeGPA.scss'


/*
    :::::PROPS:::::
    prevGPA => a hook, to store previous gpa of the student
    setPrevGPA => to set state of the previous gpa
    prevTotalCredits => a hook, to store total credits of the previous gpa
    setPrevTotalCredits => to set the state of total credits of the previous gpa
    isWeighted => true, if user has checked the 'Weighted' switch
*/
const PrevCumulativeGPA = ({prevGPA, setPrevGPA, prevTotalCredits, setPrevTotalCredits, isWeighted, isCollegeGPABtnActive}) => {

    // to update value of the previous gpa
    const handlePrevGPAChange = (e) => {
        const value = e.target.value;

        // to check either value in previous gpa is a valid floating point number
        // upto decimal places or not
        if (isFloatingNumber(value, 2)){
            if(value !== ''){
                const tempPrevGPA = parseFloat(value);
                if(!isWeighted && ((isCollegeGPABtnActive && tempPrevGPA > 4.33) || (!isCollegeGPABtnActive && tempPrevGPA > 4.30))){
                    return;
                }
            }
            setPrevGPA(value);
        }
    }
    
    return (
        <div className='prev-cumulative-gpa-wrapper row '>
            <p className="col xl8 l6 m6 s12">Cumulative GPA to Date:</p>            
            <div className='right-section col xl4 l6 m6 s12 row'>
                <div className="prev-gpa-container col m6 s12 ">
                    <label className="valign-wrapper">
                        <p className="white-text">GPA</p>
                        <input
                            onChange={handlePrevGPAChange}
                            value={prevGPA ? prevGPA : ""}
                            type='text' placeholder={"--"} style={{
                                border: 'none'
                            }}
                        />
                    </label>
                </div>
                <div className="total-credits-container col m6 s12 row">
                    <label className="valign-wrapper">
                        <p className="white-text">Total Credits</p>
                        <input
                            onChange={(e) => { isFloatingNumber(e.target.value, 1) && setPrevTotalCredits(e.target.value) }}
                            value={prevTotalCredits ? prevTotalCredits : ""}
                            type='text' placeholder={"--"} style={{
                                border: 'none'
                            }}
                        />
                    </label>
                </div>
            </div>
        </div>
    )
}

export default PrevCumulativeGPA
