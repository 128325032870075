import React, {useEffect} from "react";
import axios from 'axios'
import {SERVER_IP} from './IP';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
import ArtworkPage from "./components/ArtworkPage/ArtworkPage";

const App = () => {

  /**
   * To satrt server on page load, if it is not running.
   * To avoid cold start time of server
  **/
  useEffect(() => {
    axios.get(`${SERVER_IP}/init`);
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route path="/artwork" component={ArtworkPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
