// import react components
import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";

// import custom components
import Calculator from "./Calculator/Calculator";
import Artwork from "./Artwork/Artwork";
import ScatterPlot from "./Chart/ScatterPlot";
import GPASummary from "./GPASummary/GPASummary";

import { RECAPTCHA_SITE_KEY } from '../../IP';

// import custom stylesheet
import './MainPage.scss';



// i case if google is down
window.recaptchaOptions = {
    useRecaptchaNet: true,
};


const SCHOOL_DROPDOWN_LIST = ["Medical School", "PhD Programs", "Law School"];

const MainPage = () => {
    const [CGPA, setCGPA] = useState(null);     // student cgpa
    const [CGPATotalCredits, setCGPATotalCredits] = useState(null); // total credit hours used to calculate cgpa
    const [CGPATotalGrade, setCGPATotalGrade] = useState(null);
    const [requiredGPA, setRequiredGPA] = useState(null);   // reuired gpa i.e gpa needed for remaining credits
    const [remainingCredits, setRemainingCredits] = useState(null); // estimated remaining credits
    const [estimatedRemainingCredits, setEstimatedRemainingCredits] = useState(null);
    const [isWeighted, setIsWeighted] = useState(false);    // is weighted gpa?
    const [avgSemesterCredits, setAvgSemesterCredits] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);  // to check either modal is open or close
    const [isCollegeGPABtnActive, setIsCollegeGPABtnActive] = useState(true); // to store either 'college gpa' button is actived or not
    const [isArtworkCompleted, setIsArtworkCompleted] = useState(false);  // to display charts, artworks, cgpa etc
    const [temporaryCGPA, setTemporaryCGPA] = useState(null);
    const [minRangeValue, setMinRangeValue] = useState(0);       // minimum value of the range    
    const [maxRangeValue, setMaxRangeValue] = useState(4.33);    // maximum value of the range
    const [artSeedValue, setArtSeedValue] = useState("");
    const [targetGPA, setTargetGPA] = useState(CGPA ? CGPA : "1.00");   // value of the range slider
    const [tempTargetGPA, setTempTargetGPA] = useState(targetGPA);
    const [captchaCount, setCaptchaCount] = useState(0);
    const recaptchaRef = useRef(null);
    const [isLandscape, setIsLanscape] = useState(false);
    const [maxValidValidGPA, setMaxValidGPA] = useState(null);
    const [isMaxValidReached, setIsMaxValidReached] = useState(false);


    useEffect(() => {
        setIsLanscape(window.orientation == 90)
        window.addEventListener("orientationchange", function () {
            setIsLanscape(window.orientation == 90)
        }, false);
    }, [])

    useEffect(() => {
        setMaxValidGPA(isCollegeGPABtnActive ? 4.30 :
            !isWeighted && !isCollegeGPABtnActive ? 4.00 :
                isWeighted && !isCollegeGPABtnActive ? 5.00 :
                    null)
    }, [isCollegeGPABtnActive, isWeighted])


    /**
     * FORMULA to calculate TARGET-GPA
     * Required GPA = ((Target GPA * (CGPA Total Credits + Remaining Credits)) - (CGPA * CGPA Total Credits)) / Remaining Credits
    **/

    const calculateRequiredGPA = (tempTargetGPAFloat, CGPATotalCreditsFloat, remainingCreditsFloat, CGPAFLoat) => {
        return ((tempTargetGPAFloat * (CGPATotalCreditsFloat + remainingCreditsFloat)) - (CGPAFLoat * CGPATotalCreditsFloat)) / remainingCreditsFloat;
    }
    const calculateRequiredGPAWrapper = () => {
        let tempReqGPA = null;
        
        if (modalIsOpen || !isArtworkCompleted) return;

        let errorContainer = document.querySelector(".gpa-summary-calculations");
        
        if(remainingCredits <= 0){
            setRequiredGPA(null);
            errorContainer.classList.remove('gpa-summary-calculations-invalid');
            return;
        }

        let CGPAFLoat = parseFloat(CGPA), CGPATotalCreditsFloat = parseFloat(CGPATotalCredits),
            tempTargetGPAFloat = parseFloat(tempTargetGPA), remainingCreditsFloat = parseFloat(remainingCredits);

        if (CGPA && !isNaN(CGPAFLoat) && CGPATotalCredits && !isNaN(CGPATotalCreditsFloat) && tempTargetGPA && !isNaN(tempTargetGPAFloat) && !isNaN(remainingCreditsFloat)) {
            tempReqGPA = remainingCreditsFloat <= 0 ? -1 : calculateRequiredGPA(tempTargetGPAFloat, CGPATotalCreditsFloat, remainingCreditsFloat, CGPAFLoat);

            tempReqGPA = +parseFloat(tempReqGPA).toFixed(2);

            // if rquired cgpa is less than 0
            // or required cgpa is greater than 4.33, in case of unweighted
            if (tempReqGPA < 0.00 || tempReqGPA > maxValidValidGPA) {

                /* 
                    Handle error display here
                */
                tempReqGPA = null;
                errorContainer.classList.add('gpa-summary-calculations-invalid');
            }
            else {

                tempReqGPA = tempReqGPA.toFixed(2);
                errorContainer.classList.remove('gpa-summary-calculations-invalid');
            }
        }
        else {
            tempReqGPA = null;
            errorContainer.classList.remove('gpa-summary-calculations-invalid');
        }
        setRequiredGPA(tempReqGPA)
    }
    useEffect(() => {
        calculateRequiredGPAWrapper();
    }, [CGPA, CGPATotalCredits, tempTargetGPA, remainingCredits,  isWeighted, isArtworkCompleted, modalIsOpen, estimatedRemainingCredits]);



    /** 
     * To store state of recaptcha in local storage
    **/
    const storeCaptchaStatus = (onLoad = false) => {

        // reset recptcha token
        recaptchaRef.current.reset();

        // get recptcha status detail from local storage
        let item = window.localStorage.getItem("captchaStatus");

        //it converts day into milliseconds
        const daysToMilliSeconds = (days) => days * 24 * 60 * 60 * 1000;

        const now = new Date();

        // set recptcha state default values
        let count = 1;
        let expiry = now.getTime() + daysToMilliSeconds(7);

        // if state already exists
        if(item !== null){
            item = JSON.parse(item);

            // if expiry date i.e 7 days, reset recaptcha status
            if(now.getTime() > item.expiry){
                window.localStorage.removeItem("captchaStatus");
            }
            else{
                count = item.count + 1;
                expiry = item.expiry;
            }
        }
        else{
            if(onLoad){
                count = 0;
            }
        }
        
        if (onLoad) {
            setCaptchaCount(count);
            return;
        }

        const captchaStatus = {
            count: count,
            expiry: expiry
        }
        window.localStorage.setItem("captchaStatus", JSON.stringify(captchaStatus));

        setCaptchaCount(count);
    }

    useEffect(() => {
        storeCaptchaStatus(true);
    }, [])

    return (
        <div className="main-page-container">
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="stylesheet" href="css/index.css" />
            </Helmet>
            <Calculator
                CGPA={CGPA} setCGPA={setCGPA}
                setTemporaryCGPA={setTemporaryCGPA}
                avgSemesterCredits={avgSemesterCredits}
                setAvgSemesterCredits={setAvgSemesterCredits}
                setRemainingCredits={setRemainingCredits} setEstimatedRemainingCredits={setEstimatedRemainingCredits}
                CGPATotalCredits={CGPATotalCredits} setCGPATotalCredits={setCGPATotalCredits}
                CGPATotalGrade={CGPATotalGrade} setCGPATotalGrade={setCGPATotalGrade}
                isWeighted={isWeighted} setIsWeighted={setIsWeighted}
                modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}
                isCollegeGPABtnActive={isCollegeGPABtnActive} setIsCollegeGPABtnActive={setIsCollegeGPABtnActive}
                isArtworkCompleted={isArtworkCompleted} setMaxRangeValue={setMaxRangeValue}
                artSeedValue={artSeedValue} setArtSeedValue={setArtSeedValue}
                storeCaptchaStatus={storeCaptchaStatus} recaptchaRef={recaptchaRef} captchaCount={captchaCount}
            />

            <div className="recaptcha-container" style={{display: captchaCount >= 15 ? "flex": "none"}}>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    theme="dark"
                    type="image"
                    sitekey={RECAPTCHA_SITE_KEY}
                />
            </div>

            <Artwork
                isLandscape={isLandscape}
                modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}
                isArtworkCompleted={isArtworkCompleted} setIsArtworkCompleted={setIsArtworkCompleted}
                CGPA = {CGPA} temporaryCGPA={temporaryCGPA} CGPATotalCredits={CGPATotalCredits}
                artSeedValue={artSeedValue} CGPATotalGrade={CGPATotalGrade}
            />
            
            {(!modalIsOpen && isArtworkCompleted) &&
                <>
                    {
                        !isCollegeGPABtnActive &&
                        <ScatterPlot
                            isLandscape={isLandscape}
                            CGPA={CGPA}
                            title="High School GPA Averages for Accepted Undergraduate Students"
                            dropDownList={["College"]} isForCollege={true}
                            minRangeValue={minRangeValue} setMinRangeValue={setMinRangeValue}
                            maxRangeValue={maxRangeValue} setMaxRangeValue={setMaxRangeValue} isWeighted={isWeighted}
                            rangeValue={targetGPA} setRangeValue={setTargetGPA}
                            shouldRemoveChartTitle={true} tempTargetGPA={tempTargetGPA}
                            setIsMaxValidReached={setIsMaxValidReached}
                        />
                    }

                    {
                        isCollegeGPABtnActive &&
                        <ScatterPlot
                            isLandscape={isLandscape}
                            CGPA={CGPA}
                            title="College GPA Averages for Accepted Graduate Program Students"
                            dropDownList={SCHOOL_DROPDOWN_LIST} isForCollege={false}
                            minRangeValue={minRangeValue} setMinRangeValue={setMinRangeValue}
                            maxRangeValue={maxRangeValue} setMaxRangeValue={setMaxRangeValue} isWeighted={isWeighted}
                            rangeValue={targetGPA} setRangeValue={setTargetGPA}
                            shouldRemoveChartTitle={false}  tempTargetGPA={tempTargetGPA} setTempTargetGPA={setTempTargetGPA}
                            setIsMaxValidReached={setIsMaxValidReached}
                        />
                    }
                    <GPASummary
                        isCollegeGPABtnActive={isCollegeGPABtnActive}
                        CGPA={CGPA} isWeighted={isWeighted} requiredGPA={requiredGPA}
                        minRangeValue={minRangeValue} maxRangeValue={maxRangeValue}
                        targetGPA={targetGPA} setTargetGPA={setTargetGPA}
                        tempTargetGPA={tempTargetGPA} setTempTargetGPA={setTempTargetGPA}
                        remainingCredits={remainingCredits} setRemainingCredits={setRemainingCredits}
                        estimatedRemainingCredits={estimatedRemainingCredits} isArtworkCompleted={isArtworkCompleted}
                        maxValidValidGPA={maxValidValidGPA}
                        isMaxValidReached={isMaxValidReached} setIsMaxValidReached={setIsMaxValidReached}
                    />


                </>
            }
            {
                <div className="footer-q-container">
                    <div className='footer-question'>

                        <div className="desktop-cta hide-on-small-only">

                            What would your GPA be if <br/>you
                            could <span>remember <span className="rainbow">everything</span></span>
                            <br/>
                            you've ever studied?

                        </div>

                        <div className="mobile-cta hide-on-med-and-up">
                            
                            What would your <br/> GPA be if you could <br/> <span>remember <span className="rainbow">everything</span></span>
                            <br/>
                            you've ever studied?


                            <div className="mobile-cta-circle">

                            </div>
                            
                        </div>

                        
                    </div>

                    <button className='discover-how-btn' onClick={() => window.open("https://memorymaps.io?ref=gpacalculator.memorymaps.io/", '_blank').focus()} style={{cursor: 'pointer'}}>
                        <p>
                            <p className="discover-line">Discover How with</p>
                            <p className="discover-line">memory maps</p>
                        </p>
                    </button>
                </div>
            }
        </div>
    );
}

export default MainPage;