// import react components
import React from 'react';
import { hydrate, render } from "react-dom";
// import * as serviceWorker from './serviceWorker';

// import custom components
import reportWebVitals from './reportWebVitals';
import App from './App'

const rootElement = document.getElementById("root");
document.addEventListener('DOMContentLoaded', function() {
    render(<App />, rootElement);
});

// if (rootElement.hasChildNodes()) {
//     hydrate(<App />, rootElement);
// } else {
//     render(<App />, rootElement);
// }
reportWebVitals();
// serviceWorker.register();
