// import custom stylesheet
import './ChartSlider.scss';

import React from 'react';

/*
    :::::PROPS:::::
    rangeSliderRef => Reference to the range slider
    rangeValue => a hook, to store value of range slider
    setRangeValue => to set state of range slider
*/
const ChartSlider = ({minRangeValue, isForCollege, maxRangeValue, rangeSliderRef, rangeValue, setRangeValue, setIsMaxValidReached}) => {
    // update states, if value of range slider is changed
    const handleSliderOnChange = (e) => {
        const value = parseFloat(e.target.value).toFixed(2);
        setRangeValue(value);
    }

    return (
        <div className='slider-container'>
            <div className={`range-container ${isForCollege ? "college-slider" : "not-college-slider"}`}>            

                <input
                    ref={rangeSliderRef} id="chartSlider" value={rangeValue ? rangeValue : ""}
                    onChange={(e) => { handleSliderOnChange(e); setIsMaxValidReached(false); }} onTouchStart={handleSliderOnChange}
                    onTouchEnd={handleSliderOnChange} onTouchMove={handleSliderOnChange} onMouseMove={handleSliderOnChange}
                    className={`range`} type="range" name="chartRange" min={minRangeValue} max={maxRangeValue} step="0.01" />

            </div>
            <span className="hide">{rangeValue}</span>
        </div >
    );
}

export default ChartSlider;