"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (randFunc) {
  return function () {
    var min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    var max = arguments[1];

    var mi = min;
    var ma = max;

    if (ma === undefined) {
      ma = mi;
      mi = 0;
    }

    return randFunc() * (ma - mi) + mi;
  };
};