export const HighSchool_Data = [
    {
        "University": "Stanford University",
        "AverageGPA": 4.18,
        "AverageSAT": 1503,
        "AcceptanceRate": 4.34
    },
    {
        "University": "Harvard University",
        "AverageGPA": 4.04,
        "AverageSAT": 1517,
        "AcceptanceRate": 4.64
    },
    {
        "University": "Columbia University in the City of New York",
        "AverageGPA": 3.91,
        "AverageSAT": 1511,
        "AcceptanceRate": 5.45
    },
    {
        "University": "Princeton University",
        "AverageGPA": 3.87,
        "AverageSAT": 1517,
        "AcceptanceRate": 5.78
    },
    {
        "University": "Yale University",
        "AverageGPA": 3.95,
        "AverageSAT": 1517,
        "AcceptanceRate": 6.08
    },
    {
        "University": "University of Chicago",
        "AverageGPA": 4,
        "AverageSAT": 1528,
        "AcceptanceRate": 6.17
    },
    {
        "University": "California Institute of Technology",
        "AverageGPA": 3.97,
        "AverageSAT": 1557,
        "AcceptanceRate": 6.42
    },
    {
        "University": "Massachusetts Institute of Technology",
        "AverageGPA": 3.96,
        "AverageSAT": 1547,
        "AcceptanceRate": 6.7
    },
    {
        "University": "Brown University",
        "AverageGPA": 3.94,
        "AverageSAT": 1511,
        "AcceptanceRate": 7.07
    },
    {
        "University": "Pomona College",
        "AverageGPA": 3.89,
        "AverageSAT": 1480,
        "AcceptanceRate": 7.4
    },
    {
        "University": "Duke University",
        "AverageGPA": 3.94,
        "AverageSAT": 1522,
        "AcceptanceRate": 7.6
    },
    {
        "University": "University of Pennsylvania",
        "AverageGPA": 3.9,
        "AverageSAT": 1511,
        "AcceptanceRate": 7.66
    },
    {
        "University": "Dartmouth College",
        "AverageGPA": 3.9,
        "AverageSAT": 1500,
        "AcceptanceRate": 7.93
    },
    {
        "University": "United States Naval Academy",
        "AverageGPA": 3.8,
        "AverageSAT": 1348,
        "AcceptanceRate": 8.33
    },
    {
        "University": "Rice University",
        "AverageGPA": 3.96,
        "AverageSAT": 1520,
        "AcceptanceRate": 8.72
    },
    {
        "University": "Swarthmore College",
        "AverageGPA": 3.9,
        "AverageSAT": 1469,
        "AcceptanceRate": 8.93
    },
    {
        "University": "Northwestern University",
        "AverageGPA": 3.92,
        "AverageSAT": 1506,
        "AcceptanceRate": 9.05
    },
    {
        "University": "Vanderbilt University",
        "AverageGPA": 3.76,
        "AverageSAT": 1515,
        "AcceptanceRate": 9.12
    },
    {
        "University": "Cornell University",
        "AverageGPA": 3.9,
        "AverageSAT": 1487,
        "AcceptanceRate": 10.85
    },
    {
        "University": "University of Southern California",
        "AverageGPA": 3.73,
        "AverageSAT": 1453,
        "AcceptanceRate": 11.42
    },
    {
        "University": "University of California-Los Angeles",
        "AverageGPA": 4.29,
        "AverageSAT": 1429,
        "AcceptanceRate": 12.32
    },
    {
        "University": "Williams College",
        "AverageGPA": 3.94,
        "AverageSAT": 1488,
        "AcceptanceRate": 12.6
    },
    {
        "University": "Tulane University of Louisiana",
        "AverageGPA": 3.5,
        "AverageSAT": 1444,
        "AcceptanceRate": 12.87
    },
    {
        "University": "Washington University in St Louis",
        "AverageGPA": 3.93,
        "AverageSAT": 1520,
        "AcceptanceRate": 13.85
    },
    {
        "University": "Georgetown University",
        "AverageGPA": 3.89,
        "AverageSAT": 1473,
        "AcceptanceRate": 14.36
    },
    {
        "University": "Tufts University",
        "AverageGPA": 3.91,
        "AverageSAT": 1472,
        "AcceptanceRate": 14.95
    },
    {
        "University": "Carnegie Mellon University",
        "AverageGPA": 3.69,
        "AverageSAT": 1513,
        "AcceptanceRate": 15.44
    },
    {
        "University": "University of Notre Dame",
        "AverageGPA": 3.91,
        "AverageSAT": 1490,
        "AcceptanceRate": 15.83
    },
    {
        "University": "New York University",
        "AverageGPA": 3.6,
        "AverageSAT": 1443,
        "AcceptanceRate": 16.2
    },
    {
        "University": "University of California-Berkeley",
        "AverageGPA": 3.86,
        "AverageSAT": 1449,
        "AcceptanceRate": 16.25
    },
    {
        "University": "Emory University",
        "AverageGPA": 3.8,
        "AverageSAT": 1450,
        "AcceptanceRate": 17.18
    },
    {
        "University": "Boston University",
        "AverageGPA": 3.59,
        "AverageSAT": 1433,
        "AcceptanceRate": 18.94
    },
    {
        "University": "Georgia Institute of Technology-Main Campus",
        "AverageGPA": 3.95,
        "AverageSAT": 1418,
        "AcceptanceRate": 20.58
    },
    {
        "University": "University of Michigan-Ann Arbor",
        "AverageGPA": 3.82,
        "AverageSAT": 1448,
        "AcceptanceRate": 22.91
    },
    {
        "University": "University of Virginia-Main Campus",
        "AverageGPA": 4.22,
        "AverageSAT": 1436,
        "AcceptanceRate": 23.94
    },
    {
        "University": "University of California-Irvine",
        "AverageGPA": 3.92,
        "AverageSAT": 1306,
        "AcceptanceRate": 26.54
    },
    {
        "University": "University of Miami",
        "AverageGPA": 4.2,
        "AverageSAT": 1371,
        "AcceptanceRate": 27.13
    },
    {
        "University": "Boston College",
        "AverageGPA": 3.86,
        "AverageSAT": 1437,
        "AcceptanceRate": 27.22
    },
    {
        "University": "Villanova University",
        "AverageGPA": 3.86,
        "AverageSAT": 1422,
        "AcceptanceRate": 28.24
    },
    {
        "University": "California Polytechnic State University-San Luis Obispo",
        "AverageGPA": 3.87,
        "AverageSAT": 1342,
        "AcceptanceRate": 28.42
    },
    {
        "University": "University of California-San Diego",
        "AverageGPA": 3.82,
        "AverageSAT": 1375,
        "AcceptanceRate": 31.48
    },
    {
        "University": "The University of Texas at Austin",
        "AverageGPA": 3.8,
        "AverageSAT": 1367,
        "AcceptanceRate": 31.82
    },
    {
        "University": "Florida State University",
        "AverageGPA": 3.88,
        "AverageSAT": 1284,
        "AcceptanceRate": 35.97
    },
    {
        "University": "University of Florida",
        "AverageGPA": 4.1,
        "AverageSAT": 1382,
        "AcceptanceRate": 36.18
    },
    {
        "University": "University of California-Davis",
        "AverageGPA": 3.99,
        "AverageSAT": 1298,
        "AcceptanceRate": 38.87
    },
    {
        "University": "Texas A & M University-College Station",
        "AverageGPA": 3.68,
        "AverageSAT": 1296,
        "AcceptanceRate": 57.79
    },
    {
        "University": "University of Georgia",
        "AverageGPA": 3.86,
        "AverageSAT": 1347,
        "AcceptanceRate": 45.3
    },
    {
        "University": "Northern Illinois University",
        "AverageGPA": 3.15,
        "AverageSAT": 1070,
        "AcceptanceRate": 48.35
    },
    {
        "University": "University of California-Santa Cruz",
        "AverageGPA": 3.73,
        "AverageSAT": 1306,
        "AcceptanceRate": 51.31
    },
    {
        "University": "University of Washington-Seattle Campus",
        "AverageGPA": 3.75,
        "AverageSAT": 1316,
        "AcceptanceRate": 54.98
    },
    {
        "University": "Colorado School of Mines",
        "AverageGPA": 3.8,
        "AverageSAT": 1342,
        "AcceptanceRate": 53.08
    },
    {
        "University": "Ohio State University-Main Campus",
        "AverageGPA": 3.76,
        "AverageSAT": 1372,
        "AcceptanceRate": 53.74
    },
    {
        "University": "Mississippi State University",
        "AverageGPA": 3.32,
        "AverageSAT": 1246,
        "AcceptanceRate": 53.86
    },
    {
        "University": "University of Wisconsin Madison",
        "AverageGPA": 3.84,
        "AverageSAT": 1371,
        "AcceptanceRate": 54.41
    },
    {
        "University": "New Mexico State University-Main Campus",
        "AverageGPA": 3.19,
        "AverageSAT": 1061,
        "AcceptanceRate": 54.58
    },
    {
        "University": "University of Hawaii at Manoa",
        "AverageGPA": 3.46,
        "AverageSAT": 1170,
        "AcceptanceRate": 58.47
    },
    {
        "University": "University of Illinois Urbana-Champaign",
        "AverageGPA": 3.79,
        "AverageSAT": 1342,
        "AcceptanceRate": 59.03
    },
    {
        "University": "Purdue University-Main Campus",
        "AverageGPA": 3.7,
        "AverageSAT": 1321,
        "AcceptanceRate": 59.83
    },
    {
        "University": "San Jose State University",
        "AverageGPA": 3.31,
        "AverageSAT": 1139,
        "AcceptanceRate": 63.57
    },
    {
        "University": "University of Massachusetts-Amherst",
        "AverageGPA": 3.73,
        "AverageSAT": 1299,
        "AcceptanceRate": 63.8
    },
    {
        "University": "Oklahoma State University-Main Campus",
        "AverageGPA": 3.53,
        "AverageSAT": 1209,
        "AcceptanceRate": 69.98
    },
    {
        "University": "Washington State University",
        "AverageGPA": 3.3,
        "AverageSAT": 1131,
        "AcceptanceRate": 75.8
    },
    {
        "University": "University of Cincinnati-Main Campus",
        "AverageGPA": 3.46,
        "AverageSAT": 1247,
        "AcceptanceRate": 76.67
    },
    {
        "University": "The University of Tennessee-Knoxville",
        "AverageGPA": 3.85,
        "AverageSAT": 1276,
        "AcceptanceRate": 78.85
    },
    {
        "University": "University of Oregon",
        "AverageGPA": 3.6,
        "AverageSAT": 1210,
        "AcceptanceRate": 81.8
    },
    {
        "University": "Oregon State University",
        "AverageGPA": 3.56,
        "AverageSAT": 1200,
        "AcceptanceRate": 81.92
    },
    {
        "University": "Ohio University-Main Campus",
        "AverageGPA": 3.42,
        "AverageSAT": 1167,
        "AcceptanceRate": 82.07
    }
]
/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */



export const MedicalSchool_Data = [
    {
        "University": "George Washington University",
        "AverageGPA": 3.7,
        "AverageMCAT": 512,
        "AcceptanceRate": 1.9
    },
    {
        "University": "University of Texas--Austin (Dell)",
        "AverageGPA": 3.74,
        "AverageMCAT": 513,
        "AcceptanceRate": 2
    },
    {
        "University": "Virginia Tech Carilion School of Medicine",
        "AverageGPA": 3.6,
        "AverageMCAT": 512,
        "AcceptanceRate": 2
    },
    {
        "University": "Stanford University",
        "AverageGPA": 3.89,
        "AverageMCAT": 519,
        "AcceptanceRate": 2.3
    },
    {
        "University": "University of Arizona Tucson",
        "AverageGPA": 3.72,
        "AverageMCAT": 509,
        "AcceptanceRate": 2.3
    },
    {
        "University": "Mayo Clinic School of Medicine",
        "AverageGPA": 3.92,
        "AverageMCAT": 520,
        "AcceptanceRate": 2.4
    },
    {
        "University": "University of California--Los Angeles (Geffen)",
        "AverageGPA": 3.83,
        "AverageMCAT": 517,
        "AcceptanceRate": 2.4
    },
    {
        "University": "New York University (Langone)",
        "AverageGPA": 3.96,
        "AverageMCAT": 522,
        "AcceptanceRate": 2.5
    },
    {
        "University": "Brown University (Alpert)",
        "AverageGPA": 3.77,
        "AverageMCAT": 514,
        "AcceptanceRate": 3
    },
    {
        "University": "Florida State University",
        "AverageGPA": 3.76,
        "AverageMCAT": 505,
        "AcceptanceRate": 3
    },
    {
        "University": "Georgetown University",
        "AverageGPA": 3.63,
        "AverageMCAT": 512,
        "AcceptanceRate": 3
    },
    {
        "University": "University of California--Davis",
        "AverageGPA": 3.63,
        "AverageMCAT": 510,
        "AcceptanceRate": 3
    },
    {
        "University": "Wake Forest University",
        "AverageGPA": 3.67,
        "AverageMCAT": 513,
        "AcceptanceRate": 3
    },
    {
        "University": "Harvard University",
        "AverageGPA": 3.93,
        "AverageMCAT": 519,
        "AcceptanceRate": 3.3
    },
    {
        "University": "Columbia University",
        "AverageGPA": 3.9,
        "AverageMCAT": 521,
        "AcceptanceRate": 3.5
    },
    {
        "University": "University of Colorado",
        "AverageGPA": 3.82,
        "AverageMCAT": 512,
        "AcceptanceRate": 3.7
    },
    {
        "University": "University of California--San Diego",
        "AverageGPA": 3.8,
        "AverageMCAT": 516,
        "AcceptanceRate": 3.8
    },
    {
        "University": "University of California--San Francisco",
        "AverageGPA": 3.84,
        "AverageMCAT": 518,
        "AcceptanceRate": 3.8
    },
    {
        "University": "Dartmouth College (Geisel)",
        "AverageGPA": 3.66,
        "AverageMCAT": 514,
        "AcceptanceRate": 4
    },
    {
        "University": "Duke University",
        "AverageGPA": 3.86,
        "AverageMCAT": 519,
        "AcceptanceRate": 4
    },
    {
        "University": "Emory University",
        "AverageGPA": 3.7,
        "AverageMCAT": 514,
        "AcceptanceRate": 4
    },
    {
        "University": "Michigan State University (College of Human Medicine)",
        "AverageGPA": 3.58,
        "AverageMCAT": 506,
        "AcceptanceRate": 4
    },
    {
        "University": "Oregon Health and Science University",
        "AverageGPA": 3.7,
        "AverageMCAT": 512,
        "AcceptanceRate": 4
    },
    {
        "University": "University of California--Irvine",
        "AverageGPA": 3.76,
        "AverageMCAT": 516,
        "AcceptanceRate": 4
    },
    {
        "University": "University of California--Riverside",
        "AverageGPA": 3.64,
        "AverageMCAT": 508,
        "AcceptanceRate": 4
    },
    {
        "University": "University of North Carolina--Chapel Hill",
        "AverageGPA": 3.87,
        "AverageMCAT": 512,
        "AcceptanceRate": 4
    },
    {
        "University": "West Virginia University",
        "AverageGPA": 3.8,
        "AverageMCAT": 507,
        "AcceptanceRate": 4
    },
    {
        "University": "University of Washington",
        "AverageGPA": 3.68,
        "AverageMCAT": 510,
        "AcceptanceRate": 4.1
    },
    {
        "University": "University of Chicago (Pritzker)",
        "AverageGPA": 3.91,
        "AverageMCAT": 521,
        "AcceptanceRate": 4.3
    },
    {
        "University": "University of Miami (Miller)",
        "AverageGPA": 3.71,
        "AverageMCAT": 513,
        "AcceptanceRate": 4.3
    },
    {
        "University": "University of Pennsylvania (Perelman)",
        "AverageGPA": 3.9,
        "AverageMCAT": 522,
        "AcceptanceRate": 4.4
    },
    {
        "University": "University of Pittsburgh",
        "AverageGPA": 3.8,
        "AverageMCAT": 517,
        "AcceptanceRate": 4.5
    },
    {
        "University": "University of Southern California (Keck)",
        "AverageGPA": 3.7,
        "AverageMCAT": 517,
        "AcceptanceRate": 4.6
    },
    {
        "University": "Thomas Jefferson University (Sydney Kimmel)",
        "AverageGPA": 3.72,
        "AverageMCAT": 515,
        "AcceptanceRate": 4.7
    },
    {
        "University": "University of Utah",
        "AverageGPA": 3.75,
        "AverageMCAT": 512,
        "AcceptanceRate": 4.7
    },
    {
        "University": "Baylor College of Medicine",
        "AverageGPA": 3.93,
        "AverageMCAT": 518,
        "AcceptanceRate": 4.9
    },
    {
        "University": "Temple University (Katz)",
        "AverageGPA": 3.7,
        "AverageMCAT": 511,
        "AcceptanceRate": 4.9
    },
    {
        "University": "Drexel University",
        "AverageGPA": 3.67,
        "AverageMCAT": 511,
        "AcceptanceRate": 5
    },
    {
        "University": "Eastern Virginia Medical School",
        "AverageGPA": 3.6,
        "AverageMCAT": 511,
        "AcceptanceRate": 5
    },
    {
        "University": "University of Central Florida",
        "AverageGPA": 3.8,
        "AverageMCAT": 512,
        "AcceptanceRate": 5
    },
    {
        "University": "University of Florida",
        "AverageGPA": 3.89,
        "AverageMCAT": 515,
        "AcceptanceRate": 5
    },
    {
        "University": "University of Hawaii--Manoa (Burns)",
        "AverageGPA": 3.76,
        "AverageMCAT": 511,
        "AcceptanceRate": 5
    },
    {
        "University": "University of Michigan--Ann Arbor",
        "AverageGPA": 3.81,
        "AverageMCAT": 516,
        "AcceptanceRate": 5
    },
    {
        "University": "Virginia Commonwealth University",
        "AverageGPA": 3.72,
        "AverageMCAT": 511,
        "AcceptanceRate": 5
    },
    {
        "University": "Yeshiva (Albert Einstein) College of Medicine",
        "AverageGPA": 3.81,
        "AverageMCAT": 515,
        "AcceptanceRate": 5
    },
    {
        "University": "Cornell University (Weill)",
        "AverageGPA": 3.88,
        "AverageMCAT": 515,
        "AcceptanceRate": 5.1
    },
    {
        "University": "Vanderbilt University",
        "AverageGPA": 3.84,
        "AverageMCAT": 520,
        "AcceptanceRate": 5.3
    },
    {
        "University": "Rutgers Robert Wood Johnson Medical School--New Brunswick",
        "AverageGPA": 3.7,
        "AverageMCAT": 512,
        "AcceptanceRate": 5.4
    },
    {
        "University": "University of Wisconsin--Madison",
        "AverageGPA": 3.74,
        "AverageMCAT": 512,
        "AcceptanceRate": 5.5
    },
    {
        "University": "Yale University",
        "AverageGPA": 3.83,
        "AverageMCAT": 518,
        "AcceptanceRate": 5.5
    },
    {
        "University": "Wayne State University",
        "AverageGPA": 3.81,
        "AverageMCAT": 511,
        "AcceptanceRate": 5.6
    },
    {
        "University": "Icahn School of Medicine at Mount Sinai",
        "AverageGPA": 3.82,
        "AverageMCAT": 518,
        "AcceptanceRate": 5.7
    },
    {
        "University": "Ohio State University",
        "AverageGPA": 3.79,
        "AverageMCAT": 514,
        "AcceptanceRate": 5.7
    },
    {
        "University": "University of Rochester",
        "AverageGPA": 3.82,
        "AverageMCAT": 516,
        "AcceptanceRate": 5.7
    },
    {
        "University": "East Tennessee State University (Quillen)",
        "AverageGPA": 3.77,
        "AverageMCAT": 506,
        "AcceptanceRate": 6
    },
    {
        "University": "Johns Hopkins University",
        "AverageGPA": 3.94,
        "AverageMCAT": 521,
        "AcceptanceRate": 6
    },
    {
        "University": "Marshall University (Edwards)",
        "AverageGPA": 3.62,
        "AverageMCAT": 503,
        "AcceptanceRate": 6
    },
    {
        "University": "Rush University",
        "AverageGPA": 3.69,
        "AverageMCAT": 512,
        "AcceptanceRate": 6
    },
    {
        "University": "Tufts University",
        "AverageGPA": 3.65,
        "AverageMCAT": 512,
        "AcceptanceRate": 6
    },
    {
        "University": "University of Minnesota",
        "AverageGPA": 3.77,
        "AverageMCAT": 510,
        "AcceptanceRate": 6
    },
    {
        "University": "Wright State University (Boonshoft)",
        "AverageGPA": 3.72,
        "AverageMCAT": 504,
        "AcceptanceRate": 6
    },
    {
        "University": "Northwestern University (Feinberg)",
        "AverageGPA": 3.91,
        "AverageMCAT": 520,
        "AcceptanceRate": 6.4
    },
    {
        "University": "University of Vermont",
        "AverageGPA": 3.7,
        "AverageMCAT": 511,
        "AcceptanceRate": 6.4
    },
    {
        "University": "Boston University",
        "AverageGPA": 3.75,
        "AverageMCAT": 517,
        "AcceptanceRate": 6.5
    },
    {
        "University": "University of Connecticut",
        "AverageGPA": 3.76,
        "AverageMCAT": 512,
        "AcceptanceRate": 6.6
    },
    {
        "University": "University of Maryland",
        "AverageGPA": 3.83,
        "AverageMCAT": 515,
        "AcceptanceRate": 6.6
    },
    {
        "University": "Case Western Reserve University",
        "AverageGPA": 3.81,
        "AverageMCAT": 518,
        "AcceptanceRate": 7
    },
    {
        "University": "Florida Atlantic University (Schmidt)",
        "AverageGPA": 3.7,
        "AverageMCAT": 510,
        "AcceptanceRate": 7
    },
    {
        "University": "Medical College of Wisconsin",
        "AverageGPA": 3.76,
        "AverageMCAT": 511,
        "AcceptanceRate": 7
    },
    {
        "University": "New York Medical College",
        "AverageGPA": 3.6,
        "AverageMCAT": 511,
        "AcceptanceRate": 7
    },
    {
        "University": "Rowan University School of Osteopathic Medicine",
        "AverageGPA": 3.65,
        "AverageMCAT": 503,
        "AcceptanceRate": 7
    },
    {
        "University": "University of Texas Health Science Center--San Antonio",
        "AverageGPA": 3.8,
        "AverageMCAT": 515,
        "AcceptanceRate": 7
    },
    {
        "University": "University of Alabama--Birmingham",
        "AverageGPA": 3.84,
        "AverageMCAT": 509,
        "AcceptanceRate": 7
    },
    {
        "University": "University of Texas Southwestern Medical Center",
        "AverageGPA": 3.86,
        "AverageMCAT": 516,
        "AcceptanceRate": 7
    },
    {
        "University": "University of Toledo",
        "AverageGPA": 3.66,
        "AverageMCAT": 509,
        "AcceptanceRate": 7
    },
    {
        "University": "University of Iowa (Carver)",
        "AverageGPA": 3.85,
        "AverageMCAT": 514,
        "AcceptanceRate": 7.1
    },
    {
        "University": "University of South Florida",
        "AverageGPA": 3.8,
        "AverageMCAT": 516,
        "AcceptanceRate": 7.5
    },
    {
        "University": "University of Oklahoma",
        "AverageGPA": 3.85,
        "AverageMCAT": 509,
        "AcceptanceRate": 7.7
    },
    {
        "University": "Hofstra University",
        "AverageGPA": 3.8,
        "AverageMCAT": 516,
        "AcceptanceRate": 7.9
    },
    {
        "University": "Rutgers New Jersey Medical School--Newark",
        "AverageGPA": 3.65,
        "AverageMCAT": 512,
        "AcceptanceRate": 8
    },
    {
        "University": "Saint Louis University",
        "AverageGPA": 3.85,
        "AverageMCAT": 513,
        "AcceptanceRate": 8
    },
    {
        "University": "University of Kansas Medical Center",
        "AverageGPA": 3.85,
        "AverageMCAT": 510,
        "AcceptanceRate": 8
    },
    {
        "University": "University of Louisville",
        "AverageGPA": 3.73,
        "AverageMCAT": 507,
        "AcceptanceRate": 8
    },
    {
        "University": "Stony Brook University--SUNY",
        "AverageGPA": 3.8,
        "AverageMCAT": 514,
        "AcceptanceRate": 8.2
    },
    {
        "University": "Washington University in St. Louis",
        "AverageGPA": 3.9,
        "AverageMCAT": 521,
        "AcceptanceRate": 8.2
    },
    {
        "University": "University of Massachusetts--Worcester",
        "AverageGPA": 3.77,
        "AverageMCAT": 514,
        "AcceptanceRate": 8.6
    },
    {
        "University": "University of Missouri",
        "AverageGPA": 3.83,
        "AverageMCAT": 508,
        "AcceptanceRate": 9
    },
    {
        "University": "University of New Mexico",
        "AverageGPA": 3.68,
        "AverageMCAT": 505,
        "AcceptanceRate": 9
    },
    {
        "University": "University of Texas Health Science Center--Houston (McGovern)",
        "AverageGPA": 3.78,
        "AverageMCAT": 510,
        "AcceptanceRate": 10
    },
    {
        "University": "University of Nebraska Medical Center",
        "AverageGPA": 3.77,
        "AverageMCAT": 511,
        "AcceptanceRate": 10
    },
    {
        "University": "University of Illinois",
        "AverageGPA": 3.75,
        "AverageMCAT": 513,
        "AcceptanceRate": 10.3
    },
    {
        "University": "University at Buffalo--SUNY (Jacobs)",
        "AverageGPA": 3.79,
        "AverageMCAT": 510,
        "AcceptanceRate": 11
    },
    {
        "University": "University of Texas Medical Branch--Galveston",
        "AverageGPA": 3.89,
        "AverageMCAT": 507,
        "AcceptanceRate": 11
    },
    {
        "University": "University of Kentucky",
        "AverageGPA": 3.78,
        "AverageMCAT": 505,
        "AcceptanceRate": 11.3
    },
    {
        "University": "University of Virginia",
        "AverageGPA": 3.89,
        "AverageMCAT": 518,
        "AcceptanceRate": 11.7
    },
    {
        "University": "Medical College of Georgia at Augusta University",
        "AverageGPA": 3.7,
        "AverageMCAT": 510,
        "AcceptanceRate": 12
    },
    {
        "University": "East Carolina University (Brody)",
        "AverageGPA": 3.71,
        "AverageMCAT": 507,
        "AcceptanceRate": 12
    },
    {
        "University": "University of Nevada--Reno",
        "AverageGPA": 3.63,
        "AverageMCAT": 507,
        "AcceptanceRate": 12
    },
    {
        "University": "University of South Dakota (Sanford)",
        "AverageGPA": 3.83,
        "AverageMCAT": 507,
        "AcceptanceRate": 13
    },
    {
        "University": "University of Tennessee Health Science Center",
        "AverageGPA": 3.77,
        "AverageMCAT": 510,
        "AcceptanceRate": 14
    },
    {
        "University": "Oklahoma State University",
        "AverageGPA": 3.73,
        "AverageMCAT": 500,
        "AcceptanceRate": 15
    },
    {
        "University": "Medical University of South Carolina",
        "AverageGPA": 3.75,
        "AverageMCAT": 510,
        "AcceptanceRate": 15.5
    },
    {
        "University": "University of North Dakota",
        "AverageGPA": 3.7,
        "AverageMCAT": 506,
        "AcceptanceRate": 18
    }
];
/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */




export const LawSchool_Data = [
    {
        "University": "Yale University",
        "AverageGPA": 3.94,
        "AverageLSAT": 173,
        "AcceptanceRate": 7.4,
        "UndergraduateGPA_75th_Percentile": 3.99,
        "UndergraduateGPA_50th_Percentile": 3.94,
        "UndergraduateGPA_25th_Percentile": 3.85,
        "LSAT_75th_Percentile": 176,
        "LSAT_50th_Percentile": 173,
        "LSAT_25th_Percentile": 170
    },
    {
        "University": "Stanford University",
        "AverageGPA": 3.89,
        "AverageLSAT": 171,
        "AcceptanceRate": 10.48,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "UndergraduateGPA_50th_Percentile": 3.89,
        "UndergraduateGPA_25th_Percentile": 3.77,
        "LSAT_75th_Percentile": 174,
        "LSAT_50th_Percentile": 171,
        "LSAT_25th_Percentile": 169
    },
    {
        "University": "Harvard University",
        "AverageGPA": 3.88,
        "AverageLSAT": 173,
        "AcceptanceRate": 13,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "UndergraduateGPA_50th_Percentile": 3.88,
        "UndergraduateGPA_25th_Percentile": 3.78,
        "LSAT_75th_Percentile": 175,
        "LSAT_50th_Percentile": 173,
        "LSAT_25th_Percentile": 170
    },
    {
        "University": "Columbia University",
        "AverageGPA": 3.82,
        "AverageLSAT": 172,
        "AcceptanceRate": 16.69,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "UndergraduateGPA_50th_Percentile": 3.82,
        "UndergraduateGPA_25th_Percentile": 3.72,
        "LSAT_75th_Percentile": 173,
        "LSAT_50th_Percentile": 172,
        "LSAT_25th_Percentile": 169
    },
    {
        "University": "University of Chicago",
        "AverageGPA": 3.89,
        "AverageLSAT": 171,
        "AcceptanceRate": 17.86,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "UndergraduateGPA_50th_Percentile": 3.89,
        "UndergraduateGPA_25th_Percentile": 3.72,
        "LSAT_75th_Percentile": 173,
        "LSAT_50th_Percentile": 171,
        "LSAT_25th_Percentile": 167
    },
    {
        "University": "University of Pennsylvania",
        "AverageGPA": 3.89,
        "AverageLSAT": 170,
        "AcceptanceRate": 14.3,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "UndergraduateGPA_50th_Percentile": 3.89,
        "UndergraduateGPA_25th_Percentile": 3.57,
        "LSAT_75th_Percentile": 171,
        "LSAT_50th_Percentile": 170,
        "LSAT_25th_Percentile": 164
    },
    {
        "University": "New York University",
        "AverageGPA": 3.82,
        "AverageLSAT": 170,
        "AcceptanceRate": 21.58,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "UndergraduateGPA_50th_Percentile": 3.82,
        "UndergraduateGPA_25th_Percentile": 3.65,
        "LSAT_75th_Percentile": 172,
        "LSAT_50th_Percentile": 170,
        "LSAT_25th_Percentile": 167
    },
    {
        "University": "University of Virginia",
        "AverageGPA": 3.9,
        "AverageLSAT": 170,
        "AcceptanceRate": 14.05,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "UndergraduateGPA_50th_Percentile": 3.9,
        "UndergraduateGPA_25th_Percentile": 3.49,
        "LSAT_75th_Percentile": 172,
        "LSAT_50th_Percentile": 170,
        "LSAT_25th_Percentile": 164
    },
    {
        "University": "University of California—Berkeley",
        "AverageGPA": 3.81,
        "AverageLSAT": 168,
        "AcceptanceRate": 21.53,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "UndergraduateGPA_50th_Percentile": 3.81,
        "UndergraduateGPA_25th_Percentile": 3.65,
        "LSAT_75th_Percentile": 170,
        "LSAT_50th_Percentile": 168,
        "LSAT_25th_Percentile": 163
    },
    {
        "University": "University of Michigan",
        "AverageGPA": 3.76,
        "AverageLSAT": 169,
        "AcceptanceRate": 16.36,
        "UndergraduateGPA_75th_Percentile": 3.88,
        "UndergraduateGPA_50th_Percentile": 3.76,
        "UndergraduateGPA_25th_Percentile": 3.56,
        "LSAT_75th_Percentile": 170,
        "LSAT_50th_Percentile": 169,
        "LSAT_25th_Percentile": 164
    },
    {
        "University": "Duke University",
        "AverageGPA": 3.8,
        "AverageLSAT": 169,
        "AcceptanceRate": 22.34,
        "UndergraduateGPA_75th_Percentile": 3.89,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_25th_Percentile": 3.7,
        "LSAT_75th_Percentile": 169,
        "LSAT_50th_Percentile": 169,
        "LSAT_25th_Percentile": 167
    },
    {
        "University": "Northwestern University",
        "AverageGPA": 3.85,
        "AverageLSAT": 169,
        "AcceptanceRate": 19.2,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "UndergraduateGPA_50th_Percentile": 3.85,
        "UndergraduateGPA_25th_Percentile": 3.57,
        "LSAT_75th_Percentile": 171,
        "LSAT_50th_Percentile": 169,
        "LSAT_25th_Percentile": 165
    },
    {
        "University": "Cornell University",
        "AverageGPA": 3.86,
        "AverageLSAT": 168,
        "AcceptanceRate": 19.03,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "UndergraduateGPA_50th_Percentile": 3.86,
        "UndergraduateGPA_25th_Percentile": 3.76,
        "LSAT_75th_Percentile": 169,
        "LSAT_50th_Percentile": 168,
        "LSAT_25th_Percentile": 164
    },
    {
        "University": "University of California—Los Angeles",
        "AverageGPA": 3.79,
        "AverageLSAT": 169,
        "AcceptanceRate": 22.75,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_25th_Percentile": 3.54,
        "LSAT_75th_Percentile": 171,
        "LSAT_50th_Percentile": 169,
        "LSAT_25th_Percentile": 164
    },
    {
        "University": "Georgetown University",
        "AverageGPA": 3.78,
        "AverageLSAT": 168,
        "AcceptanceRate": 20.1,
        "UndergraduateGPA_75th_Percentile": 3.88,
        "UndergraduateGPA_50th_Percentile": 3.78,
        "UndergraduateGPA_25th_Percentile": 3.55,
        "LSAT_75th_Percentile": 169,
        "LSAT_50th_Percentile": 168,
        "LSAT_25th_Percentile": 164
    },
    {
        "University": "University of Texas at Austin",
        "AverageGPA": 3.76,
        "AverageLSAT": 168,
        "AcceptanceRate": 18.43,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "UndergraduateGPA_50th_Percentile": 3.76,
        "UndergraduateGPA_25th_Percentile": 3.58,
        "LSAT_75th_Percentile": 169,
        "LSAT_50th_Percentile": 168,
        "LSAT_25th_Percentile": 164
    },
    {
        "University": "Washington University in St. Louis",
        "AverageGPA": 3.88,
        "AverageLSAT": 169,
        "AcceptanceRate": 20.31,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "UndergraduateGPA_50th_Percentile": 3.88,
        "UndergraduateGPA_25th_Percentile": 3.41,
        "LSAT_75th_Percentile": 170,
        "LSAT_50th_Percentile": 169,
        "LSAT_25th_Percentile": 162
    },
    {
        "University": "Vanderbilt University",
        "AverageGPA": 3.82,
        "AverageLSAT": 167,
        "AcceptanceRate": 22.57,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "UndergraduateGPA_50th_Percentile": 3.82,
        "UndergraduateGPA_25th_Percentile": 3.65,
        "LSAT_75th_Percentile": 168,
        "LSAT_50th_Percentile": 167,
        "LSAT_25th_Percentile": 162
    },
    {
        "University": "University of Southern California",
        "AverageGPA": 3.83,
        "AverageLSAT": 167,
        "AcceptanceRate": 17.18,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "UndergraduateGPA_50th_Percentile": 3.83,
        "UndergraduateGPA_25th_Percentile": 3.61,
        "LSAT_75th_Percentile": 168,
        "LSAT_50th_Percentile": 167,
        "LSAT_25th_Percentile": 162
    },
    {
        "University": "Boston University",
        "AverageGPA": 3.8,
        "AverageLSAT": 167,
        "AcceptanceRate": 24.93,
        "UndergraduateGPA_75th_Percentile": 3.87,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_25th_Percentile": 3.56,
        "LSAT_75th_Percentile": 168,
        "LSAT_50th_Percentile": 167,
        "LSAT_25th_Percentile": 162
    },
    {
        "University": "University of Florida (Levin)",
        "AverageGPA": 3.84,
        "AverageLSAT": 165,
        "AcceptanceRate": 19.77,
        "UndergraduateGPA_75th_Percentile": 3.94,
        "UndergraduateGPA_50th_Percentile": 3.84,
        "UndergraduateGPA_25th_Percentile": 3.52,
        "LSAT_75th_Percentile": 166,
        "LSAT_50th_Percentile": 165,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Notre Dame",
        "AverageGPA": 3.75,
        "AverageLSAT": 167,
        "AcceptanceRate": 20.28,
        "UndergraduateGPA_75th_Percentile": 3.88,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_25th_Percentile": 3.34,
        "LSAT_75th_Percentile": 168,
        "LSAT_50th_Percentile": 167,
        "LSAT_25th_Percentile": 161
    },
    {
        "University": "University of Minnesota",
        "AverageGPA": 3.77,
        "AverageLSAT": 165,
        "AcceptanceRate": 36.45,
        "UndergraduateGPA_75th_Percentile": 3.85,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_25th_Percentile": 3.54,
        "LSAT_75th_Percentile": 166,
        "LSAT_50th_Percentile": 165,
        "LSAT_25th_Percentile": 160
    },
    {
        "University": "University of North Carolina",
        "AverageGPA": 3.68,
        "AverageLSAT": 163,
        "AcceptanceRate": 21.62,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.68,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 159
    },
    {
        "University": "Arizona State University",
        "AverageGPA": 3.83,
        "AverageLSAT": 165,
        "AcceptanceRate": 26.19,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "UndergraduateGPA_50th_Percentile": 3.83,
        "UndergraduateGPA_25th_Percentile": 3.36,
        "LSAT_75th_Percentile": 166,
        "LSAT_50th_Percentile": 165,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Alabama",
        "AverageGPA": 3.94,
        "AverageLSAT": 164,
        "AcceptanceRate": 35.72,
        "UndergraduateGPA_75th_Percentile": 4,
        "UndergraduateGPA_50th_Percentile": 3.94,
        "UndergraduateGPA_25th_Percentile": 3.57,
        "LSAT_75th_Percentile": 166,
        "LSAT_50th_Percentile": 164,
        "LSAT_25th_Percentile": 158
    },
    {
        "University": "University of Georgia",
        "AverageGPA": 3.78,
        "AverageLSAT": 165,
        "AcceptanceRate": 19.35,
        "UndergraduateGPA_75th_Percentile": 3.88,
        "UndergraduateGPA_50th_Percentile": 3.78,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "LSAT_75th_Percentile": 166,
        "LSAT_50th_Percentile": 165,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "George Washington University",
        "AverageGPA": 3.76,
        "AverageLSAT": 166,
        "AcceptanceRate": 34.15,
        "UndergraduateGPA_75th_Percentile": 3.85,
        "UndergraduateGPA_50th_Percentile": 3.76,
        "UndergraduateGPA_25th_Percentile": 3.45,
        "LSAT_75th_Percentile": 167,
        "LSAT_50th_Percentile": 166,
        "LSAT_25th_Percentile": 159
    },
    {
        "University": "Boston College",
        "AverageGPA": 3.69,
        "AverageLSAT": 164,
        "AcceptanceRate": 31.84,
        "UndergraduateGPA_75th_Percentile": 3.79,
        "UndergraduateGPA_50th_Percentile": 3.69,
        "UndergraduateGPA_25th_Percentile": 3.46,
        "LSAT_75th_Percentile": 165,
        "LSAT_50th_Percentile": 164,
        "LSAT_25th_Percentile": 160
    },
    {
        "University": "Emory University",
        "AverageGPA": 3.8,
        "AverageLSAT": 166,
        "AcceptanceRate": 32.21,
        "UndergraduateGPA_75th_Percentile": 3.89,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_25th_Percentile": 3.47,
        "LSAT_75th_Percentile": 167,
        "LSAT_50th_Percentile": 166,
        "LSAT_25th_Percentile": 159
    },
    {
        "University": "Brigham Young University",
        "AverageGPA": 3.82,
        "AverageLSAT": 166,
        "AcceptanceRate": 36.7,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "UndergraduateGPA_50th_Percentile": 3.82,
        "UndergraduateGPA_25th_Percentile": 3.55,
        "LSAT_75th_Percentile": 168,
        "LSAT_50th_Percentile": 166,
        "LSAT_25th_Percentile": 161
    },
    {
        "University": "University of Wisconsin",
        "AverageGPA": 3.61,
        "AverageLSAT": 163,
        "AcceptanceRate": 42.45,
        "UndergraduateGPA_75th_Percentile": 3.75,
        "UndergraduateGPA_50th_Percentile": 3.61,
        "UndergraduateGPA_25th_Percentile": 3.31,
        "LSAT_75th_Percentile": 165,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 158
    },
    {
        "University": "University of Illinois—Urbana Champaign",
        "AverageGPA": 3.64,
        "AverageLSAT": 162,
        "AcceptanceRate": 42.53,
        "UndergraduateGPA_75th_Percentile": 3.79,
        "UndergraduateGPA_50th_Percentile": 3.64,
        "UndergraduateGPA_25th_Percentile": 3.31,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 162,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Iowa",
        "AverageGPA": 3.64,
        "AverageLSAT": 161,
        "AcceptanceRate": 60.32,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.64,
        "UndergraduateGPA_25th_Percentile": 3.44,
        "LSAT_75th_Percentile": 163,
        "LSAT_50th_Percentile": 161,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of California—Irvine",
        "AverageGPA": 3.61,
        "AverageLSAT": 166,
        "AcceptanceRate": 20.3,
        "UndergraduateGPA_75th_Percentile": 3.76,
        "UndergraduateGPA_50th_Percentile": 3.61,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "LSAT_75th_Percentile": 167,
        "LSAT_50th_Percentile": 166,
        "LSAT_25th_Percentile": 163
    },
    {
        "University": "Fordham University",
        "AverageGPA": 3.64,
        "AverageLSAT": 164,
        "AcceptanceRate": 27.37,
        "UndergraduateGPA_75th_Percentile": 3.74,
        "UndergraduateGPA_50th_Percentile": 3.64,
        "UndergraduateGPA_25th_Percentile": 3.46,
        "LSAT_75th_Percentile": 166,
        "LSAT_50th_Percentile": 164,
        "LSAT_25th_Percentile": 162
    },
    {
        "University": "University of California—Davis",
        "AverageGPA": 3.72,
        "AverageLSAT": 163,
        "AcceptanceRate": 33.06,
        "UndergraduateGPA_75th_Percentile": 3.81,
        "UndergraduateGPA_50th_Percentile": 3.72,
        "UndergraduateGPA_25th_Percentile": 3.52,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 158
    },
    {
        "University": "Washington and Lee University",
        "AverageGPA": 3.63,
        "AverageLSAT": 163,
        "AcceptanceRate": 36.73,
        "UndergraduateGPA_75th_Percentile": 3.79,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_25th_Percentile": 3.32,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "William & Mary Law School",
        "AverageGPA": 3.6,
        "AverageLSAT": 163,
        "AcceptanceRate": 42.07,
        "UndergraduateGPA_75th_Percentile": 3.79,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_25th_Percentile": 3.39,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 161
    },
    {
        "University": "Ohio State University",
        "AverageGPA": 3.79,
        "AverageLSAT": 161,
        "AcceptanceRate": 35.18,
        "UndergraduateGPA_75th_Percentile": 3.94,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_25th_Percentile": 3.65,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 161,
        "LSAT_25th_Percentile": 158
    },
    {
        "University": "Wake Forest University",
        "AverageGPA": 3.73,
        "AverageLSAT": 163,
        "AcceptanceRate": 17.99,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "UndergraduateGPA_50th_Percentile": 3.73,
        "UndergraduateGPA_25th_Percentile": 3.62,
        "LSAT_75th_Percentile": 165,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 158
    },
    {
        "University": "George Mason University",
        "AverageGPA": 3.77,
        "AverageLSAT": 164,
        "AcceptanceRate": 22.8,
        "UndergraduateGPA_75th_Percentile": 3.87,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_25th_Percentile": 3.42,
        "LSAT_75th_Percentile": 165,
        "LSAT_50th_Percentile": 164,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "University of Utah",
        "AverageGPA": 3.77,
        "AverageLSAT": 161,
        "AcceptanceRate": 33.45,
        "UndergraduateGPA_75th_Percentile": 3.87,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_25th_Percentile": 3.62,
        "LSAT_75th_Percentile": 163,
        "LSAT_50th_Percentile": 161,
        "LSAT_25th_Percentile": 159
    },
    {
        "University": "Indiana University - Bloomington",
        "AverageGPA": 3.78,
        "AverageLSAT": 162,
        "AcceptanceRate": 43.29,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "UndergraduateGPA_50th_Percentile": 3.78,
        "UndergraduateGPA_25th_Percentile": 3.43,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 162,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "University of Washington",
        "AverageGPA": 3.68,
        "AverageLSAT": 162,
        "AcceptanceRate": 34.51,
        "UndergraduateGPA_75th_Percentile": 3.81,
        "UndergraduateGPA_50th_Percentile": 3.68,
        "UndergraduateGPA_25th_Percentile": 3.45,
        "LSAT_75th_Percentile": 165,
        "LSAT_50th_Percentile": 162,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "University of Arizona",
        "AverageGPA": 3.56,
        "AverageLSAT": 163,
        "AcceptanceRate": 27.74,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.56,
        "UndergraduateGPA_25th_Percentile": 3.31,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 161
    },
    {
        "University": "Pepperdine University",
        "AverageGPA": 3.68,
        "AverageLSAT": 162,
        "AcceptanceRate": 28.2,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.68,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 162,
        "LSAT_25th_Percentile": 159
    },
    {
        "University": "Florida State University",
        "AverageGPA": 3.72,
        "AverageLSAT": 161,
        "AcceptanceRate": 29.7,
        "UndergraduateGPA_75th_Percentile": 3.82,
        "UndergraduateGPA_50th_Percentile": 3.72,
        "UndergraduateGPA_25th_Percentile": 3.48,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 161,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Colorado—Boulder",
        "AverageGPA": 3.65,
        "AverageLSAT": 163,
        "AcceptanceRate": 36.5,
        "UndergraduateGPA_75th_Percentile": 3.77,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_25th_Percentile": 3.39,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 163,
        "LSAT_25th_Percentile": 158
    },
    {
        "University": "University of Maryland",
        "AverageGPA": 3.66,
        "AverageLSAT": 160,
        "AcceptanceRate": 36.42,
        "UndergraduateGPA_75th_Percentile": 3.76,
        "UndergraduateGPA_50th_Percentile": 3.66,
        "UndergraduateGPA_25th_Percentile": 3.35,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "University of California (Hastings)",
        "AverageGPA": 3.42,
        "AverageLSAT": 158,
        "AcceptanceRate": 38.66,
        "UndergraduateGPA_75th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.42,
        "UndergraduateGPA_25th_Percentile": 3.2,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "Southern Methodist University",
        "AverageGPA": 3.73,
        "AverageLSAT": 162,
        "AcceptanceRate": 40.77,
        "UndergraduateGPA_75th_Percentile": 3.84,
        "UndergraduateGPA_50th_Percentile": 3.73,
        "UndergraduateGPA_25th_Percentile": 3.44,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 162,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "Texas A&M University",
        "AverageGPA": 3.76,
        "AverageLSAT": 160,
        "AcceptanceRate": 22.43,
        "UndergraduateGPA_75th_Percentile": 3.87,
        "UndergraduateGPA_50th_Percentile": 3.76,
        "UndergraduateGPA_25th_Percentile": 3.35,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "Villanova University",
        "AverageGPA": 3.63,
        "AverageLSAT": 160,
        "AcceptanceRate": 29.75,
        "UndergraduateGPA_75th_Percentile": 3.79,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_25th_Percentile": 3.37,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "Temple University",
        "AverageGPA": 3.57,
        "AverageLSAT": 161,
        "AcceptanceRate": 36.05,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.57,
        "UndergraduateGPA_25th_Percentile": 3.31,
        "LSAT_75th_Percentile": 163,
        "LSAT_50th_Percentile": 161,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "Yeshiva University (Cardozo)",
        "AverageGPA": 3.65,
        "AverageLSAT": 162,
        "AcceptanceRate": 37.71,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_25th_Percentile": 3.41,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 162,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "University of Richmond",
        "AverageGPA": 3.62,
        "AverageLSAT": 161,
        "AcceptanceRate": 43.5,
        "UndergraduateGPA_75th_Percentile": 3.74,
        "UndergraduateGPA_50th_Percentile": 3.62,
        "UndergraduateGPA_25th_Percentile": 3.21,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 161,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "Baylor University",
        "AverageGPA": 3.63,
        "AverageLSAT": 160,
        "AcceptanceRate": 34.02,
        "UndergraduateGPA_75th_Percentile": 3.82,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_25th_Percentile": 3.45,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "University of Connecticut",
        "AverageGPA": 3.53,
        "AverageLSAT": 158,
        "AcceptanceRate": 45.11,
        "UndergraduateGPA_75th_Percentile": 3.68,
        "UndergraduateGPA_50th_Percentile": 3.53,
        "UndergraduateGPA_25th_Percentile": 3.34,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Nevada—Las Vegas",
        "AverageGPA": 3.67,
        "AverageLSAT": 159,
        "AcceptanceRate": 31.07,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.67,
        "UndergraduateGPA_25th_Percentile": 3.28,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "University of Tennessee",
        "AverageGPA": 3.63,
        "AverageLSAT": 159,
        "AcceptanceRate": 33.33,
        "UndergraduateGPA_75th_Percentile": 3.84,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_25th_Percentile": 3.33,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "University of Houston",
        "AverageGPA": 3.57,
        "AverageLSAT": 160,
        "AcceptanceRate": 33.59,
        "UndergraduateGPA_75th_Percentile": 3.74,
        "UndergraduateGPA_50th_Percentile": 3.57,
        "UndergraduateGPA_25th_Percentile": 3.29,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "Pennsylvania State - Penn State Law",
        "AverageGPA": 3.72,
        "AverageLSAT": 160,
        "AcceptanceRate": 37.59,
        "UndergraduateGPA_75th_Percentile": 3.83,
        "UndergraduateGPA_50th_Percentile": 3.72,
        "UndergraduateGPA_25th_Percentile": 3.36,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "Pennsylvania State - Dickinson Law",
        "AverageGPA": 3.39,
        "AverageLSAT": 160,
        "AcceptanceRate": 38.6,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "UndergraduateGPA_50th_Percentile": 3.39,
        "UndergraduateGPA_25th_Percentile": 3.14,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "Tulane University",
        "AverageGPA": 3.54,
        "AverageLSAT": 159,
        "AcceptanceRate": 53.34,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.54,
        "UndergraduateGPA_25th_Percentile": 3.25,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "University of Missouri",
        "AverageGPA": 3.66,
        "AverageLSAT": 158,
        "AcceptanceRate": 55.49,
        "UndergraduateGPA_75th_Percentile": 3.89,
        "UndergraduateGPA_50th_Percentile": 3.66,
        "UndergraduateGPA_25th_Percentile": 3.39,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "Northeastern University",
        "AverageGPA": 3.65,
        "AverageLSAT": 162,
        "AcceptanceRate": 35.56,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_25th_Percentile": 3.36,
        "LSAT_75th_Percentile": 164,
        "LSAT_50th_Percentile": 162,
        "LSAT_25th_Percentile": 158
    },
    {
        "University": "University of Pittsburgh",
        "AverageGPA": 3.51,
        "AverageLSAT": 159,
        "AcceptanceRate": 41.66,
        "UndergraduateGPA_75th_Percentile": 3.76,
        "UndergraduateGPA_50th_Percentile": 3.51,
        "UndergraduateGPA_25th_Percentile": 3.22,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "University of Oklahoma",
        "AverageGPA": 3.63,
        "AverageLSAT": 157,
        "AcceptanceRate": 54.01,
        "UndergraduateGPA_75th_Percentile": 3.84,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 157,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "Seton Hall University",
        "AverageGPA": 3.55,
        "AverageLSAT": 158,
        "AcceptanceRate": 46.2,
        "UndergraduateGPA_75th_Percentile": 3.79,
        "UndergraduateGPA_50th_Percentile": 3.55,
        "UndergraduateGPA_25th_Percentile": 3.32,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "University of Kansas",
        "AverageGPA": 3.66,
        "AverageLSAT": 157,
        "AcceptanceRate": 59.86,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.66,
        "UndergraduateGPA_25th_Percentile": 3.32,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 157,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "Loyola Marymount University—Los Angeles",
        "AverageGPA": 3.59,
        "AverageLSAT": 160,
        "AcceptanceRate": 35.73,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "UndergraduateGPA_50th_Percentile": 3.59,
        "UndergraduateGPA_25th_Percentile": 3.38,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "Wayne State University",
        "AverageGPA": 3.65,
        "AverageLSAT": 159,
        "AcceptanceRate": 36.62,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "St. John's University",
        "AverageGPA": 3.62,
        "AverageLSAT": 160,
        "AcceptanceRate": 41.11,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.62,
        "UndergraduateGPA_25th_Percentile": 3.28,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Case Western Reserve University",
        "AverageGPA": 3.5,
        "AverageLSAT": 159,
        "AcceptanceRate": 46.63,
        "UndergraduateGPA_75th_Percentile": 3.69,
        "UndergraduateGPA_50th_Percentile": 3.5,
        "UndergraduateGPA_25th_Percentile": 3.25,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Miami",
        "AverageGPA": 3.53,
        "AverageLSAT": 159,
        "AcceptanceRate": 51.36,
        "UndergraduateGPA_75th_Percentile": 3.71,
        "UndergraduateGPA_50th_Percentile": 3.53,
        "UndergraduateGPA_25th_Percentile": 3.3,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Oregon",
        "AverageGPA": 3.48,
        "AverageLSAT": 157,
        "AcceptanceRate": 60.42,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.48,
        "UndergraduateGPA_25th_Percentile": 3.2,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 157,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "Georgia State University",
        "AverageGPA": 3.52,
        "AverageLSAT": 158,
        "AcceptanceRate": 28.57,
        "UndergraduateGPA_75th_Percentile": 3.68,
        "UndergraduateGPA_50th_Percentile": 3.52,
        "UndergraduateGPA_25th_Percentile": 3.28,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "Loyola University—Chicago",
        "AverageGPA": 3.41,
        "AverageLSAT": 159,
        "AcceptanceRate": 44.39,
        "UndergraduateGPA_75th_Percentile": 3.64,
        "UndergraduateGPA_50th_Percentile": 3.41,
        "UndergraduateGPA_25th_Percentile": 3.19,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 157
    },
    {
        "University": "University of Denver",
        "AverageGPA": 3.55,
        "AverageLSAT": 159,
        "AcceptanceRate": 51.42,
        "UndergraduateGPA_75th_Percentile": 3.75,
        "UndergraduateGPA_50th_Percentile": 3.55,
        "UndergraduateGPA_25th_Percentile": 3.2,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "American University",
        "AverageGPA": 3.51,
        "AverageLSAT": 160,
        "AcceptanceRate": 36.35,
        "UndergraduateGPA_75th_Percentile": 3.65,
        "UndergraduateGPA_50th_Percentile": 3.51,
        "UndergraduateGPA_25th_Percentile": 3.22,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 160,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "Drexel University",
        "AverageGPA": 3.56,
        "AverageLSAT": 156,
        "AcceptanceRate": 43.24,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.56,
        "UndergraduateGPA_25th_Percentile": 3.3,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Brooklyn Law School",
        "AverageGPA": 3.44,
        "AverageLSAT": 158,
        "AcceptanceRate": 48.83,
        "UndergraduateGPA_75th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.44,
        "UndergraduateGPA_25th_Percentile": 3.21,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of Cincinnati",
        "AverageGPA": 3.65,
        "AverageLSAT": 158,
        "AcceptanceRate": 50.62,
        "UndergraduateGPA_75th_Percentile": 3.85,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_25th_Percentile": 3.32,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "University of Kentucky",
        "AverageGPA": 3.54,
        "AverageLSAT": 155,
        "AcceptanceRate": 60.67,
        "UndergraduateGPA_75th_Percentile": 3.75,
        "UndergraduateGPA_50th_Percentile": 3.54,
        "UndergraduateGPA_25th_Percentile": 3.28,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "University of San Diego",
        "AverageGPA": 3.65,
        "AverageLSAT": 159,
        "AcceptanceRate": 39.04,
        "UndergraduateGPA_75th_Percentile": 3.75,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_25th_Percentile": 3.33,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 155
    },
    {
        "University": "University of Nebraska",
        "AverageGPA": 3.63,
        "AverageLSAT": 156,
        "AcceptanceRate": 66.23,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_25th_Percentile": 3.37,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "Florida International University",
        "AverageGPA": 3.65,
        "AverageLSAT": 158,
        "AcceptanceRate": 27.33,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_25th_Percentile": 3.35,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "University of New Hampshire",
        "AverageGPA": 3.46,
        "AverageLSAT": 156,
        "AcceptanceRate": 56.92,
        "UndergraduateGPA_75th_Percentile": 3.66,
        "UndergraduateGPA_50th_Percentile": 3.46,
        "UndergraduateGPA_25th_Percentile": 3.11,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "Lewis And Clark College",
        "AverageGPA": 3.49,
        "AverageLSAT": 158,
        "AcceptanceRate": 60.72,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.49,
        "UndergraduateGPA_25th_Percentile": 3.23,
        "LSAT_75th_Percentile": 162,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 156
    },
    {
        "University": "Howard University",
        "AverageGPA": 3.41,
        "AverageLSAT": 153,
        "AcceptanceRate": 34.22,
        "UndergraduateGPA_75th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.41,
        "UndergraduateGPA_25th_Percentile": 3.13,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Rutgers University (merged)",
        "AverageGPA": 3.31,
        "AverageLSAT": 156,
        "AcceptanceRate": 43.54,
        "UndergraduateGPA_75th_Percentile": 3.59,
        "UndergraduateGPA_50th_Percentile": 3.31,
        "UndergraduateGPA_25th_Percentile": 3,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "Illinois Institute of Technology (Chicago-Kent College of Law)",
        "AverageGPA": 3.48,
        "AverageLSAT": 158,
        "AcceptanceRate": 47.02,
        "UndergraduateGPA_75th_Percentile": 3.68,
        "UndergraduateGPA_50th_Percentile": 3.48,
        "UndergraduateGPA_25th_Percentile": 3.17,
        "LSAT_75th_Percentile": 161,
        "LSAT_50th_Percentile": 158,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Michigan State University",
        "AverageGPA": 3.56,
        "AverageLSAT": 156,
        "AcceptanceRate": 59.23,
        "UndergraduateGPA_75th_Percentile": 3.74,
        "UndergraduateGPA_50th_Percentile": 3.56,
        "UndergraduateGPA_25th_Percentile": 3.33,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "Saint Louis University",
        "AverageGPA": 3.45,
        "AverageLSAT": 155,
        "AcceptanceRate": 61.69,
        "UndergraduateGPA_75th_Percentile": 3.69,
        "UndergraduateGPA_50th_Percentile": 3.45,
        "UndergraduateGPA_25th_Percentile": 3.18,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "University of South Carolina",
        "AverageGPA": 3.5,
        "AverageLSAT": 156,
        "AcceptanceRate": 47.3,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.5,
        "UndergraduateGPA_25th_Percentile": 3.33,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "University of Arkansas, Fayetteville",
        "AverageGPA": 3.61,
        "AverageLSAT": 155,
        "AcceptanceRate": 54.44,
        "UndergraduateGPA_75th_Percentile": 3.83,
        "UndergraduateGPA_50th_Percentile": 3.61,
        "UndergraduateGPA_25th_Percentile": 3.31,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "University of Hawaii",
        "AverageGPA": 3.42,
        "AverageLSAT": 154,
        "AcceptanceRate": 40.3,
        "UndergraduateGPA_75th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.42,
        "UndergraduateGPA_25th_Percentile": 3.17,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "University of Mississippi",
        "AverageGPA": 3.51,
        "AverageLSAT": 156,
        "AcceptanceRate": 42.24,
        "UndergraduateGPA_75th_Percentile": 3.72,
        "UndergraduateGPA_50th_Percentile": 3.51,
        "UndergraduateGPA_25th_Percentile": 3.18,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "University of Buffalo—SUNY",
        "AverageGPA": 3.52,
        "AverageLSAT": 155,
        "AcceptanceRate": 44.87,
        "UndergraduateGPA_75th_Percentile": 3.75,
        "UndergraduateGPA_50th_Percentile": 3.52,
        "UndergraduateGPA_25th_Percentile": 3.25,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "University of Louisville",
        "AverageGPA": 3.63,
        "AverageLSAT": 155,
        "AcceptanceRate": 48.74,
        "UndergraduateGPA_75th_Percentile": 3.88,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_25th_Percentile": 3.35,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "CUNY",
        "AverageGPA": 3.45,
        "AverageLSAT": 154,
        "AcceptanceRate": 34.53,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.45,
        "UndergraduateGPA_25th_Percentile": 3.17,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "Texas Tech University",
        "AverageGPA": 3.56,
        "AverageLSAT": 156,
        "AcceptanceRate": 36.85,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "UndergraduateGPA_50th_Percentile": 3.56,
        "UndergraduateGPA_25th_Percentile": 3.28,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "Syracuse University",
        "AverageGPA": 3.53,
        "AverageLSAT": 155,
        "AcceptanceRate": 39.08,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "UndergraduateGPA_50th_Percentile": 3.53,
        "UndergraduateGPA_25th_Percentile": 3.11,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Catholic University Of America",
        "AverageGPA": 3.4,
        "AverageLSAT": 156,
        "AcceptanceRate": 41.85,
        "UndergraduateGPA_75th_Percentile": 3.56,
        "UndergraduateGPA_50th_Percentile": 3.4,
        "UndergraduateGPA_25th_Percentile": 3.25,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "University of New Mexico",
        "AverageGPA": 3.5,
        "AverageLSAT": 155,
        "AcceptanceRate": 46.22,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "UndergraduateGPA_50th_Percentile": 3.5,
        "UndergraduateGPA_25th_Percentile": 3.22,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Marquette University",
        "AverageGPA": 3.5,
        "AverageLSAT": 154,
        "AcceptanceRate": 51.23,
        "UndergraduateGPA_75th_Percentile": 3.65,
        "UndergraduateGPA_50th_Percentile": 3.5,
        "UndergraduateGPA_25th_Percentile": 3.25,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "Drake University",
        "AverageGPA": 3.46,
        "AverageLSAT": 155,
        "AcceptanceRate": 69.13,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "UndergraduateGPA_50th_Percentile": 3.46,
        "UndergraduateGPA_25th_Percentile": 3.17,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Washburn University",
        "AverageGPA": 3.39,
        "AverageLSAT": 155,
        "AcceptanceRate": 50.54,
        "UndergraduateGPA_75th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.39,
        "UndergraduateGPA_25th_Percentile": 2.94,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Louisiana State University",
        "AverageGPA": 3.45,
        "AverageLSAT": 155,
        "AcceptanceRate": 52.15,
        "UndergraduateGPA_75th_Percentile": 3.71,
        "UndergraduateGPA_50th_Percentile": 3.45,
        "UndergraduateGPA_25th_Percentile": 3.1,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "DePaul University",
        "AverageGPA": 3.41,
        "AverageLSAT": 155,
        "AcceptanceRate": 50,
        "UndergraduateGPA_75th_Percentile": 3.66,
        "UndergraduateGPA_50th_Percentile": 3.41,
        "UndergraduateGPA_25th_Percentile": 2.96,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Stetson University",
        "AverageGPA": 3.44,
        "AverageLSAT": 155,
        "AcceptanceRate": 52.06,
        "UndergraduateGPA_75th_Percentile": 3.66,
        "UndergraduateGPA_50th_Percentile": 3.44,
        "UndergraduateGPA_25th_Percentile": 3.18,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "University of Missouri—Kansas City",
        "AverageGPA": 3.43,
        "AverageLSAT": 153,
        "AcceptanceRate": 54.9,
        "UndergraduateGPA_75th_Percentile": 3.68,
        "UndergraduateGPA_50th_Percentile": 3.43,
        "UndergraduateGPA_25th_Percentile": 3.2,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "Indiana University - Indianapolis",
        "AverageGPA": 3.45,
        "AverageLSAT": 154,
        "AcceptanceRate": 55.96,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.45,
        "UndergraduateGPA_25th_Percentile": 3.23,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "University of Tulsa",
        "AverageGPA": 3.38,
        "AverageLSAT": 155,
        "AcceptanceRate": 59.97,
        "UndergraduateGPA_75th_Percentile": 3.67,
        "UndergraduateGPA_50th_Percentile": 3.38,
        "UndergraduateGPA_25th_Percentile": 3.09,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "Cleveland State University",
        "AverageGPA": 3.35,
        "AverageLSAT": 153,
        "AcceptanceRate": 46.99,
        "UndergraduateGPA_75th_Percentile": 3.61,
        "UndergraduateGPA_50th_Percentile": 3.35,
        "UndergraduateGPA_25th_Percentile": 3.03,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Albany Law School Of Union University",
        "AverageGPA": 3.4,
        "AverageLSAT": 154,
        "AcceptanceRate": 50.65,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.4,
        "UndergraduateGPA_25th_Percentile": 3.14,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "West Virginia University",
        "AverageGPA": 3.45,
        "AverageLSAT": 154,
        "AcceptanceRate": 63.49,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.45,
        "UndergraduateGPA_25th_Percentile": 3.06,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "University of Dayton",
        "AverageGPA": 3.48,
        "AverageLSAT": 152,
        "AcceptanceRate": 36.21,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.48,
        "UndergraduateGPA_25th_Percentile": 3.24,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Hofstra University",
        "AverageGPA": 3.37,
        "AverageLSAT": 154,
        "AcceptanceRate": 45.95,
        "UndergraduateGPA_75th_Percentile": 3.62,
        "UndergraduateGPA_50th_Percentile": 3.37,
        "UndergraduateGPA_25th_Percentile": 3.08,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "New York Law School",
        "AverageGPA": 3.47,
        "AverageLSAT": 153,
        "AcceptanceRate": 51.58,
        "UndergraduateGPA_75th_Percentile": 3.66,
        "UndergraduateGPA_50th_Percentile": 3.47,
        "UndergraduateGPA_25th_Percentile": 3.21,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "University of Wyoming",
        "AverageGPA": 3.4,
        "AverageLSAT": 153,
        "AcceptanceRate": 56.98,
        "UndergraduateGPA_75th_Percentile": 3.68,
        "UndergraduateGPA_50th_Percentile": 3.4,
        "UndergraduateGPA_25th_Percentile": 3.18,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Duquesne University",
        "AverageGPA": 3.41,
        "AverageLSAT": 154,
        "AcceptanceRate": 57.44,
        "UndergraduateGPA_75th_Percentile": 3.62,
        "UndergraduateGPA_50th_Percentile": 3.41,
        "UndergraduateGPA_25th_Percentile": 3.12,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Mercer University",
        "AverageGPA": 3.41,
        "AverageLSAT": 153,
        "AcceptanceRate": 44.55,
        "UndergraduateGPA_75th_Percentile": 3.63,
        "UndergraduateGPA_50th_Percentile": 3.41,
        "UndergraduateGPA_25th_Percentile": 3.2,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "University of Maine",
        "AverageGPA": 3.51,
        "AverageLSAT": 156,
        "AcceptanceRate": 46.28,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.51,
        "UndergraduateGPA_25th_Percentile": 3.24,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Santa Clara University",
        "AverageGPA": 3.34,
        "AverageLSAT": 156,
        "AcceptanceRate": 55.43,
        "UndergraduateGPA_75th_Percentile": 3.54,
        "UndergraduateGPA_50th_Percentile": 3.34,
        "UndergraduateGPA_25th_Percentile": 3.11,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "Seattle University",
        "AverageGPA": 3.39,
        "AverageLSAT": 155,
        "AcceptanceRate": 59.66,
        "UndergraduateGPA_75th_Percentile": 3.57,
        "UndergraduateGPA_50th_Percentile": 3.39,
        "UndergraduateGPA_25th_Percentile": 3.14,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "University of St. Thomas (Minnesota)",
        "AverageGPA": 3.51,
        "AverageLSAT": 154,
        "AcceptanceRate": 69.15,
        "UndergraduateGPA_75th_Percentile": 3.76,
        "UndergraduateGPA_50th_Percentile": 3.51,
        "UndergraduateGPA_25th_Percentile": 3.23,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Baltimore, University Of",
        "AverageGPA": 3.32,
        "AverageLSAT": 153,
        "AcceptanceRate": 55.13,
        "UndergraduateGPA_75th_Percentile": 3.53,
        "UndergraduateGPA_50th_Percentile": 3.32,
        "UndergraduateGPA_25th_Percentile": 2.94,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Gonzaga University",
        "AverageGPA": 3.43,
        "AverageLSAT": 153,
        "AcceptanceRate": 59.22,
        "UndergraduateGPA_75th_Percentile": 3.63,
        "UndergraduateGPA_50th_Percentile": 3.43,
        "UndergraduateGPA_25th_Percentile": 3.22,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 152
    },
    {
        "University": "Quinnipiac University",
        "AverageGPA": 3.46,
        "AverageLSAT": 152,
        "AcceptanceRate": 60.18,
        "UndergraduateGPA_75th_Percentile": 3.69,
        "UndergraduateGPA_50th_Percentile": 3.46,
        "UndergraduateGPA_25th_Percentile": 3.17,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "University of Toledo",
        "AverageGPA": 3.43,
        "AverageLSAT": 152,
        "AcceptanceRate": 65.58,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.43,
        "UndergraduateGPA_25th_Percentile": 3.11,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Suffolk University",
        "AverageGPA": 3.44,
        "AverageLSAT": 154,
        "AcceptanceRate": 69.37,
        "UndergraduateGPA_75th_Percentile": 3.66,
        "UndergraduateGPA_50th_Percentile": 3.44,
        "UndergraduateGPA_25th_Percentile": 3.17,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Chapman University",
        "AverageGPA": 3.52,
        "AverageLSAT": 159,
        "AcceptanceRate": 32.55,
        "UndergraduateGPA_75th_Percentile": 3.66,
        "UndergraduateGPA_50th_Percentile": 3.52,
        "UndergraduateGPA_25th_Percentile": 3.26,
        "LSAT_75th_Percentile": 160,
        "LSAT_50th_Percentile": 159,
        "LSAT_25th_Percentile": 154
    },
    {
        "University": "Belmont University",
        "AverageGPA": 3.6,
        "AverageLSAT": 156,
        "AcceptanceRate": 39.44,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_25th_Percentile": 3.36,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 153
    },
    {
        "University": "University of Akron",
        "AverageGPA": 3.34,
        "AverageLSAT": 153,
        "AcceptanceRate": 53.26,
        "UndergraduateGPA_75th_Percentile": 3.69,
        "UndergraduateGPA_50th_Percentile": 3.34,
        "UndergraduateGPA_25th_Percentile": 3.01,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "University of Montana",
        "AverageGPA": 3.44,
        "AverageLSAT": 156,
        "AcceptanceRate": 59.91,
        "UndergraduateGPA_75th_Percentile": 3.71,
        "UndergraduateGPA_50th_Percentile": 3.44,
        "UndergraduateGPA_25th_Percentile": 3.13,
        "LSAT_75th_Percentile": 159,
        "LSAT_50th_Percentile": 156,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "University of South Dakota",
        "AverageGPA": 3.56,
        "AverageLSAT": 149,
        "AcceptanceRate": 64,
        "UndergraduateGPA_75th_Percentile": 3.75,
        "UndergraduateGPA_50th_Percentile": 3.56,
        "UndergraduateGPA_25th_Percentile": 3.22,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 149,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "Pace University",
        "AverageGPA": 3.33,
        "AverageLSAT": 151,
        "AcceptanceRate": 51.3,
        "UndergraduateGPA_75th_Percentile": 3.55,
        "UndergraduateGPA_50th_Percentile": 3.33,
        "UndergraduateGPA_25th_Percentile": 3.04,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "University of Idaho",
        "AverageGPA": 3.24,
        "AverageLSAT": 153,
        "AcceptanceRate": 67.47,
        "UndergraduateGPA_75th_Percentile": 3.56,
        "UndergraduateGPA_50th_Percentile": 3.24,
        "UndergraduateGPA_25th_Percentile": 2.83,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "University of Arkansas, Little Rock",
        "AverageGPA": 3.41,
        "AverageLSAT": 151,
        "AcceptanceRate": 50.44,
        "UndergraduateGPA_75th_Percentile": 3.68,
        "UndergraduateGPA_50th_Percentile": 3.41,
        "UndergraduateGPA_25th_Percentile": 3.12,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "University of the Pacific (Mcgeorge)",
        "AverageGPA": 3.4,
        "AverageLSAT": 154,
        "AcceptanceRate": 55.88,
        "UndergraduateGPA_75th_Percentile": 3.62,
        "UndergraduateGPA_50th_Percentile": 3.4,
        "UndergraduateGPA_25th_Percentile": 3.07,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 154,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Creighton University",
        "AverageGPA": 3.25,
        "AverageLSAT": 152,
        "AcceptanceRate": 62.95,
        "UndergraduateGPA_75th_Percentile": 3.53,
        "UndergraduateGPA_50th_Percentile": 3.25,
        "UndergraduateGPA_25th_Percentile": 2.86,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "University of Memphis",
        "AverageGPA": 3.33,
        "AverageLSAT": 153,
        "AcceptanceRate": 47.07,
        "UndergraduateGPA_75th_Percentile": 3.62,
        "UndergraduateGPA_50th_Percentile": 3.33,
        "UndergraduateGPA_25th_Percentile": 3.02,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Loyola University—New Orleans",
        "AverageGPA": 3.32,
        "AverageLSAT": 151,
        "AcceptanceRate": 63.73,
        "UndergraduateGPA_75th_Percentile": 3.56,
        "UndergraduateGPA_50th_Percentile": 3.32,
        "UndergraduateGPA_25th_Percentile": 3.04,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Samford University",
        "AverageGPA": 3.49,
        "AverageLSAT": 152,
        "AcceptanceRate": 67.59,
        "UndergraduateGPA_75th_Percentile": 3.71,
        "UndergraduateGPA_50th_Percentile": 3.49,
        "UndergraduateGPA_25th_Percentile": 3.2,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Texas Southern University",
        "AverageGPA": 3.17,
        "AverageLSAT": 148,
        "AcceptanceRate": 30.43,
        "UndergraduateGPA_75th_Percentile": 3.49,
        "UndergraduateGPA_50th_Percentile": 3.17,
        "UndergraduateGPA_25th_Percentile": 2.72,
        "LSAT_75th_Percentile": 150,
        "LSAT_50th_Percentile": 148,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "University of the District of Columbia",
        "AverageGPA": 3.09,
        "AverageLSAT": 150,
        "AcceptanceRate": 32.63,
        "UndergraduateGPA_75th_Percentile": 3.51,
        "UndergraduateGPA_50th_Percentile": 3.09,
        "UndergraduateGPA_25th_Percentile": 2.81,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "Florida A&M University",
        "AverageGPA": 3.3,
        "AverageLSAT": 147,
        "AcceptanceRate": 33.82,
        "UndergraduateGPA_75th_Percentile": 3.48,
        "UndergraduateGPA_50th_Percentile": 3.3,
        "UndergraduateGPA_25th_Percentile": 3,
        "LSAT_75th_Percentile": 150,
        "LSAT_50th_Percentile": 147,
        "LSAT_25th_Percentile": 145
    },
    {
        "University": "Atlanta's John Marshall Law Shool",
        "AverageGPA": 2.93,
        "AverageLSAT": 150,
        "AcceptanceRate": 37.04,
        "UndergraduateGPA_75th_Percentile": 3.29,
        "UndergraduateGPA_50th_Percentile": 2.93,
        "UndergraduateGPA_25th_Percentile": 2.48,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Western State College Of Law",
        "AverageGPA": 3.14,
        "AverageLSAT": 150,
        "AcceptanceRate": 42.86,
        "UndergraduateGPA_75th_Percentile": 3.41,
        "UndergraduateGPA_50th_Percentile": 3.14,
        "UndergraduateGPA_25th_Percentile": 2.94,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Western Michigan University (Cooley)",
        "AverageGPA": 2.94,
        "AverageLSAT": 147,
        "AcceptanceRate": 43.78,
        "UndergraduateGPA_75th_Percentile": 3.39,
        "UndergraduateGPA_50th_Percentile": 2.94,
        "UndergraduateGPA_25th_Percentile": 2.6,
        "LSAT_75th_Percentile": 150,
        "LSAT_50th_Percentile": 147,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "North Carolina Central University",
        "AverageGPA": 3.24,
        "AverageLSAT": 145,
        "AcceptanceRate": 44.25,
        "UndergraduateGPA_75th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.24,
        "UndergraduateGPA_25th_Percentile": 3.01,
        "LSAT_75th_Percentile": 150,
        "LSAT_50th_Percentile": 145,
        "LSAT_25th_Percentile": 143
    },
    {
        "University": "Faulkner University",
        "AverageGPA": 3.28,
        "AverageLSAT": 148,
        "AcceptanceRate": 44.63,
        "UndergraduateGPA_75th_Percentile": 3.62,
        "UndergraduateGPA_50th_Percentile": 3.28,
        "UndergraduateGPA_25th_Percentile": 2.98,
        "LSAT_75th_Percentile": 151,
        "LSAT_50th_Percentile": 148,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "Appalachian School of Law",
        "AverageGPA": 3.17,
        "AverageLSAT": 147,
        "AcceptanceRate": 44.81,
        "UndergraduateGPA_75th_Percentile": 3.44,
        "UndergraduateGPA_50th_Percentile": 3.17,
        "UndergraduateGPA_25th_Percentile": 2.76,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 147,
        "LSAT_25th_Percentile": 144
    },
    {
        "University": "South Texas College Of Law—Houston",
        "AverageGPA": 3.17,
        "AverageLSAT": 152,
        "AcceptanceRate": 45.68,
        "UndergraduateGPA_75th_Percentile": 3.42,
        "UndergraduateGPA_50th_Percentile": 3.17,
        "UndergraduateGPA_25th_Percentile": 2.81,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Elon University",
        "AverageGPA": 3.3,
        "AverageLSAT": 150,
        "AcceptanceRate": 45.69,
        "UndergraduateGPA_75th_Percentile": 3.52,
        "UndergraduateGPA_50th_Percentile": 3.3,
        "UndergraduateGPA_25th_Percentile": 3.04,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "Florida Coastal School Of Law",
        "AverageGPA": 3.12,
        "AverageLSAT": 149,
        "AcceptanceRate": 46.04,
        "UndergraduateGPA_75th_Percentile": 3.46,
        "UndergraduateGPA_50th_Percentile": 3.12,
        "UndergraduateGPA_25th_Percentile": 2.72,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 149,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "Southwestern Law School",
        "AverageGPA": 3.25,
        "AverageLSAT": 153,
        "AcceptanceRate": 46.73,
        "UndergraduateGPA_75th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.25,
        "UndergraduateGPA_25th_Percentile": 2.97,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 151
    },
    {
        "University": "Nova Southeastern University",
        "AverageGPA": 3.19,
        "AverageLSAT": 152,
        "AcceptanceRate": 47.49,
        "UndergraduateGPA_75th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.19,
        "UndergraduateGPA_25th_Percentile": 2.96,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "University of San Francisco",
        "AverageGPA": 3.32,
        "AverageLSAT": 153,
        "AcceptanceRate": 48.72,
        "UndergraduateGPA_75th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.32,
        "UndergraduateGPA_25th_Percentile": 2.94,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 153,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Ohio Northern University",
        "AverageGPA": 3.26,
        "AverageLSAT": 150,
        "AcceptanceRate": 49.54,
        "UndergraduateGPA_75th_Percentile": 3.57,
        "UndergraduateGPA_50th_Percentile": 3.26,
        "UndergraduateGPA_25th_Percentile": 3,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "Touro College",
        "AverageGPA": 3.07,
        "AverageLSAT": 149,
        "AcceptanceRate": 49.77,
        "UndergraduateGPA_75th_Percentile": 3.43,
        "UndergraduateGPA_50th_Percentile": 3.07,
        "UndergraduateGPA_25th_Percentile": 2.82,
        "LSAT_75th_Percentile": 151,
        "LSAT_50th_Percentile": 149,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "Southern Illinois University—Carbondale",
        "AverageGPA": 3.29,
        "AverageLSAT": 150,
        "AcceptanceRate": 49.78,
        "UndergraduateGPA_75th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.29,
        "UndergraduateGPA_25th_Percentile": 2.91,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "Regent University",
        "AverageGPA": 3.45,
        "AverageLSAT": 155,
        "AcceptanceRate": 51.38,
        "UndergraduateGPA_75th_Percentile": 3.72,
        "UndergraduateGPA_50th_Percentile": 3.45,
        "UndergraduateGPA_25th_Percentile": 3.22,
        "LSAT_75th_Percentile": 158,
        "LSAT_50th_Percentile": 155,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Ave Maria School Of Law",
        "AverageGPA": 3.37,
        "AverageLSAT": 151,
        "AcceptanceRate": 51.56,
        "UndergraduateGPA_75th_Percentile": 3.55,
        "UndergraduateGPA_50th_Percentile": 3.37,
        "UndergraduateGPA_25th_Percentile": 2.98,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "St. Thomas University (Florida)",
        "AverageGPA": 3.23,
        "AverageLSAT": 150,
        "AcceptanceRate": 51.92,
        "UndergraduateGPA_75th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.23,
        "UndergraduateGPA_25th_Percentile": 2.95,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "California Western School Of Law",
        "AverageGPA": 3.24,
        "AverageLSAT": 152,
        "AcceptanceRate": 53,
        "UndergraduateGPA_75th_Percentile": 3.47,
        "UndergraduateGPA_50th_Percentile": 3.24,
        "UndergraduateGPA_25th_Percentile": 2.95,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "University of Detroit Mercy",
        "AverageGPA": 3.24,
        "AverageLSAT": 152,
        "AcceptanceRate": 53.68,
        "UndergraduateGPA_75th_Percentile": 3.42,
        "UndergraduateGPA_50th_Percentile": 3.24,
        "UndergraduateGPA_25th_Percentile": 2.99,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Charleston School Of Law",
        "AverageGPA": 3.2,
        "AverageLSAT": 150,
        "AcceptanceRate": 55.1,
        "UndergraduateGPA_75th_Percentile": 3.45,
        "UndergraduateGPA_50th_Percentile": 3.2,
        "UndergraduateGPA_25th_Percentile": 2.83,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Golden Gate University",
        "AverageGPA": 3.02,
        "AverageLSAT": 150,
        "AcceptanceRate": 55.34,
        "UndergraduateGPA_75th_Percentile": 3.34,
        "UndergraduateGPA_50th_Percentile": 3.02,
        "UndergraduateGPA_25th_Percentile": 2.72,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Liberty University",
        "AverageGPA": 3.4,
        "AverageLSAT": 151,
        "AcceptanceRate": 55.41,
        "UndergraduateGPA_75th_Percentile": 3.72,
        "UndergraduateGPA_50th_Percentile": 3.4,
        "UndergraduateGPA_25th_Percentile": 3.09,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Lincoln Memorial",
        "AverageGPA": 3.21,
        "AverageLSAT": 150,
        "AcceptanceRate": 55.58,
        "UndergraduateGPA_75th_Percentile": 3.52,
        "UndergraduateGPA_50th_Percentile": 3.21,
        "UndergraduateGPA_25th_Percentile": 2.88,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "University of Massachusetts Dartmouth",
        "AverageGPA": 3.27,
        "AverageLSAT": 149,
        "AcceptanceRate": 56.06,
        "UndergraduateGPA_75th_Percentile": 3.53,
        "UndergraduateGPA_50th_Percentile": 3.27,
        "UndergraduateGPA_25th_Percentile": 3.03,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 149,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "Widener-commonwealth",
        "AverageGPA": 3.14,
        "AverageLSAT": 147,
        "AcceptanceRate": 56.28,
        "UndergraduateGPA_75th_Percentile": 3.56,
        "UndergraduateGPA_50th_Percentile": 3.14,
        "UndergraduateGPA_25th_Percentile": 2.73,
        "LSAT_75th_Percentile": 150,
        "LSAT_50th_Percentile": 147,
        "LSAT_25th_Percentile": 145
    },
    {
        "University": "John Marshall Law School",
        "AverageGPA": 3.27,
        "AverageLSAT": 151,
        "AcceptanceRate": 56.94,
        "UndergraduateGPA_75th_Percentile": 3.52,
        "UndergraduateGPA_50th_Percentile": 3.27,
        "UndergraduateGPA_25th_Percentile": 2.98,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Mitchell Hamline",
        "AverageGPA": 3.24,
        "AverageLSAT": 152,
        "AcceptanceRate": 57.66,
        "UndergraduateGPA_75th_Percentile": 3.53,
        "UndergraduateGPA_50th_Percentile": 3.24,
        "UndergraduateGPA_25th_Percentile": 2.93,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "St. Mary's University",
        "AverageGPA": 3.2,
        "AverageLSAT": 151,
        "AcceptanceRate": 58.3,
        "UndergraduateGPA_75th_Percentile": 3.52,
        "UndergraduateGPA_50th_Percentile": 3.2,
        "UndergraduateGPA_25th_Percentile": 2.88,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Northern Illinois University",
        "AverageGPA": 3.2,
        "AverageLSAT": 149,
        "AcceptanceRate": 58.76,
        "UndergraduateGPA_75th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.2,
        "UndergraduateGPA_25th_Percentile": 2.86,
        "LSAT_75th_Percentile": 151,
        "LSAT_50th_Percentile": 149,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "Barry University",
        "AverageGPA": 3.02,
        "AverageLSAT": 148,
        "AcceptanceRate": 58.99,
        "UndergraduateGPA_75th_Percentile": 3.34,
        "UndergraduateGPA_50th_Percentile": 3.02,
        "UndergraduateGPA_25th_Percentile": 2.68,
        "LSAT_75th_Percentile": 151,
        "LSAT_50th_Percentile": 148,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "Western New England University",
        "AverageGPA": 3.27,
        "AverageLSAT": 148,
        "AcceptanceRate": 60.19,
        "UndergraduateGPA_75th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.27,
        "UndergraduateGPA_25th_Percentile": 3.03,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 148,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "Southern University",
        "AverageGPA": 2.98,
        "AverageLSAT": 144,
        "AcceptanceRate": 61.64,
        "UndergraduateGPA_75th_Percentile": 3.33,
        "UndergraduateGPA_50th_Percentile": 2.98,
        "UndergraduateGPA_25th_Percentile": 2.65,
        "LSAT_75th_Percentile": 146,
        "LSAT_50th_Percentile": 144,
        "LSAT_25th_Percentile": 143
    },
    {
        "University": "Oklahoma City University",
        "AverageGPA": 3.32,
        "AverageLSAT": 147,
        "AcceptanceRate": 62.56,
        "UndergraduateGPA_75th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.32,
        "UndergraduateGPA_25th_Percentile": 3.03,
        "LSAT_75th_Percentile": 151,
        "LSAT_50th_Percentile": 147,
        "LSAT_25th_Percentile": 145
    },
    {
        "University": "Campbell University",
        "AverageGPA": 3.37,
        "AverageLSAT": 152,
        "AcceptanceRate": 62.61,
        "UndergraduateGPA_75th_Percentile": 3.65,
        "UndergraduateGPA_50th_Percentile": 3.37,
        "UndergraduateGPA_25th_Percentile": 3.14,
        "LSAT_75th_Percentile": 156,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "Widener University—Delaware",
        "AverageGPA": 3.22,
        "AverageLSAT": 149,
        "AcceptanceRate": 62.69,
        "UndergraduateGPA_75th_Percentile": 3.46,
        "UndergraduateGPA_50th_Percentile": 3.22,
        "UndergraduateGPA_25th_Percentile": 2.95,
        "LSAT_75th_Percentile": 151,
        "LSAT_50th_Percentile": 149,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "Willamette University",
        "AverageGPA": 3.07,
        "AverageLSAT": 152,
        "AcceptanceRate": 64.22,
        "UndergraduateGPA_75th_Percentile": 3.42,
        "UndergraduateGPA_50th_Percentile": 3.07,
        "UndergraduateGPA_25th_Percentile": 2.85,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "Vermont Law School",
        "AverageGPA": 3.19,
        "AverageLSAT": 152,
        "AcceptanceRate": 64.53,
        "UndergraduateGPA_75th_Percentile": 3.49,
        "UndergraduateGPA_50th_Percentile": 3.19,
        "UndergraduateGPA_25th_Percentile": 2.96,
        "LSAT_75th_Percentile": 157,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Roger Williams University",
        "AverageGPA": 3.21,
        "AverageLSAT": 149,
        "AcceptanceRate": 64.87,
        "UndergraduateGPA_75th_Percentile": 3.56,
        "UndergraduateGPA_50th_Percentile": 3.21,
        "UndergraduateGPA_25th_Percentile": 2.91,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 149,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "Northern Kentucky University",
        "AverageGPA": 3.19,
        "AverageLSAT": 152,
        "AcceptanceRate": 65.28,
        "UndergraduateGPA_75th_Percentile": 3.53,
        "UndergraduateGPA_50th_Percentile": 3.19,
        "UndergraduateGPA_25th_Percentile": 2.82,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 150
    },
    {
        "University": "University of North Dakota",
        "AverageGPA": 3.34,
        "AverageLSAT": 148,
        "AcceptanceRate": 66.35,
        "UndergraduateGPA_75th_Percentile": 3.59,
        "UndergraduateGPA_50th_Percentile": 3.34,
        "UndergraduateGPA_25th_Percentile": 2.99,
        "LSAT_75th_Percentile": 151,
        "LSAT_50th_Percentile": 148,
        "LSAT_25th_Percentile": 145
    },
    {
        "University": "Mississippi College",
        "AverageGPA": 3.22,
        "AverageLSAT": 148,
        "AcceptanceRate": 70.05,
        "UndergraduateGPA_75th_Percentile": 3.54,
        "UndergraduateGPA_50th_Percentile": 3.22,
        "UndergraduateGPA_25th_Percentile": 2.97,
        "LSAT_75th_Percentile": 152,
        "LSAT_50th_Percentile": 148,
        "LSAT_25th_Percentile": 146
    },
    {
        "University": "New England Law | Boston",
        "AverageGPA": 3.27,
        "AverageLSAT": 152,
        "AcceptanceRate": 70.51,
        "UndergraduateGPA_75th_Percentile": 3.59,
        "UndergraduateGPA_50th_Percentile": 3.27,
        "UndergraduateGPA_25th_Percentile": 2.92,
        "LSAT_75th_Percentile": 155,
        "LSAT_50th_Percentile": 152,
        "LSAT_25th_Percentile": 148
    },
    {
        "University": "Capital University",
        "AverageGPA": 3.21,
        "AverageLSAT": 150,
        "AcceptanceRate": 76.12,
        "UndergraduateGPA_75th_Percentile": 3.51,
        "UndergraduateGPA_50th_Percentile": 3.21,
        "UndergraduateGPA_25th_Percentile": 2.85,
        "LSAT_75th_Percentile": 154,
        "LSAT_50th_Percentile": 150,
        "LSAT_25th_Percentile": 147
    },
    {
        "University": "UNT Dallas College Of Law",
        "AverageGPA": 3.1,
        "AverageLSAT": 151,
        "AcceptanceRate": 34.26,
        "UndergraduateGPA_75th_Percentile": 3.47,
        "UndergraduateGPA_50th_Percentile": 3.1,
        "UndergraduateGPA_25th_Percentile": 2.85,
        "LSAT_75th_Percentile": 153,
        "LSAT_50th_Percentile": 151,
        "LSAT_25th_Percentile": 149
    },
    {
        "University": "University of Puerto Rico",
        "AverageGPA": 3.61,
        "AverageLSAT": 144,
        "AcceptanceRate": 43.16,
        "UndergraduateGPA_75th_Percentile": 3.81,
        "UndergraduateGPA_50th_Percentile": 3.61,
        "UndergraduateGPA_25th_Percentile": 3.33,
        "LSAT_75th_Percentile": 149,
        "LSAT_50th_Percentile": 144,
        "LSAT_25th_Percentile": 141
    },
    {
        "University": "Inter American University Of Puerto Rico",
        "AverageGPA": 3.31,
        "AverageLSAT": 139,
        "AcceptanceRate": 53.76,
        "UndergraduateGPA_75th_Percentile": 3.56,
        "UndergraduateGPA_50th_Percentile": 3.31,
        "UndergraduateGPA_25th_Percentile": 3.06,
        "LSAT_75th_Percentile": 143,
        "LSAT_50th_Percentile": 139,
        "LSAT_25th_Percentile": 136
    },
    {
        "University": "Pontifical Catholic University Of P.R.",
        "AverageGPA": 3.27,
        "AverageLSAT": 135,
        "AcceptanceRate": 54.64,
        "UndergraduateGPA_75th_Percentile": 3.57,
        "UndergraduateGPA_50th_Percentile": 3.27,
        "UndergraduateGPA_25th_Percentile": 2.97,
        "LSAT_75th_Percentile": 139,
        "LSAT_50th_Percentile": 135,
        "LSAT_25th_Percentile": 132
    }
]
/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


export const PhDProgram_Data = [
    {
        "University": "University Of California, San Francisco (UCSF)",
        "AverageGPA": 3.74,
        "AverageGRE": 328,
        "AcceptanceRate": 23.1536926,
        "UndergraduateGPA_25th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.74,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 165,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Brown University",
        "AverageGPA": 3.75,
        "AverageGRE": 329,
        "AcceptanceRate": 23.4422492,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.905,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Stanford University",
        "AverageGPA": 3.9,
        "AverageGRE": 332,
        "AcceptanceRate": 24.0784983,
        "UndergraduateGPA_25th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.9,
        "UndergraduateGPA_75th_Percentile": 3.985,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 164,
        "GREQuant_50th_Percentile": 168,
        "GREQuant_75th_Percentile": 170
    },
    {
        "University": "Yale University",
        "AverageGPA": 3.9,
        "AverageGRE": 329,
        "AcceptanceRate": 24.6557203,
        "UndergraduateGPA_25th_Percentile": 3.7225,
        "UndergraduateGPA_50th_Percentile": 3.9,
        "UndergraduateGPA_75th_Percentile": 3.97,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "Princeton University",
        "AverageGPA": 3.88,
        "AverageGRE": 331,
        "AcceptanceRate": 25.1865192,
        "UndergraduateGPA_25th_Percentile": 3.735,
        "UndergraduateGPA_50th_Percentile": 3.88,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "GREVerbal_25th_Percentile": 160,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 163,
        "GREQuant_50th_Percentile": 167,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "Massachusetts Institute Of Technology (MIT)",
        "AverageGPA": 3.92,
        "AverageGRE": 332,
        "AcceptanceRate": 26.7641271,
        "UndergraduateGPA_25th_Percentile": 3.8125,
        "UndergraduateGPA_50th_Percentile": 3.92,
        "UndergraduateGPA_75th_Percentile": 3.9875,
        "GREVerbal_25th_Percentile": 160,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 164,
        "GREQuant_50th_Percentile": 168,
        "GREQuant_75th_Percentile": 170
    },
    {
        "University": "Duke University",
        "AverageGPA": 3.81,
        "AverageGRE": 328,
        "AcceptanceRate": 27.3982829,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.81,
        "UndergraduateGPA_75th_Percentile": 3.94,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 163,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "University Of California, Berkeley (UCB)",
        "AverageGPA": 3.9,
        "AverageGRE": 330,
        "AcceptanceRate": 27.4848255,
        "UndergraduateGPA_25th_Percentile": 3.8,
        "UndergraduateGPA_50th_Percentile": 3.9,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "GREVerbal_25th_Percentile": 160,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 163.25,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "University Of Pennsylvania (UPenn)",
        "AverageGPA": 3.86,
        "AverageGRE": 329,
        "AcceptanceRate": 27.8076303,
        "UndergraduateGPA_25th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.86,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "Harvard University",
        "AverageGPA": 3.85,
        "AverageGRE": 333,
        "AcceptanceRate": 28.5070273,
        "UndergraduateGPA_25th_Percentile": 3.7175,
        "UndergraduateGPA_50th_Percentile": 3.85,
        "UndergraduateGPA_75th_Percentile": 3.9525,
        "GREVerbal_25th_Percentile": 162.75,
        "GREVerbal_50th_Percentile": 166,
        "GREVerbal_75th_Percentile": 168.25,
        "GREQuant_25th_Percentile": 163,
        "GREQuant_50th_Percentile": 167,
        "GREQuant_75th_Percentile": 170
    },
    {
        "University": "Rockefeller University",
        "AverageGPA": 3.85,
        "AverageGRE": 328,
        "AcceptanceRate": 29.1666667,
        "UndergraduateGPA_25th_Percentile": 3.74,
        "UndergraduateGPA_50th_Percentile": 3.85,
        "UndergraduateGPA_75th_Percentile": 3.97,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 165,
        "GREVerbal_75th_Percentile": 169,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 165.5
    },
    {
        "University": "Columbia University",
        "AverageGPA": 3.85,
        "AverageGRE": 330,
        "AcceptanceRate": 29.2857143,
        "UndergraduateGPA_25th_Percentile": 3.72,
        "UndergraduateGPA_50th_Percentile": 3.85,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 161.75,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "Northwestern University",
        "AverageGPA": 3.83,
        "AverageGRE": 328,
        "AcceptanceRate": 29.8255487,
        "UndergraduateGPA_25th_Percentile": 3.64,
        "UndergraduateGPA_50th_Percentile": 3.83,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Vanderbilt University",
        "AverageGPA": 3.75,
        "AverageGRE": 323,
        "AcceptanceRate": 29.8821549,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 165
    },
    {
        "University": "University Of Chicago (UChicago)",
        "AverageGPA": 3.865,
        "AverageGRE": 331,
        "AcceptanceRate": 30.6455664,
        "UndergraduateGPA_25th_Percentile": 3.73,
        "UndergraduateGPA_50th_Percentile": 3.865,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "GREVerbal_25th_Percentile": 161,
        "GREVerbal_50th_Percentile": 165,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 162,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 170
    },
    {
        "University": "New York University (NYU)",
        "AverageGPA": 3.8,
        "AverageGRE": 327,
        "AcceptanceRate": 30.6589147,
        "UndergraduateGPA_25th_Percentile": 3.61,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.94,
        "GREVerbal_25th_Percentile": 160,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "Emory University",
        "AverageGPA": 3.755,
        "AverageGRE": 322.5,
        "AcceptanceRate": 30.9027778,
        "UndergraduateGPA_25th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.755,
        "UndergraduateGPA_75th_Percentile": 3.9325,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 161.5,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 164.25
    },
    {
        "University": "Carnegie Mellon University (CMU)",
        "AverageGPA": 3.83,
        "AverageGRE": 329,
        "AcceptanceRate": 32.2864322,
        "UndergraduateGPA_25th_Percentile": 3.67,
        "UndergraduateGPA_50th_Percentile": 3.83,
        "UndergraduateGPA_75th_Percentile": 3.94,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 164,
        "GREQuant_50th_Percentile": 168,
        "GREQuant_75th_Percentile": 170
    },
    {
        "University": "Cornell University",
        "AverageGPA": 3.87,
        "AverageGRE": 328,
        "AcceptanceRate": 33.2619405,
        "UndergraduateGPA_25th_Percentile": 3.705,
        "UndergraduateGPA_50th_Percentile": 3.87,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "GREVerbal_25th_Percentile": 157.5,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "California Institute Of Technology (Caltech)",
        "AverageGPA": 3.9,
        "AverageGRE": 332,
        "AcceptanceRate": 34.6521146,
        "UndergraduateGPA_25th_Percentile": 3.79,
        "UndergraduateGPA_50th_Percentile": 3.9,
        "UndergraduateGPA_75th_Percentile": 3.965,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 167.5,
        "GREQuant_25th_Percentile": 165,
        "GREQuant_50th_Percentile": 168,
        "GREQuant_75th_Percentile": 170
    },
    {
        "University": "University Of Michigan, Ann Arbor (UMich)",
        "AverageGPA": 3.83,
        "AverageGRE": 327,
        "AcceptanceRate": 35.3736971,
        "UndergraduateGPA_25th_Percentile": 3.6825,
        "UndergraduateGPA_50th_Percentile": 3.83,
        "UndergraduateGPA_75th_Percentile": 3.94,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Notre Dame",
        "AverageGPA": 3.73,
        "AverageGRE": 323,
        "AcceptanceRate": 36.0813704,
        "UndergraduateGPA_25th_Percentile": 3.54,
        "UndergraduateGPA_50th_Percentile": 3.73,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Johns Hopkins University (JHU)",
        "AverageGPA": 3.78,
        "AverageGRE": 326,
        "AcceptanceRate": 36.5417867,
        "UndergraduateGPA_25th_Percentile": 3.61,
        "UndergraduateGPA_50th_Percentile": 3.78,
        "UndergraduateGPA_75th_Percentile": 3.88,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "Dartmouth College",
        "AverageGPA": 3.65,
        "AverageGRE": 324,
        "AcceptanceRate": 36.6255144,
        "UndergraduateGPA_25th_Percentile": 3.2,
        "UndergraduateGPA_50th_Percentile": 3.65,
        "UndergraduateGPA_75th_Percentile": 3.82,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 161,
        "GREQuant_25th_Percentile": 162,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Washington University, St. Louis (WUSTL)",
        "AverageGPA": 3.85,
        "AverageGRE": 327,
        "AcceptanceRate": 37.1471025,
        "UndergraduateGPA_25th_Percentile": 3.6075,
        "UndergraduateGPA_50th_Percentile": 3.85,
        "UndergraduateGPA_75th_Percentile": 3.9425,
        "GREVerbal_25th_Percentile": 160,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 167.25,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "University Of Washington, Seattle (UW)",
        "AverageGPA": 3.81,
        "AverageGRE": 327,
        "AcceptanceRate": 37.2513562,
        "UndergraduateGPA_25th_Percentile": 3.665,
        "UndergraduateGPA_50th_Percentile": 3.81,
        "UndergraduateGPA_75th_Percentile": 3.94,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of California, San Diego (UCSD)",
        "AverageGPA": 3.8,
        "AverageGRE": 326,
        "AcceptanceRate": 38.4114179,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "University Of Oregon",
        "AverageGPA": 3.8,
        "AverageGRE": 323,
        "AcceptanceRate": 40.3571429,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 165
    },
    {
        "University": "University Of Rochester",
        "AverageGPA": 3.75,
        "AverageGRE": 324,
        "AcceptanceRate": 41.7540515,
        "UndergraduateGPA_25th_Percentile": 3.41,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of North Carolina, Chapel Hill (UNC)",
        "AverageGPA": 3.81,
        "AverageGRE": 327,
        "AcceptanceRate": 41.8576105,
        "UndergraduateGPA_25th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.81,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Brandeis University",
        "AverageGPA": 3.81,
        "AverageGRE": 323,
        "AcceptanceRate": 42.1828909,
        "UndergraduateGPA_25th_Percentile": 3.64,
        "UndergraduateGPA_50th_Percentile": 3.81,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 155.5,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 161,
        "GREQuant_25th_Percentile": 156.5,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "University Of Virginia (UVA)",
        "AverageGPA": 3.83,
        "AverageGRE": 325,
        "AcceptanceRate": 42.7647059,
        "UndergraduateGPA_25th_Percentile": 3.5825,
        "UndergraduateGPA_50th_Percentile": 3.83,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "GREVerbal_25th_Percentile": 156.75,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 167.25
    },
    {
        "University": "University Of California, Los Angeles (UCLA)",
        "AverageGPA": 3.8,
        "AverageGRE": 327,
        "AcceptanceRate": 42.870655,
        "UndergraduateGPA_25th_Percentile": 3.64,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Boston University (BU)",
        "AverageGPA": 3.8,
        "AverageGRE": 326,
        "AcceptanceRate": 43.100116,
        "UndergraduateGPA_25th_Percentile": 3.5425,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of California, Santa Barbara (UCSB)",
        "AverageGPA": 3.82,
        "AverageGRE": 327,
        "AcceptanceRate": 43.4294089,
        "UndergraduateGPA_25th_Percentile": 3.7,
        "UndergraduateGPA_50th_Percentile": 3.82,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of California, Santa Cruz (UCSC)",
        "AverageGPA": 3.79,
        "AverageGRE": 326,
        "AcceptanceRate": 45.1874367,
        "UndergraduateGPA_25th_Percentile": 3.57,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "George Washington University (GWU)",
        "AverageGPA": 3.77,
        "AverageGRE": 323,
        "AcceptanceRate": 45.4285714,
        "UndergraduateGPA_25th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_75th_Percentile": 3.89,
        "GREVerbal_25th_Percentile": 157.5,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 166.5,
        "GREQuant_25th_Percentile": 157.5,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "University Of Texas, Austin (UT Austin)",
        "AverageGPA": 3.82,
        "AverageGRE": 325,
        "AcceptanceRate": 45.7063712,
        "UndergraduateGPA_25th_Percentile": 3.68,
        "UndergraduateGPA_50th_Percentile": 3.82,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Syracuse University",
        "AverageGPA": 3.75,
        "AverageGRE": 320,
        "AcceptanceRate": 45.7198444,
        "UndergraduateGPA_25th_Percentile": 3.57,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "GREVerbal_25th_Percentile": 158.25,
        "GREVerbal_50th_Percentile": 161.5,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 154,
        "GREQuant_50th_Percentile": 158.5,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "Tufts University",
        "AverageGPA": 3.6,
        "AverageGRE": 318,
        "AcceptanceRate": 45.7300275,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "GREVerbal_25th_Percentile": 151,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "Georgetown University",
        "AverageGPA": 3.86,
        "AverageGRE": 324,
        "AcceptanceRate": 45.777027,
        "UndergraduateGPA_25th_Percentile": 3.78,
        "UndergraduateGPA_50th_Percentile": 3.86,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 155,
        "GREQuant_50th_Percentile": 160,
        "GREQuant_75th_Percentile": 163
    },
    {
        "University": "University Of Massachusetts, Amherst (UMass)",
        "AverageGPA": 3.78,
        "AverageGRE": 324,
        "AcceptanceRate": 45.805981,
        "UndergraduateGPA_25th_Percentile": 3.535,
        "UndergraduateGPA_50th_Percentile": 3.78,
        "UndergraduateGPA_75th_Percentile": 3.925,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Rice University",
        "AverageGPA": 3.79,
        "AverageGRE": 326,
        "AcceptanceRate": 45.9488273,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 169.5
    },
    {
        "University": "University Of Pittsburgh",
        "AverageGPA": 3.75,
        "AverageGRE": 322,
        "AcceptanceRate": 46.2093863,
        "UndergraduateGPA_25th_Percentile": 3.455,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Pennsylvania State University (PennState)",
        "AverageGPA": 3.74,
        "AverageGRE": 324,
        "AcceptanceRate": 46.2287105,
        "UndergraduateGPA_25th_Percentile": 3.56,
        "UndergraduateGPA_50th_Percentile": 3.74,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "University Of Southern California (USC)",
        "AverageGPA": 3.79,
        "AverageGRE": 326,
        "AcceptanceRate": 46.3607595,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 170
    },
    {
        "University": "University Of Colorado, Boulder",
        "AverageGPA": 3.77,
        "AverageGRE": 326,
        "AcceptanceRate": 47.0293486,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 159.75,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of British Columbia (UBC)",
        "AverageGPA": 3.755,
        "AverageGRE": 326,
        "AcceptanceRate": 47.4025974,
        "UndergraduateGPA_25th_Percentile": 3.625,
        "UndergraduateGPA_50th_Percentile": 3.755,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 164,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 158.75,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "University Of Wisconsin, Madison",
        "AverageGPA": 3.81,
        "AverageGRE": 327,
        "AcceptanceRate": 47.4843623,
        "UndergraduateGPA_25th_Percentile": 3.65,
        "UndergraduateGPA_50th_Percentile": 3.81,
        "UndergraduateGPA_75th_Percentile": 3.915,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Maryland, College Park (UMD)",
        "AverageGPA": 3.8,
        "AverageGRE": 326,
        "AcceptanceRate": 47.9638009,
        "UndergraduateGPA_25th_Percentile": 3.65,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University of Texas, Austin (UT Austin)",
        "AverageGPA": 3.77,
        "AverageGRE": 324,
        "AcceptanceRate": 48.0825959,
        "UndergraduateGPA_25th_Percentile": 3.62,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "GREVerbal_25th_Percentile": 156.5,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 166.5,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 168.5
    },
    {
        "University": "Case Western Reserve University (CWRU)",
        "AverageGPA": 3.565,
        "AverageGRE": 319.5,
        "AcceptanceRate": 48.1481481,
        "UndergraduateGPA_25th_Percentile": 3.46,
        "UndergraduateGPA_50th_Percentile": 3.565,
        "UndergraduateGPA_75th_Percentile": 3.8425,
        "GREVerbal_25th_Percentile": 153.25,
        "GREVerbal_50th_Percentile": 156,
        "GREVerbal_75th_Percentile": 164.25,
        "GREQuant_25th_Percentile": 153.5,
        "GREQuant_50th_Percentile": 163.5,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Minnesota, Twin Cities (UMN)",
        "AverageGPA": 3.8,
        "AverageGRE": 326,
        "AcceptanceRate": 48.4888889,
        "UndergraduateGPA_25th_Percentile": 3.63,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Icahn School Of Medicine At Mount Sinai",
        "AverageGPA": 3.55,
        "AverageGRE": 324,
        "AcceptanceRate": 48.5714286,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.55,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "GREVerbal_25th_Percentile": 160,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "Baylor University",
        "AverageGPA": 3.8,
        "AverageGRE": 325,
        "AcceptanceRate": 48.8188976,
        "UndergraduateGPA_25th_Percentile": 3.61,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.995,
        "GREVerbal_25th_Percentile": 161.5,
        "GREVerbal_50th_Percentile": 165,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 154.5,
        "GREQuant_50th_Percentile": 160,
        "GREQuant_75th_Percentile": 162.5
    },
    {
        "University": "Pennsylvania State University (Penn State)",
        "AverageGPA": 3.7,
        "AverageGRE": 323,
        "AcceptanceRate": 48.9666137,
        "UndergraduateGPA_25th_Percentile": 3.5225,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 151.25,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Waterloo",
        "AverageGPA": 3.805,
        "AverageGRE": 324.5,
        "AcceptanceRate": 49.0825688,
        "UndergraduateGPA_25th_Percentile": 3.7125,
        "UndergraduateGPA_50th_Percentile": 3.805,
        "UndergraduateGPA_75th_Percentile": 3.9375,
        "GREVerbal_25th_Percentile": 152.75,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 164.75,
        "GREQuant_25th_Percentile": 152.5,
        "GREQuant_50th_Percentile": 166.5,
        "GREQuant_75th_Percentile": 169.5
    },
    {
        "University": "University Of Illinois, Urbana-Champaign (UIUC)",
        "AverageGPA": 3.84,
        "AverageGRE": 325.5,
        "AcceptanceRate": 49.148012,
        "UndergraduateGPA_25th_Percentile": 3.67,
        "UndergraduateGPA_50th_Percentile": 3.84,
        "UndergraduateGPA_75th_Percentile": 3.95,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 162,
        "GREQuant_50th_Percentile": 165.5,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Arizona",
        "AverageGPA": 3.765,
        "AverageGRE": 323,
        "AcceptanceRate": 49.4604317,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.765,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 153.25,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 163.75,
        "GREQuant_25th_Percentile": 157.25,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 167.75
    },
    {
        "University": "University Of Texas Southwestern",
        "AverageGPA": 3.55,
        "AverageGRE": 315,
        "AcceptanceRate": 50,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "UndergraduateGPA_50th_Percentile": 3.55,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "GREVerbal_25th_Percentile": 151.5,
        "GREVerbal_50th_Percentile": 155,
        "GREVerbal_75th_Percentile": 159.5,
        "GREQuant_25th_Percentile": 156,
        "GREQuant_50th_Percentile": 160,
        "GREQuant_75th_Percentile": 161.5
    },
    {
        "University": "University Of Toronto (UofT)",
        "AverageGPA": 3.81,
        "AverageGRE": 328,
        "AcceptanceRate": 50.4770992,
        "UndergraduateGPA_25th_Percentile": 3.625,
        "UndergraduateGPA_50th_Percentile": 3.81,
        "UndergraduateGPA_75th_Percentile": 3.975,
        "GREVerbal_25th_Percentile": 159.5,
        "GREVerbal_50th_Percentile": 163,
        "GREVerbal_75th_Percentile": 167,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "Rutgers University",
        "AverageGPA": 3.7,
        "AverageGRE": 320,
        "AcceptanceRate": 51.0233918,
        "UndergraduateGPA_25th_Percentile": 3.41,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.81,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Fordham University",
        "AverageGPA": 3.895,
        "AverageGRE": 321,
        "AcceptanceRate": 51.1627907,
        "UndergraduateGPA_25th_Percentile": 3.6975,
        "UndergraduateGPA_50th_Percentile": 3.895,
        "UndergraduateGPA_75th_Percentile": 3.965,
        "GREVerbal_25th_Percentile": 161.75,
        "GREVerbal_50th_Percentile": 163.5,
        "GREVerbal_75th_Percentile": 168,
        "GREQuant_25th_Percentile": 155,
        "GREQuant_50th_Percentile": 157.5,
        "GREQuant_75th_Percentile": 162
    },
    {
        "University": "Colorado School Of Mines (CSM)",
        "AverageGPA": 3.68,
        "AverageGRE": 318,
        "AcceptanceRate": 51.5151515,
        "UndergraduateGPA_25th_Percentile": 3.485,
        "UndergraduateGPA_50th_Percentile": 3.68,
        "UndergraduateGPA_75th_Percentile": 3.81,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 161.5,
        "GREQuant_25th_Percentile": 156.5,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 165.5
    },
    {
        "University": "Drexel University",
        "AverageGPA": 3.565,
        "AverageGRE": 322,
        "AcceptanceRate": 51.6129032,
        "UndergraduateGPA_25th_Percentile": 3.405,
        "UndergraduateGPA_50th_Percentile": 3.565,
        "UndergraduateGPA_75th_Percentile": 3.795,
        "GREVerbal_25th_Percentile": 152,
        "GREVerbal_50th_Percentile": 157.5,
        "GREVerbal_75th_Percentile": 161.75,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 164.5,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "Scripps Institute",
        "AverageGPA": 3.7,
        "AverageGRE": 327.5,
        "AcceptanceRate": 52.0408163,
        "UndergraduateGPA_25th_Percentile": 3.6025,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.84,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 161.5,
        "GREVerbal_75th_Percentile": 166.5,
        "GREQuant_25th_Percentile": 165,
        "GREQuant_50th_Percentile": 166,
        "GREQuant_75th_Percentile": 167.75
    },
    {
        "University": "University Of Iowa",
        "AverageGPA": 3.77,
        "AverageGRE": 321,
        "AcceptanceRate": 52.3611111,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "University Of Florida, Gainesville (UFL)",
        "AverageGPA": 3.67,
        "AverageGRE": 319,
        "AcceptanceRate": 52.4518389,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.67,
        "UndergraduateGPA_75th_Percentile": 3.83,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 156,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Baylor College Of Medicine",
        "AverageGPA": 3.7,
        "AverageGRE": 322,
        "AcceptanceRate": 52.4822695,
        "UndergraduateGPA_25th_Percentile": 3.58,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.7875,
        "GREVerbal_25th_Percentile": 156.5,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 159.75,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Tulane University",
        "AverageGPA": 3.79,
        "AverageGRE": 318,
        "AcceptanceRate": 52.9953917,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_75th_Percentile": 3.875,
        "GREVerbal_25th_Percentile": 153.5,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 163.25,
        "GREQuant_25th_Percentile": 154.5,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 163.75
    },
    {
        "University": "Georgia Institute Of Technology (GTech)",
        "AverageGPA": 3.8,
        "AverageGRE": 326,
        "AcceptanceRate": 53.1397174,
        "UndergraduateGPA_25th_Percentile": 3.63,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.92,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 163,
        "GREQuant_50th_Percentile": 167,
        "GREQuant_75th_Percentile": 169
    },
    {
        "University": "University Of Missouri",
        "AverageGPA": 3.72,
        "AverageGRE": 316,
        "AcceptanceRate": 53.2994924,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.72,
        "UndergraduateGPA_75th_Percentile": 3.925,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 162.5,
        "GREQuant_25th_Percentile": 153.5,
        "GREQuant_50th_Percentile": 156,
        "GREQuant_75th_Percentile": 160
    },
    {
        "University": "Colorado State University (CSU)",
        "AverageGPA": 3.69,
        "AverageGRE": 319,
        "AcceptanceRate": 54.2307692,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "UndergraduateGPA_50th_Percentile": 3.69,
        "UndergraduateGPA_75th_Percentile": 3.885,
        "GREVerbal_25th_Percentile": 153,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 162,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Indiana University, Bloomington",
        "AverageGPA": 3.71,
        "AverageGRE": 324,
        "AcceptanceRate": 54.4212797,
        "UndergraduateGPA_25th_Percentile": 3.4775,
        "UndergraduateGPA_50th_Percentile": 3.71,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 167.75
    },
    {
        "University": "University Of Connecticut",
        "AverageGPA": 3.745,
        "AverageGRE": 321,
        "AcceptanceRate": 54.4217687,
        "UndergraduateGPA_25th_Percentile": 3.5225,
        "UndergraduateGPA_50th_Percentile": 3.745,
        "UndergraduateGPA_75th_Percentile": 3.8975,
        "GREVerbal_25th_Percentile": 156,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 151.75,
        "GREQuant_50th_Percentile": 160,
        "GREQuant_75th_Percentile": 163
    },
    {
        "University": "University Of Delaware",
        "AverageGPA": 3.785,
        "AverageGRE": 322,
        "AcceptanceRate": 54.9618321,
        "UndergraduateGPA_25th_Percentile": 3.4925,
        "UndergraduateGPA_50th_Percentile": 3.785,
        "UndergraduateGPA_75th_Percentile": 3.89,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of California, Irvine (UCI)",
        "AverageGPA": 3.7,
        "AverageGRE": 325,
        "AcceptanceRate": 55.1587302,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.87,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "University Of Illinois, Chicago (UIC)",
        "AverageGPA": 3.75,
        "AverageGRE": 316,
        "AcceptanceRate": 56.0483871,
        "UndergraduateGPA_25th_Percentile": 3.5025,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.86,
        "GREVerbal_25th_Percentile": 150,
        "GREVerbal_50th_Percentile": 155.5,
        "GREVerbal_75th_Percentile": 161,
        "GREQuant_25th_Percentile": 156,
        "GREQuant_50th_Percentile": 160.5,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "SUNY Stony Brook",
        "AverageGPA": 3.7,
        "AverageGRE": 324,
        "AcceptanceRate": 56.2649641,
        "UndergraduateGPA_25th_Percentile": 3.52,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.85,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Oregon State University",
        "AverageGPA": 3.56,
        "AverageGRE": 317.5,
        "AcceptanceRate": 56.2711864,
        "UndergraduateGPA_25th_Percentile": 3.355,
        "UndergraduateGPA_50th_Percentile": 3.56,
        "UndergraduateGPA_75th_Percentile": 3.8575,
        "GREVerbal_25th_Percentile": 149,
        "GREVerbal_50th_Percentile": 156,
        "GREVerbal_75th_Percentile": 162.5,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 161.5,
        "GREQuant_75th_Percentile": 165.25
    },
    {
        "University": "Purdue University",
        "AverageGPA": 3.795,
        "AverageGRE": 322,
        "AcceptanceRate": 56.6159251,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.795,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 162.75,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Cincinnati",
        "AverageGPA": 3.6,
        "AverageGRE": 318,
        "AcceptanceRate": 56.9444444,
        "UndergraduateGPA_25th_Percentile": 3.35,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_75th_Percentile": 3.87,
        "GREVerbal_25th_Percentile": 145,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 154,
        "GREQuant_50th_Percentile": 160,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "University Of California, Davis (UCD)",
        "AverageGPA": 3.675,
        "AverageGRE": 322,
        "AcceptanceRate": 57.1658615,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.675,
        "UndergraduateGPA_75th_Percentile": 3.8525,
        "GREVerbal_25th_Percentile": 155.75,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "University Of South Florida (USF)",
        "AverageGPA": 3.705,
        "AverageGRE": 317,
        "AcceptanceRate": 57.195572,
        "UndergraduateGPA_25th_Percentile": 3.525,
        "UndergraduateGPA_50th_Percentile": 3.705,
        "UndergraduateGPA_75th_Percentile": 3.945,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 158.5,
        "GREVerbal_75th_Percentile": 161.75,
        "GREQuant_25th_Percentile": 151.25,
        "GREQuant_50th_Percentile": 158.5,
        "GREQuant_75th_Percentile": 161.75
    },
    {
        "University": "Northeastern University",
        "AverageGPA": 3.6,
        "AverageGRE": 321,
        "AcceptanceRate": 57.5,
        "UndergraduateGPA_25th_Percentile": 3.33,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_75th_Percentile": 3.8025,
        "GREVerbal_25th_Percentile": 153.75,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 164.25,
        "GREQuant_25th_Percentile": 159.75,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "Ohio State University (OSU)",
        "AverageGPA": 3.8,
        "AverageGRE": 324,
        "AcceptanceRate": 57.713964,
        "UndergraduateGPA_25th_Percentile": 3.61,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 165,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Miami",
        "AverageGPA": 3.55,
        "AverageGRE": 319.5,
        "AcceptanceRate": 57.7777778,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "UndergraduateGPA_50th_Percentile": 3.55,
        "UndergraduateGPA_75th_Percentile": 3.73,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 165.75,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 159.5,
        "GREQuant_75th_Percentile": 164.5
    },
    {
        "University": "University Of Utah (UoU)",
        "AverageGPA": 3.75,
        "AverageGRE": 321,
        "AcceptanceRate": 58.0996885,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "GREVerbal_25th_Percentile": 152,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Iowa State University (ISU)",
        "AverageGPA": 3.645,
        "AverageGRE": 319.5,
        "AcceptanceRate": 58.3333333,
        "UndergraduateGPA_25th_Percentile": 3.43,
        "UndergraduateGPA_50th_Percentile": 3.645,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 150.75,
        "GREVerbal_50th_Percentile": 155.5,
        "GREVerbal_75th_Percentile": 160,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "Arizona State University (ASU)",
        "AverageGPA": 3.64,
        "AverageGRE": 322,
        "AcceptanceRate": 58.8014981,
        "UndergraduateGPA_25th_Percentile": 3.45,
        "UndergraduateGPA_50th_Percentile": 3.64,
        "UndergraduateGPA_75th_Percentile": 3.89,
        "GREVerbal_25th_Percentile": 152,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 159,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 167.25
    },
    {
        "University": "Michigan State University (MSU)",
        "AverageGPA": 3.765,
        "AverageGRE": 322.5,
        "AcceptanceRate": 59.2198582,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.765,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 160.5,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "Rensselaer Polytechnic Institute (RPI)",
        "AverageGPA": 3.7,
        "AverageGRE": 324,
        "AcceptanceRate": 59.223301,
        "UndergraduateGPA_25th_Percentile": 3.415,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.85,
        "GREVerbal_25th_Percentile": 157,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "American University",
        "AverageGPA": 3.73,
        "AverageGRE": 321,
        "AcceptanceRate": 59.2592593,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.73,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 155,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 161.5
    },
    {
        "University": "Oklahoma State University",
        "AverageGPA": 3.495,
        "AverageGRE": 316.5,
        "AcceptanceRate": 59.7014925,
        "UndergraduateGPA_25th_Percentile": 3.2425,
        "UndergraduateGPA_50th_Percentile": 3.495,
        "UndergraduateGPA_75th_Percentile": 3.86,
        "GREVerbal_25th_Percentile": 152,
        "GREVerbal_50th_Percentile": 155,
        "GREVerbal_75th_Percentile": 161,
        "GREQuant_25th_Percentile": 158.25,
        "GREQuant_50th_Percentile": 161.5,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "University Of Kentucky",
        "AverageGPA": 3.7,
        "AverageGRE": 316,
        "AcceptanceRate": 60.2272727,
        "UndergraduateGPA_25th_Percentile": 3.455,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.81,
        "GREVerbal_25th_Percentile": 153,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 163.5,
        "GREQuant_25th_Percentile": 155,
        "GREQuant_50th_Percentile": 158,
        "GREQuant_75th_Percentile": 164.5
    },
    {
        "University": "Lehigh University",
        "AverageGPA": 3.72,
        "AverageGRE": 318,
        "AcceptanceRate": 60.952381,
        "UndergraduateGPA_25th_Percentile": 3.35,
        "UndergraduateGPA_50th_Percentile": 3.72,
        "UndergraduateGPA_75th_Percentile": 3.875,
        "GREVerbal_25th_Percentile": 151.5,
        "GREVerbal_50th_Percentile": 154,
        "GREVerbal_75th_Percentile": 161,
        "GREQuant_25th_Percentile": 160.5,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 165.5
    },
    {
        "University": "University Of Houston",
        "AverageGPA": 3.64,
        "AverageGRE": 322,
        "AcceptanceRate": 61.341853,
        "UndergraduateGPA_25th_Percentile": 3.47,
        "UndergraduateGPA_50th_Percentile": 3.64,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 152,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 161,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "CUNY",
        "AverageGPA": 3.825,
        "AverageGRE": 323.5,
        "AcceptanceRate": 61.5141956,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.825,
        "UndergraduateGPA_75th_Percentile": 3.93,
        "GREVerbal_25th_Percentile": 157.75,
        "GREVerbal_50th_Percentile": 164.5,
        "GREVerbal_75th_Percentile": 167.25,
        "GREQuant_25th_Percentile": 155,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 165
    },
    {
        "University": "University Of Nebraska, Lincoln",
        "AverageGPA": 3.785,
        "AverageGRE": 317,
        "AcceptanceRate": 61.5555556,
        "UndergraduateGPA_25th_Percentile": 3.605,
        "UndergraduateGPA_50th_Percentile": 3.785,
        "UndergraduateGPA_75th_Percentile": 3.8875,
        "GREVerbal_25th_Percentile": 154.5,
        "GREVerbal_50th_Percentile": 158.5,
        "GREVerbal_75th_Percentile": 161.75,
        "GREQuant_25th_Percentile": 153,
        "GREQuant_50th_Percentile": 158.5,
        "GREQuant_75th_Percentile": 163.75
    },
    {
        "University": "Wayne State University",
        "AverageGPA": 3.62,
        "AverageGRE": 319,
        "AcceptanceRate": 61.7977528,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.62,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 149,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 162,
        "GREQuant_25th_Percentile": 156,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 163
    },
    {
        "University": "University Of Kansas",
        "AverageGPA": 3.745,
        "AverageGRE": 318,
        "AcceptanceRate": 61.8581907,
        "UndergraduateGPA_25th_Percentile": 3.57,
        "UndergraduateGPA_50th_Percentile": 3.745,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 152,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 152.25,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 162
    },
    {
        "University": "Georgia State University (GSU)",
        "AverageGPA": 3.75,
        "AverageGRE": 319,
        "AcceptanceRate": 62.4605678,
        "UndergraduateGPA_25th_Percentile": 3.635,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 153.5,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 152,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 165.5
    },
    {
        "University": "George Mason University (GMU)",
        "AverageGPA": 3.73,
        "AverageGRE": 323,
        "AcceptanceRate": 63.4210526,
        "UndergraduateGPA_25th_Percentile": 3.49,
        "UndergraduateGPA_50th_Percentile": 3.73,
        "UndergraduateGPA_75th_Percentile": 3.895,
        "GREVerbal_25th_Percentile": 157.5,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "University Of Missouri, Columbia",
        "AverageGPA": 3.79,
        "AverageGRE": 321.5,
        "AcceptanceRate": 63.5245902,
        "UndergraduateGPA_25th_Percentile": 3.3,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 151.25,
        "GREVerbal_50th_Percentile": 160.5,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 156.75,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 165.75
    },
    {
        "University": "North Caroline State University (NCSU)",
        "AverageGPA": 3.75,
        "AverageGRE": 323,
        "AcceptanceRate": 64.1199226,
        "UndergraduateGPA_25th_Percentile": 3.565,
        "UndergraduateGPA_50th_Percentile": 3.75,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 153,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 164,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Colorado, Denver",
        "AverageGPA": 3.68,
        "AverageGRE": 323,
        "AcceptanceRate": 64.556962,
        "UndergraduateGPA_25th_Percentile": 3.54,
        "UndergraduateGPA_50th_Percentile": 3.68,
        "UndergraduateGPA_75th_Percentile": 3.765,
        "GREVerbal_25th_Percentile": 158,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 164.5,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 166.5
    },
    {
        "University": "Virginia Tech",
        "AverageGPA": 3.66,
        "AverageGRE": 317,
        "AcceptanceRate": 64.6067416,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "UndergraduateGPA_50th_Percentile": 3.66,
        "UndergraduateGPA_75th_Percentile": 3.765,
        "GREVerbal_25th_Percentile": 151.5,
        "GREVerbal_50th_Percentile": 154,
        "GREVerbal_75th_Percentile": 159.5,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Tennessee",
        "AverageGPA": 3.5,
        "AverageGRE": 316,
        "AcceptanceRate": 64.7540984,
        "UndergraduateGPA_25th_Percentile": 3.365,
        "UndergraduateGPA_50th_Percentile": 3.5,
        "UndergraduateGPA_75th_Percentile": 3.69,
        "GREVerbal_25th_Percentile": 150,
        "GREVerbal_50th_Percentile": 155,
        "GREVerbal_75th_Percentile": 157,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 162.5
    },
    {
        "University": "Auburn University",
        "AverageGPA": 3.4,
        "AverageGRE": 321,
        "AcceptanceRate": 65,
        "UndergraduateGPA_25th_Percentile": 3.28,
        "UndergraduateGPA_50th_Percentile": 3.4,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "GREVerbal_25th_Percentile": 151,
        "GREVerbal_50th_Percentile": 160,
        "GREVerbal_75th_Percentile": 162,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "Ohio State University, Columbus (OSU)",
        "AverageGPA": 3.79,
        "AverageGRE": 327,
        "AcceptanceRate": 65.1877133,
        "UndergraduateGPA_25th_Percentile": 3.625,
        "UndergraduateGPA_50th_Percentile": 3.79,
        "UndergraduateGPA_75th_Percentile": 3.91,
        "GREVerbal_25th_Percentile": 159,
        "GREVerbal_50th_Percentile": 162,
        "GREVerbal_75th_Percentile": 166.5,
        "GREQuant_25th_Percentile": 159.5,
        "GREQuant_50th_Percentile": 165,
        "GREQuant_75th_Percentile": 168
    },
    {
        "University": "University Of Georgia",
        "AverageGPA": 3.625,
        "AverageGRE": 321.5,
        "AcceptanceRate": 65.5102041,
        "UndergraduateGPA_25th_Percentile": 3.44,
        "UndergraduateGPA_50th_Percentile": 3.625,
        "UndergraduateGPA_75th_Percentile": 3.8975,
        "GREVerbal_25th_Percentile": 154.25,
        "GREVerbal_50th_Percentile": 161,
        "GREVerbal_75th_Percentile": 162.75,
        "GREQuant_25th_Percentile": 155.5,
        "GREQuant_50th_Percentile": 160.5,
        "GREQuant_75th_Percentile": 163.75
    },
    {
        "University": "SUNY Buffalo",
        "AverageGPA": 3.7,
        "AverageGRE": 317,
        "AcceptanceRate": 66.0516605,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.9,
        "GREVerbal_25th_Percentile": 152,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 153,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 164
    },
    {
        "University": "Texas A&M University (TAMU)",
        "AverageGPA": 3.7,
        "AverageGRE": 322,
        "AcceptanceRate": 66.8393782,
        "UndergraduateGPA_25th_Percentile": 3.6,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.88,
        "GREVerbal_25th_Percentile": 155,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "University Of Maryland, Baltimore County (UMBC)",
        "AverageGPA": 3.55,
        "AverageGRE": 318,
        "AcceptanceRate": 68.4210526,
        "UndergraduateGPA_25th_Percentile": 3.3,
        "UndergraduateGPA_50th_Percentile": 3.55,
        "UndergraduateGPA_75th_Percentile": 3.7,
        "GREVerbal_25th_Percentile": 150,
        "GREVerbal_50th_Percentile": 155,
        "GREVerbal_75th_Percentile": 162,
        "GREQuant_25th_Percentile": 161,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "Texas A&M University, College Station (TAMU)",
        "AverageGPA": 3.7,
        "AverageGRE": 320.5,
        "AcceptanceRate": 68.6440678,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.8275,
        "GREVerbal_25th_Percentile": 150,
        "GREVerbal_50th_Percentile": 153.5,
        "GREVerbal_75th_Percentile": 158.75,
        "GREQuant_25th_Percentile": 163,
        "GREQuant_50th_Percentile": 167,
        "GREQuant_75th_Percentile": 169.75
    },
    {
        "University": "University Of New Mexico",
        "AverageGPA": 3.6,
        "AverageGRE": 319.5,
        "AcceptanceRate": 68.6746988,
        "UndergraduateGPA_25th_Percentile": 3.3875,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "GREVerbal_25th_Percentile": 152.5,
        "GREVerbal_50th_Percentile": 158.5,
        "GREVerbal_75th_Percentile": 161.5,
        "GREQuant_25th_Percentile": 155.75,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 164.25
    },
    {
        "University": "SUNY Albany",
        "AverageGPA": 3.63,
        "AverageGRE": 315,
        "AcceptanceRate": 68.683274,
        "UndergraduateGPA_25th_Percentile": 3.405,
        "UndergraduateGPA_50th_Percentile": 3.63,
        "UndergraduateGPA_75th_Percentile": 3.87,
        "GREVerbal_25th_Percentile": 151.5,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 166.5,
        "GREQuant_25th_Percentile": 153.5,
        "GREQuant_50th_Percentile": 158,
        "GREQuant_75th_Percentile": 163
    },
    {
        "University": "University Of California, Riverside (UCR)",
        "AverageGPA": 3.69,
        "AverageGRE": 319,
        "AcceptanceRate": 68.9144737,
        "UndergraduateGPA_25th_Percentile": 3.41,
        "UndergraduateGPA_50th_Percentile": 3.69,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "GREVerbal_25th_Percentile": 150,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 163,
        "GREQuant_25th_Percentile": 157,
        "GREQuant_50th_Percentile": 162,
        "GREQuant_75th_Percentile": 165
    },
    {
        "University": "Clemson University",
        "AverageGPA": 3.6,
        "AverageGRE": 318,
        "AcceptanceRate": 69.1756272,
        "UndergraduateGPA_25th_Percentile": 3.38,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_75th_Percentile": 3.89,
        "GREVerbal_25th_Percentile": 151,
        "GREVerbal_50th_Percentile": 155,
        "GREVerbal_75th_Percentile": 159.5,
        "GREQuant_25th_Percentile": 158,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166.5
    },
    {
        "University": "University Of South Carolina",
        "AverageGPA": 3.7,
        "AverageGRE": 316,
        "AcceptanceRate": 69.3266833,
        "UndergraduateGPA_25th_Percentile": 3.435,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.875,
        "GREVerbal_25th_Percentile": 151,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 161.5,
        "GREQuant_25th_Percentile": 154,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 165.5
    },
    {
        "University": "West Virginia University",
        "AverageGPA": 3.77,
        "AverageGRE": 314,
        "AcceptanceRate": 69.6721311,
        "UndergraduateGPA_25th_Percentile": 3.5,
        "UndergraduateGPA_50th_Percentile": 3.77,
        "UndergraduateGPA_75th_Percentile": 3.86,
        "GREVerbal_25th_Percentile": 153,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 159,
        "GREQuant_25th_Percentile": 152,
        "GREQuant_50th_Percentile": 157,
        "GREQuant_75th_Percentile": 162
    },
    {
        "University": "Washington State University (WSU)",
        "AverageGPA": 3.6,
        "AverageGRE": 316,
        "AcceptanceRate": 70.6140351,
        "UndergraduateGPA_25th_Percentile": 3.43,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_75th_Percentile": 3.835,
        "GREVerbal_25th_Percentile": 150.5,
        "GREVerbal_50th_Percentile": 156,
        "GREVerbal_75th_Percentile": 162,
        "GREQuant_25th_Percentile": 155.5,
        "GREQuant_50th_Percentile": 160,
        "GREQuant_75th_Percentile": 163.5
    },
    {
        "University": "University Of Tennessee, Knoxville",
        "AverageGPA": 3.6,
        "AverageGRE": 320,
        "AcceptanceRate": 72.0930233,
        "UndergraduateGPA_25th_Percentile": 3.3075,
        "UndergraduateGPA_50th_Percentile": 3.6,
        "UndergraduateGPA_75th_Percentile": 3.775,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 161.75,
        "GREQuant_25th_Percentile": 156.5,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 166.25
    },
    {
        "University": "Florida State University (FSU)",
        "AverageGPA": 3.7,
        "AverageGRE": 317,
        "AcceptanceRate": 73.7430168,
        "UndergraduateGPA_25th_Percentile": 3.5075,
        "UndergraduateGPA_50th_Percentile": 3.7,
        "UndergraduateGPA_75th_Percentile": 3.845,
        "GREVerbal_25th_Percentile": 152.25,
        "GREVerbal_50th_Percentile": 158,
        "GREVerbal_75th_Percentile": 164.75,
        "GREQuant_25th_Percentile": 155.25,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 165
    },
    {
        "University": "Louisiana State University",
        "AverageGPA": 3.58,
        "AverageGRE": 318,
        "AcceptanceRate": 74.8201439,
        "UndergraduateGPA_25th_Percentile": 3.225,
        "UndergraduateGPA_50th_Percentile": 3.58,
        "UndergraduateGPA_75th_Percentile": 3.865,
        "GREVerbal_25th_Percentile": 152.5,
        "GREVerbal_50th_Percentile": 157,
        "GREVerbal_75th_Percentile": 161.5,
        "GREQuant_25th_Percentile": 152,
        "GREQuant_50th_Percentile": 161,
        "GREQuant_75th_Percentile": 167
    },
    {
        "University": "University Of Central Florida (UCF)",
        "AverageGPA": 3.61,
        "AverageGRE": 315,
        "AcceptanceRate": 75.7281553,
        "UndergraduateGPA_25th_Percentile": 3.4,
        "UndergraduateGPA_50th_Percentile": 3.61,
        "UndergraduateGPA_75th_Percentile": 3.8,
        "GREVerbal_25th_Percentile": 147,
        "GREVerbal_50th_Percentile": 152,
        "GREVerbal_75th_Percentile": 159,
        "GREQuant_25th_Percentile": 160,
        "GREQuant_50th_Percentile": 163,
        "GREQuant_75th_Percentile": 166
    },
    {
        "University": "SUNY Binghamton",
        "AverageGPA": 3.8,
        "AverageGRE": 317,
        "AcceptanceRate": 75.93361,
        "UndergraduateGPA_25th_Percentile": 3.5275,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.915,
        "GREVerbal_25th_Percentile": 153.75,
        "GREVerbal_50th_Percentile": 159.5,
        "GREVerbal_75th_Percentile": 164.25,
        "GREQuant_25th_Percentile": 152,
        "GREQuant_50th_Percentile": 157.5,
        "GREQuant_75th_Percentile": 163.25
    },
    {
        "University": "Temple University",
        "AverageGPA": 3.8,
        "AverageGRE": 318,
        "AcceptanceRate": 76.2836186,
        "UndergraduateGPA_25th_Percentile": 3.6275,
        "UndergraduateGPA_50th_Percentile": 3.8,
        "UndergraduateGPA_75th_Percentile": 3.8975,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 160.5,
        "GREVerbal_75th_Percentile": 164,
        "GREQuant_25th_Percentile": 153.25,
        "GREQuant_50th_Percentile": 157.5,
        "GREQuant_75th_Percentile": 161
    },
    {
        "University": "University Of Oklahoma",
        "AverageGPA": 3.825,
        "AverageGRE": 318,
        "AcceptanceRate": 78.313253,
        "UndergraduateGPA_25th_Percentile": 3.62,
        "UndergraduateGPA_50th_Percentile": 3.825,
        "UndergraduateGPA_75th_Percentile": 3.96,
        "GREVerbal_25th_Percentile": 154,
        "GREVerbal_50th_Percentile": 159,
        "GREVerbal_75th_Percentile": 161,
        "GREQuant_25th_Percentile": 154.75,
        "GREQuant_50th_Percentile": 159,
        "GREQuant_75th_Percentile": 165.5
    }
];