"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

// fits the PIXI.Sprite to the parent
// similar to css background-size: cover

exports.default = function (ctx, image, parent) {
  var scale = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;

  var p = parent || ctx.canvas;

  var tAspect = image.width / image.height;
  var pWidth = p.width;
  var pHeight = p.height;

  var pAspect = pWidth / pHeight;

  var width = void 0;
  var height = void 0;

  if (tAspect > pAspect) {
    height = pHeight;
    width = height * tAspect;
  } else {
    width = pWidth;
    height = width / tAspect;
  }

  width *= scale;
  height *= scale;

  var x = (pWidth - width) / 2;
  var y = (pHeight - height) / 2;

  ctx.drawImage(image, x, y, width, height);
};