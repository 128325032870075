'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _seedRandom = require('seed-random');

var _seedRandom2 = _interopRequireDefault(_seedRandom);

var _createPalette = require('./createPalette');

var _createPalette2 = _interopRequireDefault(_createPalette);

var _createRange = require('./createRange');

var _createRange2 = _interopRequireDefault(_createRange);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var _ref$seed = _ref.seed,
      seed = _ref$seed === undefined ? String(Math.floor(Math.random() * 1000000)) : _ref$seed,
      height = _ref.height,
      width = _ref.width,
      _ref$factor = _ref.factor,
      factor = _ref$factor === undefined ? 0.5 : _ref$factor,
      maps = _ref.maps,
      palettes = _ref.palettes;

  var randomFunc = (0, _seedRandom2.default)(seed);
  var random = (0, _createRange2.default)(randomFunc);

  // var mapSrc = maps[Math.floor(random(maps.length))];
  
  // Custom code to deal with map randomness
  var mapArray = [];
  for (let i = 1; i < 24; i++) {
    mapArray.push('/images/maps/' + i + '.JPG');
  }

  // Selecting the random artwork under a certain seed
  var mapSrc = mapArray[Math.floor(random(mapArray.length))];


  return {
    // // rendering opts (old configs for safety)
    // count: Math.floor(random(50, 2000)),
    // endlessBrowser: false, // whether to endlessly step in browser
    // globalAlpha: 1,
    // interval: random(0.001, 0.01),
    // lineStyle: random(1) > 0.8 ? 'round' : 'square',
    // maxRadius: random(30, 100),
    // noiseScalar: [random(0.000001, 0.000001), random(0.0002, 0.004)],
    // pointilism: random(0, 0.1),
    // random: randomFunc,
    // seedName: seed,
    // startArea: random(0.2, 1),
    // steps: 500 || Math.floor(random(100, 1000)),

    // rendering options
    random: randomFunc,
    seedName: seed,
    pointilism: random(0, 0.1),
    noiseScalar: [random(0.000001, 0.000001), random(0.0002, 0.004)],
    globalAlpha: 0.5,
    startArea: random(0.5, 1.5),
    maxRadius: 50 || random(5, 100),
    lineStyle: random(1) > 0.5 ? 'square' : 'square',
    interval: 0.01 || random(0.001, 0.01),
    count: 1000 || Math.floor(random(50, 2000)),
    steps: 1000 || Math.floor(random(100, 1000)),
    endlessBrowser: false, // Whether to endlessly step in browser


    // background image that drives the algorithm
    backgorundFill: 'black',
    backgroundScale: 1,
    backgroundSrc: mapSrc,
    debugLuma: false,

    // browser options
    // height: height / factor,
    palette: (0, _createPalette2.default)(palettes, random),
    pixelRatio: 1,
    height: 720 * 2,
    width: 1280 * 2
    // width: width / factor
  };
};