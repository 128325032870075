'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _smoothstep = require('smoothstep');

var _smoothstep2 = _interopRequireDefault(_smoothstep);

var _colorLuminance = require('color-luminance');

var _colorLuminance2 = _interopRequireDefault(_colorLuminance);

var _createImage = require('./createImage');

var _createImage2 = _interopRequireDefault(_createImage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (ctx, img, opt) {
  var canvas = ctx.canvas;

  var scale = opt.scale || 1;

  var threshold = Array.isArray(opt.threshold) ? opt.threshold : null;

  ctx.fillStyle = opt.fillStyle || 'black';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  (0, _createImage2.default)(ctx, img, canvas, scale);

  var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  var rgba = imageData.data;

  for (var i = 0; i < canvas.width * canvas.height; i += 1) {
    var r = rgba[i * 4 + 0];
    var g = rgba[i * 4 + 1];
    var b = rgba[i * 4 + 2];

    // grayscale
    var L = (0, _colorLuminance2.default)(r, g, b);

    // optional threshold
    if (threshold) {
      L = Math.floor((0, _smoothstep2.default)(threshold[0], threshold[1], L / 255) * 255);
    }

    // replace RGBA
    rgba[i * 4 + 0] = L;
    rgba[i * 4 + 1] = L;
    rgba[i * 4 + 2] = L;
  }

  return imageData;
};