// import react component
import React, { useRef, useEffect, useState } from 'react';

import Art from '../../../react-color-wander';
import axios from 'axios';
import ScrollTrigger from 'react-scroll-trigger';
import M from 'materialize-css';


import { COLORS_LIST } from './colors';
import { SERVER_IP } from '../../../IP';

import { generateSeed, getBestContrast, scrollToSection, resizeCanvas, getEncryptedSeed } from '../../../global';

// import custom stylesheet
import './Artwork.scss';
import "./animation.scss";

// used for mapping for artwork
const ARTWORK_MAP = "/images/maps/";
const MAP_DIVIDER = 43500; // to use 19 maps i.e (999999/55000) + 1 = 19 (approximately)
const PAL_DIVIDER = 8772; // to use 114 colors
// const PAL_DIVIDER = 5000; // to use all colors

const PAGE = "artwork";


// share buttons
const SHARE_BUTTON = {
    FACEBOOK: 1,
    TWITTER: 2,
    PINTEREST: 3
}

const Artwork = ({ modalIsOpen, setModalIsOpen, isArtworkCompleted, setIsArtworkCompleted, CGPA, temporaryCGPA, artSeedValue, CGPATotalCredits, CGPATotalGrade, isLandscape }) => {

    const modalArtworkRef = useRef(null);       // reference to modal artwork
    const modalCrossButtonRef = useRef(null);   // reference to the modal cross button
    const [generatedArtwork, setGeneratedArtwork] = useState(null);     // artwork generated when modal is closed
    const generatedArtworkRef = useRef(null);   // reference to generated artwork
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [artSeedParam, setArtSeedParam] = useState(-1);
    const [complementryColor, setComplementryColor] = useState(null);       // text color write on the artwork
    const artworkContainerRef = useRef(null);
    const [artworkStartTime, setArtworkStartTime] = useState(new Date())
    const [artworkEndTime, setArtworkEndTime] = useState(new Date())
    const [isIconsVisible, setIsIconsVisible] = useState(false);
    const [modalArtwork, setModalArtwork] = useState(null);
    const [artworkBgColor, setArtworkBgColor] = useState("#21242b");
    const [imgURL, setImgURL] = useState("");
    const [shareButtonValue, setShareButtonValue] = useState(null);

    useEffect(() => {
        if (artSeedValue !== "") {
            let myValue = parseInt(generateSeed(artSeedValue, +parseFloat(CGPATotalCredits).toFixed(1), +parseFloat(CGPATotalGrade).toFixed(2)));
            myValue = isNaN(myValue) || myValue < 0 ? 0 : myValue;

            setArtSeedParam(myValue);

            setModalArtwork(
                <Art
                    ref={modalArtworkRef}
                    map={`${ARTWORK_MAP}${Math.round(myValue / MAP_DIVIDER) + 1}.JPG`}
                    palette={COLORS_LIST[Math.floor(myValue / PAL_DIVIDER)]}
                    seed={"" + myValue}
                />
            )

            myValue = Math.floor(myValue / PAL_DIVIDER);
            myValue = (myValue < 0 || myValue >= 200) ? 0 : myValue;
            typeof (myValue) !== "undefined" && setArtworkBgColor(COLORS_LIST[myValue][0])
        }
    }, [artSeedValue])


    useEffect(() => {
        modalArtwork !== null && drawArtwork();
    }, [modalArtwork])


    // return seconds for artwork
    const getArtworkSeconds = () => {
        const seconds = artworkEndTime.getSeconds() - artworkStartTime.getSeconds();
        return (seconds < 8 || seconds > 12) ? 8 : seconds;
    }


    /**
     * Social media share button handlers
    **/

    // to share artwork on facebook
    const handleFacebookShare = () => {

        // parameter name to pass artwork information
        const paramName = "hf96jk28473eraraAInpainCH0DD115tuhadifUr2sk294PHD5754uewrhuhaleemhuikiadsdj";
        const paramValue = `${getEncryptedSeed(parseInt(artSeedParam))}/${getEncryptedSeed(parseInt(getArtworkSeconds()))}`;

        const facebook_url = `http://www.facebook.com/sharer.php?u=${SERVER_IP}/${PAGE}?${paramName}=${paramValue}`;
        window.open(facebook_url)
    }
    // to share artwork on twitter
    const handleTwitterShare = () => {

        // parameter name to pass artwork information
        const paramName = "hf96jk28473eraraAInpainCH0DD115tuhadifUr2sk294PHD5754uewrhuhaleemhuikiadsdj";
        const paramValue = `${getEncryptedSeed(parseInt(artSeedParam))}/${getEncryptedSeed(parseInt(getArtworkSeconds()))}`;

        // text that will be written in tweet 
        const text = `Check out this cool animated artwork made by the GPA calculator I use.`;

        const twitter_url = `https://www.twitter.com/intent/tweet?url=${SERVER_IP}/${PAGE}?${paramName}=${paramValue}&text=${text}`;
        window.open(twitter_url);
    }
    // to share artwork on pinterest
    const handlePintrestShare = () => {

        // parameter name to pass artwork information
        const paramName = "hf96jk28473eraraAInpainCH0DD115tuhadifUr2sk294PHD5754uewrhuhaleemhuikiadsdj";
        const paramValue = `${getEncryptedSeed(parseInt(artSeedParam))}/${getEncryptedSeed(parseInt(getArtworkSeconds()))}`;

        // text that will be displayed on pinterest 
        const text = `Check out this unique animated art made for me by a GPA calculator.`;

        const pintrest_url = encodeURI(`https://www.pinterest.com/pin/create/button/?url=${SERVER_IP}/${PAGE}?${paramName}=${paramValue}&description=${text}`);
        window.open(pintrest_url);
    }

    const copySharingUrl = () => {
        
        // parameter name to pass artwork information
        const paramName = "hf96jk28473eraraAInpainCH0DD115tuhadifUr2sk294PHD5754uewrhuhaleemhuikiadsdj";
        const paramValue = `${getEncryptedSeed(parseInt(artSeedParam))}/${getEncryptedSeed(parseInt(getArtworkSeconds()))}`;

        const textToCopy = `${SERVER_IP}/${PAGE}?${paramName}=${paramValue}`;

        // copy text in clipboard
        navigator.clipboard.writeText(textToCopy);

        M.toast({html: 'Link is copied!'})
    }

    useEffect(() => {
        if(imgURL !== ""){
            if (shareButtonValue === SHARE_BUTTON.FACEBOOK) {
                handleFacebookShare();
            }
            else if (shareButtonValue === SHARE_BUTTON.TWITTER) {
                handleTwitterShare();
            }
            else if (shareButtonValue === SHARE_BUTTON.PINTEREST) {
                handlePintrestShare();
            }
            setShareButtonValue(null);
        }
    }, [imgURL, shareButtonValue])


    // hide/display artwork controls
    const artworkControlsDisplay = (shouldDisplayControls = true) => {
        let artworkControls = document.querySelector(".artwork-controls");
        if (artworkControls) {
            shouldDisplayControls ?
                artworkControls.classList.remove("hide") :
                artworkControls.classList.add("hide");
        }
    }


    // to stop artwork after 10 seconds
    const stopArtworkToDraw = (ms, shouldDisplayControls = true) => {
        window.redrawTimeOutID = setTimeout(() => {

            try {
                generatedArtworkRef.current?.stop();
            } catch (error) {
                console.log(error);
            }
            clearTimeout(window.redrawTimeOutID)
            artworkControlsDisplay(shouldDisplayControls)
        }, ms)
    }

    useEffect(() => {
        generatedArtworkRef.current && generatedArtworkRef.current.draw();
    }, [generatedArtwork])

    // to draw an artwork when modal is open
    const drawArtwork = () => {
        clearTimeout(window.redrawTimeOutID);

        // set complementry color for text that is to be display on the artwork
        // set artwork background color
        const props = modalArtworkRef.current?.props;
        setComplementryColor(getBestContrast(props.palette[0], props.palette.slice(1)));
        setGeneratedArtwork(
            <Art
                ref={generatedArtworkRef}
                map={props.map}
                palette={props.palette}
                seed={props.seed}
            />
        );

        var elem = document.getElementById('artwork-modal');
        var instance = M.Modal.getInstance(elem);
        instance.open();
        // scrollToSection("calculate-cgpa-btn");

        modalArtworkRef.current.draw();

        // artwork maximum time
        const MAX_ARTWORK_TIME = 12;
        let currSec = 0;

        // set artwork start time
        setArtworkStartTime(new Date())

        window.intervalId = setInterval(() => {
            ++currSec;

            if ((currSec === MAX_ARTWORK_TIME)) {
                setArtworkEndTime(new Date())
                try {
                    modalArtworkRef.current?.stop();
                }
                catch (error) {
                    console.log(error)
                }
                try {
                    generatedArtworkRef.current?.stop()
                }
                catch (error) {
                    console.log(error)
                }

                window.modalWait = setTimeout(() => {
                    setModalIsOpen(false);
                    setIsArtworkCompleted(true);
                }, 5000)
                clearInterval(window.intervalId)
            }
        }, 1000)
    }


    useEffect(() => {

        if (!modalIsOpen && isArtworkCompleted) {
            scrollToSection("current-cumulative-gpa-container");
        }

        if (isArtworkCompleted) {
            try {
                modalArtworkRef.current.stop();
            }
            catch (error) {
                console.log(error)
            }

            try {
                generatedArtworkRef.current?.stop();
            }
            catch (error) {
                console.log(error);
            }

        }
    }, [isArtworkCompleted, modalIsOpen])

    useEffect(() => {
        if (!modalIsOpen) {
            artSeedParam !== -1 && generateImage();
        }
        else{
            // reset image url
            setImgURL("");
        }
    }, [modalIsOpen])

    // update end time and stop artwork
    useEffect(() => {
        var elem = document.getElementById('artwork-modal');
        var instance = M.Modal.getInstance(elem);

        if (!modalIsOpen) {
            clearInterval(window.modalWait);
            clearInterval(window.intervalId);
            setArtworkEndTime(new Date())

            instance.close();
        }
        else {

            let ele = document.getElementById("artwork-modal");
            document.addEventListener('click', function (event) {
                var isClickInsideElement = ele.contains(event.target);
                if (!isClickInsideElement) {
                    setIsArtworkCompleted(true);
                    setModalIsOpen(false);
                }
            });

        }

    }, [modalIsOpen])


    // this function redraw artwork
    const redrawArtwork = () => {
        clearTimeout(window.redrawTimeOutID);

        try {
            generatedArtworkRef.current.stop();
        }
        catch (error) {
            console.log(error)
        }

        generatedArtworkRef.current.draw();
        stopArtworkToDraw(getArtworkSeconds() * 1000, true);
    }


    // this function display/hide artwork controls
    const handleArtworkClick = () => {
        let artworkControls = document.querySelector(".artwork-controls");
        if (typeof (artworkControls) !== "undefined") {
            artworkControls.classList.toggle("hide");
        }
    }

    // switch to and from full screen 
    const handleArtworkDoubleClick = (e) => {
        setIsFullScreen(!isFullScreen);
    }

    // this function sets flag for full screen of artwork
    const handleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    }


    // display artwork controls, when switch to aand from full screen
    useEffect(() => {
        document.querySelector("html").style.overflowY = isFullScreen ? "hidden" : "scroll";
        document.body.style.overflowY = isFullScreen ? "hidden" : "scroll";

        artworkControlsDisplay(true)
    }, [isFullScreen])


    // this will execute for only one time
    // fullScreenHandler escape key press to close full screen
    useEffect(() => {
        document.addEventListener("keydown", () => setIsFullScreen(false), false);
    }, [])

    // options for modal
    var modalOptions = {
        onOpenEnd: () => {
            setIsArtworkCompleted(false);
            setModalIsOpen(true);
        },
        onCloseEnd: () => {
            setIsArtworkCompleted(true);
            setModalIsOpen(false);
            try {
                modalArtworkRef.current.stop();
            }
            catch (error) {
                console.log(error)
            }
            try {
                generatedArtworkRef.current?.stop();
            }
            catch (error) {
                console.log(error);
            }
            clearInterval(window.modalWait);
            clearInterval(window.intervalId);
        },
        preventScrolling: true,
        dismissible: false,
        inDuration: 0,
        opacity: 1,
    }


    // initialize modal
    useEffect(() => {
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, modalOptions);
    }, [])


    // generate image for current artwork at run time
    const generateImage = () => {
        if (generatedArtworkRef.current) {
            const dataURL = generatedArtworkRef.current.canvas.toDataURL();
            setTimeout(() => {
                axios.post(`${SERVER_IP}/save/artwork`, {
                    seedNumber: artSeedParam,
                    base64Image: dataURL
                })
                    .then(res => {
                        let url = res.data.data;
                        setImgURL(url)
                    })
                    .catch(err => console.log("Gnerate Image Error:", err))
            }, 200)
        }
    }


    useEffect(() => {
        window.addEventListener('resize', () => {
            (generatedArtworkRef.current) ?
                resizeCanvas(generatedArtworkRef.current.canvas) :
                resizeCanvas(null);
        });
    }, [generatedArtwork])

    return (
        <div className='artwork' id="artworkID">
            <div id="artwork-modal" className={`modal`}>
                <div className="modal-content">
                    <div className={`artwork-draw-section not-fullscreen`}
                        style={{ backgroundColor: artworkBgColor }}
                    >
                        <i
                            ref={modalCrossButtonRef}
                            className="material-icons cross-icon"
                            onClick={() => {
                                setIsArtworkCompleted(true);
                                setModalIsOpen(false);
                            }}
                        >
                            close
                        </i>
                        {
                            modalArtwork
                        }
                        <div className="artwork-credits" style={{ color: `${complementryColor ? complementryColor : "black"}` }}>
                            <p>
                                *Artwork generated from modified algorithm by
                                <a className="bold" href="https://www.mattdesl.com/" target="_blank">
                                    &nbsp;Matt DesLauries&nbsp;
                                </a>
                                and is licensed under<br />
                            </p>
                            <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode" target="_blank">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={`progress`} style={{display: `${modalIsOpen ? "block": "none"}`}}>
                <div className="indeterminate"></div>
            </div> */}
            {
                <div style={{ display: `${!modalIsOpen && isArtworkCompleted ? "block" : "none"}` }}>
                    <div
                        id="fullscreen-target"
                        ref={artworkContainerRef}
                        className={`artwork-draw-section generated-artwork-container animated fadeInDown ease-in-out d2 a-1 ${isLandscape || isFullScreen ? "landscape" : ""} ${isFullScreen ? "full-screen" : "not-fullscreen"}
                        `}
                        onClick={handleArtworkClick}
                        onDoubleClick={handleArtworkDoubleClick}
                        style={{ backgroundColor: artworkBgColor }}
                    >
                        {generatedArtwork}

                        <div className="artwork-controls">
                            <i className={`far fa-redo-alt`} onClick={redrawArtwork}></i>
                            <i className={`far fa-compress ${!isFullScreen ? "hide" : ""}`} onClick={handleFullScreen}></i>
                            <i className={`far fa-expand ${isFullScreen ? "hide" : ""}`} onClick={handleFullScreen}></i>
                        </div>

                        <div className="artwork-credits" style={{ color: `${complementryColor ? complementryColor : "black"}` }}>
                            <p>
                                *Artwork generated from modified algorithm by
                                <a className="bold" href="https://www.mattdesl.com/" target="_blank">
                                    &nbsp;Matt DesLauries&nbsp;
                                </a>
                                and is licensed under<br />
                            </p>
                            <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode" target="_blank">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International</a>
                        </div>
                    </div>

                    {
                        <div className="artwork-footer row no-margin" style={{
                            paddingTop: '1rem',
                            color: `${complementryColor ? complementryColor : "black"}`
                        }}>
                            <p className='artwork-credit white-text col m7 s12 no-padding share-art-cta'>

                                <span className="line">
                                    *Artwork generated from modified algorithm by
                                    <a className="bold white-text" href="https://www.mattdesl.com/" target="_blank">
                                        &nbsp;Matt DesLauries
                                    </a> </span>
                                <span className="line">
                                    &nbsp;and is licensed under &nbsp;
                                </span>
                                <span className="line">
                                    <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode" target="_blank">
                                        Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International
                                    </a>
                                </span>

                            </p>
                            <div className="share-video-section white-text col m5 s12 no-padding">

                                <div className="right valign-wrapper" style={{
                                    maxWidth: '30rem'
                                }}>
                                    <p style={{
                                        paddingRight: '1.5rem',
                                        fontSize: '1.1rem'
                                    }} className="share-art-cta">Share Your Animated Art</p>

                                    <div className={`progress`} style={{
                                        display: `${imgURL === ""? "block" : "none"}`
                                    }}
                                    >
                                        <div className="indeterminate"></div>
                                    </div>

                                    {
                                        !modalIsOpen && isArtworkCompleted &&
                                        <>
                                            <ScrollTrigger onEnter={() => setIsIconsVisible(true)} onExit={() => setIsIconsVisible(false)}>
                                                <div className="share-video-icons valign-wrapper" style={{
                                                    maxWidth: '12rem',
                                                    display: `${imgURL === "" ? "none" : "block"}`
                                                }}>
                                                    <i className={`fab fa-facebook ${isIconsVisible ? "animated-facebook" : ""}`} onClick={() => setShareButtonValue(SHARE_BUTTON.FACEBOOK)}></i>
                                                    <i className={`fab fa-twitter ${isIconsVisible ? "animated-twitter" : ""}`} onClick={() => setShareButtonValue(SHARE_BUTTON.TWITTER)}></i>
                                                    <i className={`fab fa-pinterest ${isIconsVisible ? "animated-pinterest" : ""}`} onClick={() => setShareButtonValue(SHARE_BUTTON.PINTEREST)}></i>
                                                    <i class={`fas fa-link ${isIconsVisible ? "animated-link" : ""}`} onClick={() => copySharingUrl()}></i>
                                                </div>
                                            </ScrollTrigger>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default Artwork;
