import { useRef, useEffect, useState } from 'react';

import Art from '../../react-color-wander';
import "./style.scss";

import "../MainPage/Artwork/Artwork.scss";

import { getBestContrast, resizeCanvas } from '../../global';
import { getSeedAndSeconds } from './global';
import { COLORS_LIST } from '../MainPage/Artwork/colors';


const MAP_DIVIDER = 43500; // to use 19 maps i.e (999999/55000) + 1 = 19 (approximately)
const PAL_DIVIDER = 8772; // to use 114 colors
// const PAL_DIVIDER = 5000; // to use all colors

const Artwork = () => {

    // read seed and seconds value from the url
    const artworkData = getSeedAndSeconds();
    let seed, seconds;


    if(artworkData === -1){
        window.location.pathname = "";
    }
    else{
        seed = artworkData.seed;
        seconds = artworkData.seconds;
    }


    // hooks
    const artworkRef = useRef(null);
    const [complementryColor, setComplementoryColor] = useState(null);
    const [bgColor, setBgColor] = useState("#21242b");
    const [shouldDisplayText, setShouldDisplayText] = useState(false);

    const seedValue = "" + seed;


    const PALETTE_IND = Math.floor(seed/ PAL_DIVIDER);
    const MAP_NUM = Math.round(seed / MAP_DIVIDER) + 1;

    // used for mapping for artwork
    const ARTWORK_MAP = `/images/maps/${MAP_NUM}.JPG`;

    useEffect(() => {
        setBgColor(COLORS_LIST[PALETTE_IND][0])

    }, [])

    useEffect(() => {
        if(bgColor){
            setComplementoryColor(getBestContrast(bgColor, COLORS_LIST[PALETTE_IND].slice(1)));
        }
    }, [bgColor])



    useEffect(() => {
        if (artworkRef.current && bgColor && complementryColor) {

            setTimeout(() => {
                artworkRef.current.draw();
                const timeLimit = seconds > 12 ? 12 : seconds < 8 ? 8 : seconds;
                stopArtworkToDraw(timeLimit * 1000, true);
            }, 1000)
        }
    }, [bgColor, complementryColor])

    
    // hide/display artwork controls
    const artworkControlsDisplay = (shouldDisplayControls = true) => {
        let artworkControls = document.querySelector(".artwork-controls");
        if (artworkControls) {
            shouldDisplayControls ?
                artworkControls.classList.remove("hide") :
                artworkControls.classList.add("hide");
        }
    }


    // to stop artwork after 10 seconds
    const stopArtworkToDraw = (ms, shouldDisplayControls = true) => {
        window.redrawTimeOutID = setTimeout(() => {
            try {
                artworkRef.current?.stop();
            } catch (error) {
                console.log(error);
            }
            clearTimeout(window.redrawTimeOutID)
            setShouldDisplayText(true);
            artworkControlsDisplay(shouldDisplayControls)
        }, ms)
    }


    // this function redraw artwork
    const redrawArtwork = () => {
        setShouldDisplayText(false);
        clearTimeout(window.redrawTimeOutID);
        artworkRef.current.stop();

        artworkRef.current.draw();
        stopArtworkToDraw(seconds * 1000, true);
    }

    // this function display/hide artwork controls
    const handleArtworkClick = () => {
        let artworkControls = document.querySelector(".artwork-controls");
        if (typeof (artworkControls) !== "undefined") {
            artworkControls.classList.toggle("hide");
        }
    }


    const [isLandscape, setIsLanscape] = useState(false);

    useEffect(() => {
        document.querySelector("html").style.overflowY = "hidden";
        document.body.style.overflowY = "hidden";

        setIsLanscape(!window.matchMedia("(orientation: landscape)").matches)
        window.addEventListener("orientationchange", function () {
            setIsLanscape(window.orientation === 90)
        }, false);

        // window.addEventListener('resize', () => {
        //     (artworkRef.current) ?
        //     resizeCanvas(artworkRef.current.canvas) :
        //     resizeCanvas(null);
        // });
    }, [])

    return (
        <div
            className={`full-screen ${isLandscape ? "landscape" : ""} artwork-draw-section`} style={{ backgroundColor: bgColor }}
            onClick={handleArtworkClick}
        >
            {
                (bgColor && complementryColor) &&
                <>
                    <div className={`cta-btn-container center-align ${!shouldDisplayText ? "hide" : ""}`}>

                        <a  
                            style={{ backgroundColor: complementryColor, color: bgColor }}
                            href="https://gpacalculator.memorymaps.io"
                            target="_blank"
                            className="btn-flat cta-btn"
                        >
                            Create Your Own Unique Art
                        </a>
                    </div>

                    <Art
                        ref={artworkRef}
                        map={ARTWORK_MAP}
                        palette={COLORS_LIST[PALETTE_IND]}
                        seed={seedValue}
                    />
                    
                    <div className="artwork-controls hide">
                        <i className={`far fa-redo-alt`} onClick={redrawArtwork}></i>
                    </div>
                    <div className="artwork-credits" style={{ color: `${complementryColor ? complementryColor : "black"}` }}>
                        <div className="no-margin">
                            *Artwork generated from modified algorithm by
                            <a className="bold" href="https://www.mattdesl.com/" target="_blank">
                                &nbsp;Matt DesLauries&nbsp;
                            </a>
                            and is licensed under
                        </div>
                        <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode" target="_blank">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International</a>
                    </div>
                </>
            }
        </div>
    )
}

export default Artwork
