// import react modules
import {useState, useRef, useEffect} from 'react';

// import custom components
import PrevCumulativeGPA from './PrevCumulativeGPA/PrevCumulativeGPA';
import CurrentCumulativeGPA from './CurrentCumulativeGPA/CurrentCumulativeGPA';
import Semester from './Semester/Semester';
import AddButton from './AddButton/AddButton';

// import modules from global.js
import {isInteger} from '../../../global';

// import custom stylesheet
import './Calculator.scss';

/*
    :::::PROPS:::::
    CGPA => a hook to store cgpa of the student
    setCGPA => to set state of the CGPA
    setCGPATotalCredits => to set total credit hours of the cgpa
    isWeighted => a hook, to store either 'Weighted' switch is ON or OFF
    serIsWeighted => to set the state of the 'Weighted' switch
    setCalculateCGPACount => to count number of times, the 'calculate cgpa' button is pressed
*/
const Calculator = ({CGPA, setCGPA, setTemporaryCGPA, CGPATotalCredits, setCGPATotalCredits, CGPATotalGrade, setCGPATotalGrade, isWeighted, setIsWeighted, modalIsOpen, setModalIsOpen, avgSemesterCredits, setAvgSemesterCredits, setRemainingCredits, setEstimatedRemainingCredits, isCollegeGPABtnActive, setIsCollegeGPABtnActive, isArtworkCompleted, setMaxRangeValue, artSeedValue, setArtSeedValue, storeCaptchaStatus, recaptchaRef, captchaCount}) => {

    // returns a new semester object 
    const getNewSemester = (semNo) => {
        return         {
            "semNo": semNo,
            "GPA" : null,
            "credits": 0,
            "seedValue": "",
        };       
    }

    const [semesterList, setSemesterList] = useState([getNewSemester(1)]);  // to store list of semesters
    const collegeGpaBtn = useRef(null);     // reference to the 'college gpa' button
    const highSchoolGpaBtn = useRef(null);  // reference to the 'high school gpa' button
    const [prevGPA, setPrevGPA] = useState(null); // to store previous gpa of a student
    const [prevTotalCredits, setPrevTotalCredits] = useState(null); // to store total credit hours of the previous gpa
    const calculateCGPABtn = useRef(null);      // refernece to the 'calculate cgpa' button
    const [shouldUpdateCGPA, setShouldUpdateCGPA] = useState(false);


    // to add a new semester on button click
    const handleAddSemesterClick = () =>{
        let newSemester = getNewSemester(semesterList.length + 1);
        setSemesterList([...semesterList, newSemester]);
    };

    // to handle 'college gpa' button click
    const handleCollegeGPAClick = (e) => {
        e.preventDefault();
        setIsWeighted(false);
        setIsCollegeGPABtnActive(true);
        e.target.classList.add("active-switch-btn");
        highSchoolGpaBtn.current.classList.remove("active-switch-btn");
        setIsWeighted(false);
    }
    
    // to handle 'high school gpa' button click
    const handleHighSchoolGPAClick = (e) => {
        e.preventDefault();
        setIsCollegeGPABtnActive(false);
        e.target.classList.add("active-switch-btn");
        collegeGpaBtn.current.classList.remove("active-switch-btn");
        setIsWeighted(true);
    }

    // to update gpa of a specific semester
    const updateSemesterHandler = (semNo, gpa, credits, seedValue) => {
        let updatedSemesterList = semesterList;
        const semesterIndex = updatedSemesterList.findIndex(s => s.semNo === semNo);

        if(semesterIndex !== -1){
            updatedSemesterList[semesterIndex] = {
                ...updatedSemesterList[semesterIndex],
                "GPA": gpa,
                "credits": credits,
                "seedValue": seedValue,
            }
            setSemesterList(updatedSemesterList);
            calculateCGPA();
        }
    }


    // to calculate CGPA i.e Cumulative GPA and total credit hours
    // returns true, if seed is updated/changed. 
    // Otherwise, returns false
    const calculateCGPA = () => {

        let totalGPA = 0.00;            // to store total gpa of all semesters
        let totalCredits = 0.0;         // to store total credit hours of all semesters 
        let totalSemesters = 0;         // count of semesters with not null gpa
        let totalSeedValue = "";
        let totalGrade= 0;

        semesterList.forEach(semester => {
            let semesterGPA = parseFloat(semester.GPA);
            let semesterCredits = parseFloat(semester.credits);
            if(semesterGPA >= 0 && semesterCredits > 0 && !isNaN(semesterGPA) && !isNaN(semesterCredits)){
                totalGPA +=  (semesterGPA * semesterCredits);
                totalCredits += semesterCredits;
                ++totalSemesters;
                totalSeedValue += semester.seedValue;
                totalGrade += semesterGPA;
            }
        })

        // set avg semester credits
        totalSemesters > 0 && setAvgSemesterCredits(totalCredits / totalSemesters);
        
        // add previous gpa and total credit hours of the previous gpa,
        // if both of them are valid values
        if(prevGPA?.trim() && prevTotalCredits?.trim()){
            totalGPA += (parseFloat(prevGPA) * parseFloat(prevTotalCredits));
            totalCredits += parseFloat(prevTotalCredits);
            totalSeedValue += prevGPA + prevTotalCredits;
            totalGrade += parseFloat(prevGPA);
        }

        totalSeedValue += isCollegeGPABtnActive ? "true" : "false";
        totalSeedValue += isWeighted ? "true" : "false";

        // update cgpa and total credit hours of the cgpa
        (totalCredits && totalCredits > 0) ? setCGPATotalGrade((totalGrade).toFixed(2)) : setCGPATotalGrade(null);
        (totalCredits &&  totalCredits > 0) ? setTemporaryCGPA((totalGPA / totalCredits).toFixed(2)) : setTemporaryCGPA(null);
        shouldUpdateCGPA && (totalCredits > 0 ? setCGPA((totalGPA / totalCredits).toFixed(2)) : setCGPA(null));
        totalCredits > 0 ? setCGPATotalCredits(totalCredits.toFixed(1)) : setCGPATotalCredits(null);

        if(shouldUpdateCGPA){
            totalSeedValue += (totalGPA / totalCredits).toFixed(2)
        }

        // to check either seed is updated or not
        shouldUpdateCGPA && (totalCredits > 0 ? setArtSeedValue(totalSeedValue) : setArtSeedValue("0"))
    }


    const calculateSeed = () => {
        let totalGPA = 0.00;            // to store total gpa of all semesters
        let totalCredits = 0.0;         // to store total credit hours of all semesters 
        let totalSemesters = 0;         // count of semesters with not null gpa
        let totalSeedValue = "";


        semesterList.forEach(semester => {
            let semesterGPA = parseFloat(semester.GPA);
            let semesterCredits = parseFloat(semester.credits);
            if(semesterGPA >= 0 && semesterCredits > 0 && !isNaN(semesterGPA) && !isNaN(semesterCredits)){
                totalGPA +=  (semesterGPA * semesterCredits);
                totalCredits += semesterCredits;
                ++totalSemesters;
                totalSeedValue += semester.seedValue;
            }
        })

        // add previous gpa and total credit hours of the previous gpa,
        // if both of them are valid values
        if(prevGPA?.trim() && prevTotalCredits?.trim()){
            totalCredits += parseFloat(prevTotalCredits);
            totalSeedValue += prevGPA + prevTotalCredits;
        }

        totalSeedValue += isCollegeGPABtnActive ? "true" : "false";
        totalSeedValue += isWeighted ? "true" : "false";

        if(shouldUpdateCGPA){
            totalSeedValue += (totalGPA / totalCredits).toFixed(2)
        }

        // to check either seed is updated or not
        let isSeedChanged = (totalSeedValue !== artSeedValue);
        (totalCredits > 0) && setArtSeedValue(totalSeedValue)

        return isSeedChanged;
    }

    // update estimate remaining credit hours
    useEffect(() => {
        const estimatedRemainingCredits = (8 * avgSemesterCredits) - (prevTotalCredits ? prevTotalCredits : 0);
        avgSemesterCredits && setEstimatedRemainingCredits(estimatedRemainingCredits > 0 ? estimatedRemainingCredits : 0);
    }, [avgSemesterCredits, prevTotalCredits]);

    // update cgpa and total credits
    useEffect(() => {
        calculateCGPA();
    },[prevGPA, prevTotalCredits]);

    // clear CGPA
    useEffect(()=>{
        setCGPA(null);
    }, [prevGPA, prevTotalCredits, isWeighted])

    // to handle 'cgpa calculate' click
    const handleCGPACalculateClick = (e) =>{
        e.preventDefault();


        // check recaptcha status and token
        if(captchaCount >= 15 && (!recaptchaRef.current || recaptchaRef.current.getValue() === "")) return;

        // if cgpa or total credit hours are null or undefined
        if(!CGPATotalCredits || isNaN(parseFloat(CGPATotalCredits))) return;
        setShouldUpdateCGPA(true);
    }

    useEffect(() => {
        if(shouldUpdateCGPA){
            if(!calculateSeed()){
                calculateCGPA();
                setShouldUpdateCGPA(false);
                return                
            }
            
            calculateCGPA();
            setModalIsOpen(true);
            setShouldUpdateCGPA(false);
            storeCaptchaStatus();
        }
    }, [shouldUpdateCGPA]);

    useEffect(() => {
        if (isArtworkCompleted) {
            calculateCGPABtn.current.innerText = "Recalculate Cumulative GPA";
        }
    }, [isArtworkCompleted]);

    return (
        <div>
            <div className="buttons-container row ">
                <div className="switch-btns-container col m9 s12 no-margin no-padding">
                    <div className="sub-btn-container">
                        <button
                            ref={collegeGpaBtn}
                            className='left-switch-btn active-switch-btn col s6 truncate'
                            onClick={handleCollegeGPAClick}>
                            College GPA
                        </button>
                        <button
                            ref={highSchoolGpaBtn}
                            onClick={handleHighSchoolGPAClick}
                            className='right-switch-btn col s6 truncate'>
                            High School GPA
                        </button>
                    </div>
                </div>
                <div className={`${isCollegeGPABtnActive && "hide"} weighted-switch-container col m3 s12 center-align row valign-wrapper right-align no-margin`}>
                    <p className="col m6 s8 right-align">Weighted</p>
                    <label className='weighted-switch col m6 s4 '>
                        <input
                            checked={!isCollegeGPABtnActive && isWeighted}
                            value={isWeighted}
                            onChange={() => setIsWeighted(!isWeighted)}
                            type='checkbox'
                        />
                        <span className="slider round"></span>
                        <div id={isWeighted? "tick-mark" : ""}></div>
                    </label>
                </div>
            </div>

            <PrevCumulativeGPA
                isCollegeGPABtnActive={isCollegeGPABtnActive}
                prevGPA={prevGPA} setPrevGPA={setPrevGPA} isWeighted={isWeighted}
                prevTotalCredits={prevTotalCredits} setPrevTotalCredits={setPrevTotalCredits}
            />

            <div className="semesters-wrapper">
                {
                    semesterList.map(semester => (
                        <Semester
                            CGPA={CGPA} setCGPA={setCGPA} key={semester.semNo}
                            isWeighted={isWeighted} semester={semester}
                            updateSemesterHandler={updateSemesterHandler}
                            isCollegeGPABtnActive={isCollegeGPABtnActive}
                        />
                    ))
                }
                <div className='bottom-wrapper row no-margin'>                    
                    <div className="col m6 s12 no-padding">
                        <AddButton text="Add New Semester" clickHandler={handleAddSemesterClick} />
                    </div>
                    <div className="total-credits-container col m6 s12">
                        <p>Calculated Total Credits:</p>
                        <p>{!CGPATotalCredits ? "-- Credits" : isInteger(parseFloat(CGPATotalCredits)) ? parseInt(CGPATotalCredits) + " Credits" : CGPATotalCredits + "Credits"} </p>
                    </div>
                </div>
            </div>

            <CurrentCumulativeGPA isArtworkCompleted={isArtworkCompleted} CGPA={CGPA} />

            <button className='calculate-cgpa-btn right transaparent btn-flat' onClick={handleCGPACalculateClick} >
                <p ref={calculateCGPABtn}>Calculate Cumulative GPA</p>
            </button>
        </div>
    )
}

export default Calculator;