import { getDecryptedSeed } from "../../global";


// this function read seed and seconds from the current page url and return an object
export const getSeedAndSeconds = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const paramName = "hf96jk28473eraraAInpainCH0DD115tuhadifUr2sk294PHD5754uewrhuhaleemhuikiadsdj";
    // read seed from url
    let value = urlParams.get(paramName);

    // default values
    if(!value || typeof(value) === "undefined"){
        return -1;
    }

    try {
        // set seed and seconds
        var seed = parseInt(value.split("/")[0]);
        seed = (typeof(seed) === "undefined" || isNaN(seed) || seed < 0) ? -1 : getDecryptedSeed(seed);

        var seconds = parseInt(value.split("/")[1]);

        seconds = (typeof(seconds) === "undefined" || isNaN(seconds) || seconds < 0) ? -1 : getDecryptedSeed(seconds)


        if(seed === -1 || seconds === -1 ){
            return -1;
        }

        return {
            seed,
            seconds
        }

    } catch (error) {
        return -1;
    }
}
