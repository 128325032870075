import { useEffect, useState } from 'react';

// import custom module from global file
import { isFloatingNumber } from '../../../global';

// import custom stylesheet
import './GPASummary.scss';


/*
    :::::[PROPS]:::::
    CGPA => cgpa of a student
    requiredGPA => gpa needed for remaining credit hours
    targetGPA => a hook, to store target gpa
    setTargetGPA => to set state of the target gpa
    remainingCredits => a hook, to store remaining credits of a student
    setRemainingCredits => to set state of the remaining credits
    isWeighted => true, if user has checked 'Weighted switch'
*/
const GPASummary = ({ CGPA, minRangeValue, maxRangeValue, requiredGPA, targetGPA, setTargetGPA, remainingCredits, setRemainingCredits, estimatedRemainingCredits, isWeighted, isCollegeGPABtnActive, isArtworkCompleted, tempTargetGPA, setTempTargetGPA, maxValidValidGPA, isMaxValidReached, setIsMaxValidReached }) => {

    const [tempRemainingCredits, setTempRemainingCredits] = useState(remainingCredits);
    const [currTargetGPA, setCurrTargetGPA] = useState(targetGPA);

    // to set/update value of the target gpa
    const handleCurrTargetGPAChange = (e) => 
    {
        const value = e.target.value;
        // to check either target gpa is a floating point number
        // with upto precision of 2 decimal places or not
        if (isFloatingNumber(value, 2)) {
            if (value !== '') {
                const currPrevGPA = +parseFloat(value).toFixed(2);

                if(currPrevGPA > parseFloat(maxValidValidGPA)){
                    return;
                }
            }
            // update target gpa only if, user inputted a valid floating point number
            setCurrTargetGPA(value);
        }
    }


    const setTargetGPACorrectly = () => {

        setTempTargetGPA(parseFloat(currTargetGPA).toFixed(2));

        if(+parseFloat(currTargetGPA).toFixed(2) < +parseFloat(minRangeValue).toFixed(2) || currTargetGPA > maxValidValidGPA) return;

        let str = currTargetGPA.toString()
        if(str[str.length - 1] === '.'){
            str = str.slice(0, -1);
            setTargetGPA(parseFloat(str));
        }
        else{
            setTargetGPA(currTargetGPA);
        }
    }

    useEffect(() => {
        if(!isMaxValidReached){
            setCurrTargetGPA(targetGPA);
            setTempTargetGPA(targetGPA);
        }
    }, [isMaxValidReached, targetGPA])


    const handleTargetGPAKeyDown = (e) => {
        if(e.keyCode === 13){
            setTargetGPACorrectly();
        }
    }


    // update remaining credits
    useEffect(() => {
        setRemainingCredits(estimatedRemainingCredits)
    }, [estimatedRemainingCredits])

    const handleErrorDismiss = ()=> {
        let gpaSummary = document.querySelector('.gpa-summary-calculations');
        gpaSummary.classList.remove('gpa-summary-calculations-invalid');
    }

    useEffect(() => {
        remainingCredits && setTempRemainingCredits(remainingCredits);
    }, [remainingCredits])

    const updateRemainingCredits = (e) => {
        isFloatingNumber(e.target.value, 1) && setRemainingCredits(e.target.value)
    }

    const handleRemainingCreditsKeyDown = (e) => {
        if(e.keyCode === 13){
            updateRemainingCredits(e);
        }
    }


    return (
        <div className='gpa-summary-container'>
            

            <div className='gpa-summary-calculations no-margin'>

                <div className="summary-col center-align">
                    
                    <div className="container no-padding" style={{
                        width: '8rem'
                    }}>
                        
                        <input type='text' value={(isArtworkCompleted && CGPA) ? CGPA : ""} placeholder="--" readOnly id="calc-cum-gpa" className="no-margin no-padding without-ind" style={{
                            background: 'transparent',
                            border: 'none',
                            fontFamily: 'Poppins', 
                            fontSize: '2rem',
                            color: 'white',
                            fontWeight: 500
                        }}/>

                        <p className="white-text">Calculated<br/>Cumulative GPA</p>
                    
                    </div>

                </div>

                <div className="summary-col">
                    
                    
                    <div className="container no-padding" style={{
                        width: 'fit-content'
                    }}>

                        <input type='text'
                            onChange={handleCurrTargetGPAChange}
                            onKeyDown={handleTargetGPAKeyDown}
                            onBlur={setTargetGPACorrectly}
                            value={currTargetGPA ? currTargetGPA : ""}
                            placeholder="--" className="target-gpa" style={{
                                border: 'none',
                                fontWeight: '400',
                                color: '#bdc3c7',
                                fontSize: '2rem',
                                fontStyle: 'italic',
                                background: '#2F2C62'
                            }}
                        />

                        <p className="white-text no-margin no-padding">Target GPA</p>

                    </div>

                </div>

                <div className="summary-col">
                            
                    <div className="container no-padding" style={{
                        width: 'fit-content'
                    }}>
                    
                        <input
                            type='text' value={tempRemainingCredits ? tempRemainingCredits : ""}
                            onChange = {(e) => {isFloatingNumber(e.target.value, 1) && setTempRemainingCredits(e.target.value)}}
                            onKeyDown = {handleRemainingCreditsKeyDown}
                            onBlur = {updateRemainingCredits}
                            placeholder={"--"} className='remainingCredits' style={{
                                border: 'none',
                                fontWeight: '400',
                                color: '#bdc3c7',
                                background: '#2F2C62',
                                fontSize: '2rem',
                                fontStyle: 'italic'
                            }}
                        />

                        <p className="white-text no-margin no-padding">Estimated <br/> Remaining Credits</p>
                    
                    </div>

                </div>

                <div className="summary-col gradient-container">
                
                    <div className="container no-padding" style={{
                        width: '12rem'
                    }}>

                        <div className="gradient-border" style={{
                            backgroundImage: "url('images/summary-grad.svg')"
                        }}>
                        
                            <input className='required-gpa' placeholder="N/A" type='text' value={requiredGPA ? requiredGPA : ""} readOnly style={{
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                fontSize: '2rem',
                                fontStyle: 'normal',
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                color: 'white'
                            }}/>

                            <p className="white-text">GPA Needed for Remaining Credits</p>
                        
                        </div>

                    </div>

                </div>

                <i className="material-icons gpa-summary-close-btn" onClick={handleErrorDismiss}>close</i>

            </div>
        </div>
    );
}

export default GPASummary;