// import react modules
import {useState, useEffect} from 'react';

// import custom components
import CourseRow from './CourseRow/CourseRow';
import AddButton from '../AddButton/AddButton';

// import custom stylesheet
import './Semester.scss';


/*
    :::::PROPS:::::
    semester => an object to store details of a semester
    isWeighted => true, if user has checked the 'Weighted' switch
    updateSemesterHandler => to update details of a specific semester
*/
const Semester = ({CGPA, setCGPA, semester, isWeighted, updateSemesterHandler, isCollegeGPABtnActive}) => {

    // returns a new course with default values
    const getNewEmptyCourse = (id = 1) => {
        return ({
            _id: id,
            courseName: "",
            courseGrade: -1,
            courseCredits: 0,
            courseWeight: "-1",
        })
    }

    // to set initial course rows, initially there are 4 rows in the courseList
    const getInitialCourseRows = () => {
        const INITIAL_COURSE_ROWS = 4;
        let rows = [];
        for(let i = 0; i < INITIAL_COURSE_ROWS; ++i){
            rows.push(getNewEmptyCourse(i + 1));
        }
        return rows;
    }

    const [courseList, setCourseList] = useState(getInitialCourseRows()); // to store list of current courses
    const [semesterGPA, setSemesterGPA] = useState(null); // to store gpa of a semester
    const [semesterCredits, setSemesterCredits] = useState(null); // to store total credits of a semester
    const [semesterSeedValue, setSemesterSeedValue] = useState("");


    // to set/update gpa and total credits of a specific semester
    const updateSemesterCreditGPA = () => {

        let totalCredits = 0;       // total of credit hours in a specific semester
        let totalGrade = 0.00;      // total of grades of a specific semester
        let seedValue = "";

        let coursesArr = [];
        courseList.forEach((course) => {
            const credits = parseFloat(course.courseCredits);     // credit hours of a specific course
            const grade = parseFloat(course.courseGrade);       // grade of a specific course

            let weight = 0.0;           // in case of unweighted gpa
            if(isWeighted){
                // update weight in case of weighted gpa
                if (course.courseWeight.toString().trim() === "Honors") weight = 0.5;
                if (course.courseWeight.toString().trim() === "Enriched") weight = 0.7;
                else if (["AP/IB", "College"].includes(course.courseWeight.toString().trim())) weight = 1.0;
                else if (course.courseWeight.toString().trim() === "-1"
                || course.courseWeight.toString().trim() == "Weight") weight = -1;
            }

            seedValue += `${course.courseName}`;

            if (!isNaN(credits) && credits > 0 && grade !== -1 && weight !== -1) {
                seedValue +=`${credits}`;
                seedValue += `${grade}`;
                seedValue +=`${weight}`;            

                totalCredits += credits;
                totalGrade += (credits * ((!isCollegeGPABtnActive && grade === 4.3 ? 4.0 : grade) + weight));
            }
        });

        // set semester gpa and total credits of a specific semester
        setSemesterSeedValue(seedValue);
        totalCredits ? setSemesterGPA((totalGrade / totalCredits).toFixed(2)) : setSemesterGPA(null);
        totalCredits ? setSemesterCredits(totalCredits) : setSemesterCredits(null);
    };

    // to update detail of a specific semester, if there is a change in any of the followings:
    // semesterGPA, semesterCredits, or semNo
    useEffect(() => {
        updateSemesterHandler(semester.semNo, semesterGPA, semesterCredits, semesterSeedValue);
    }, [semesterGPA, semesterCredits, semesterSeedValue, semester.semNo, isWeighted, isCollegeGPABtnActive]);

    // update semester gpa and total credit hours of a semester,
    // if a user switches between weighted/unweighted modes
    useEffect(() => {
        updateSemesterCreditGPA();
    }, [isWeighted]);

    useEffect(() => {
        updateSemesterCreditGPA();
    }, [isCollegeGPABtnActive]);

    // to add new course row on button click
    const handleAddCourseClick = () =>{
        let newCourse = getNewEmptyCourse(courseList.length + 1);
        setCourseList([...courseList, newCourse]);
    };


    // to update value of a particular course field
    const updateCourseHandler = (id, name, value) => {
        let updatedCourseList = courseList;
        const courseIndex = updatedCourseList.findIndex(c => c._id === id);

        if(courseIndex !== -1){
            updatedCourseList[courseIndex] = {
                ...updatedCourseList[courseIndex],
                [name]: value
            }
            setCourseList(updatedCourseList);
            updateSemesterCreditGPA();

            const course = updatedCourseList[courseIndex];

            if(name === "courseCredits"){
                if((!isWeighted || parseInt(course.courseWeight) !== "-1") && parseInt(course.courseGrade) !== -1){
                    setCGPA(null);
                }
            }else if(name === "courseGrade"){
                if((!isWeighted || parseInt(course.courseWeight) !== "-1") && !isNaN(parseFloat(course.courseCredits)) 
                && parseFloat(course.courseCredits) > 0){
                    setCGPA(null);
                }                
            }
            if(isWeighted && name === "courseWeight"){
                if((parseInt(course.courseGrade) !== -1) && !isNaN(parseFloat(course.courseCredits)) 
                && parseFloat(course.courseCredits) > 0){
                    setCGPA(null);
                }
            }
        }
    };


    return (
        <div className='semester-container'>
            <div className="semester-header row">

                <p className='title col m5 s12 no-padding'>Semester {semester.semNo}</p>

                <div className="semester-total-wrapper col m7 s12 no-margin">
                    <div className='semester-total-container'>
                        <p>Semester Totals: </p>
                        <p className="semGPA">{semesterGPA ? semesterGPA : "--"} GPA</p>
                        <p className="semCred ">{semesterCredits ? semesterCredits : "--"} Credits</p>
                    </div>
                </div>

            </div>

            <div className="course-rows-container">
                {
                    courseList.map(course => (
                        <CourseRow
                            key={course._id ? course._id : courseList.length + 1}
                            course={course} isWeighted={isWeighted}
                            updateCourseHandler={updateCourseHandler}
                            isCollegeGPABtnActive={isCollegeGPABtnActive}
                            CGPA={CGPA}
                        />
                    ))
                }
                <div style={{
                    paddingBottom: 20
                }}>
                    <AddButton clickHandler={handleAddCourseClick} text="Add Course" />
                </div>
            </div>

            <div className="divider"></div>
        </div>
    );
}

export default Semester;