// import react
import React from 'react'

// import custom stylesheet
import './CurrentCumulativeGPA.scss';

/*
    :::::PROPS:::::
    CGPA => a hook, to store cgpa of a student
*/
const CurrentCumulativeGPA = ({isArtworkCompleted, CGPA}) => {
    return (
        <div className='current-cumulative-gpa-container row'>

            <div className="description-wrapper white-text col l7 m5 s12">
                <p className="description-wrapper-title">You are <strong><em>more</em></strong> than just a number.</p>
                <p className='cgpa-description white-text'>

                <span className="line">Algorithmic artwork is generated for you&nbsp;</span>
                <span className="line">using your&nbsp;</span>
                <span className="line">unique combination of inputs&nbsp;</span>
                <span className="line">when you calculate&nbsp;</span>
                <span className="line">your cumulative GPA.</span>
                
                </p>
            </div>

            <div className="cgpa-container col l5 m7 s12 row valign-wrapper">
                <p className="left-align white-text col s7 no-margin">Cumulative <br /> GPA</p>
                <h1 className="col s5 right-align no-margin">{(isArtworkCompleted && CGPA) ? CGPA : "--"}</h1>
            </div>

        </div>
    );
}

export default CurrentCumulativeGPA