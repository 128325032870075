/*
    This file containes common functions that can be shared among multiple components/files
*/

import { scroller } from 'react-scroll';
import { hex } from 'wcag-contrast';




// return true, if value is empty or a floating number string
// with a precision of specific decimal places
const isFloatingNumber = (value, precision) => {
    // regular expression to check either value is a
    // floating point number upto specific precision or not
    const regex = new RegExp(`^([0-9]*([.][0-9]{0,${precision}})?|[.][0-9]{0,${precision}})$`);
    return (value === '' || regex.test(value));
}


// returns list of unique items
const getUniqueItems = (arr, key) => {
    var tempArr = [];
    arr.forEach(obj1 => {
        if (tempArr.some(obj2 => obj1[key] === obj2[key]) === false) {
            tempArr.push(obj1);
        }
    })
    return tempArr;
}


// return true, if number is an integer
const isInteger = (number) => {
    return number !== null && typeof (number) !== "undefined" && (number % 1) === 0;
}

// return true, if current devise is a mobile
function isMobileDevice() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
}

const isIOS = () => /(iPad|iPhone|iPod)/i.test(navigator.userAgent);

// to wait for sometime
function delay(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
        end = new Date().getTime();
    }
}


// this function returns mean of given array
const mean = (array) => array.reduce((a, b) => a + b) / array.length;
// sort array ascending
const asc = arr => arr.sort((a, b) => a - b);
const sum = arr => arr.reduce((a, b) => a + b, 0);

// sample standard deviation
const std = (arr) => {
    const mu = mean(arr);
    const diffArr = arr.map(a => (a - mu) ** 2);
    return Math.sqrt(sum(diffArr) / (arr.length - 1));
};

const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};


// this function returns decile array
const getDecile = (arr) => {
    var decileArr = [];
    for (var i = 1; i < 10; ++i) {
        decileArr.push(quantile(arr, +parseFloat(i / 10).toFixed(2) ))
    }
    return decileArr
}


// const addVariation = (x) => {
//     return Math.sin(5 * x) * Math.cos(5 * x) * (x * x) * 999 + 1675;
// }



// // this function generate seed from course/semester inputs for the artwork
// // 0 - 999999
// const generateSeed = (str) => {

//     let cumValue = 0;

//     for (var i = 0; i < str.length; i++) {
//         cumValue += str.charCodeAt(i) * addVariation(str.charCodeAt(i)) + (i * 99 + (i * i * 373));
//     }

//     return Math.abs(parseInt((parseFloat((cumValue % 1000000)))));
// }

// scroll to a specific container
const scrollToSection = (className, pos="start") => {
    if(isMobileDevice()) {
        document.querySelector("." + className).scrollIntoView(
            {
                block: pos,
            }
        );
    }

    else{
        scroller.scrollTo(className, {
            duration: 0,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -20,
        });
    }
};


// this function generates complementry color corresponding to the given pallete
function getBestContrast(background, colors) {
    var bestContrastIdx = 0;
    var bestContrast = 0;
    colors.forEach((p, i) => {
        var ratio = hex(background, p);
        if (ratio > bestContrast) {
            bestContrast = ratio;
            bestContrastIdx = i;
        }
    });
    return colors[bestContrastIdx];
}




// to resize artwork canvas
function resizeCanvas(canvas) {
    if(!canvas) return;
    letterbox(canvas, [window.innerWidth, window.innerHeight]);
}

// resize and reposition canvas to form a letterbox view
function letterbox(element, parent) {
    var aspect = element.width / element.height;
    var pwidth = parent[0];
    var pheight = parent[1];

    var width = pwidth;
    var height = Math.round(width / aspect);
    var y = Math.floor(pheight - height) / 2;

    if (isIOS) { // Stupid iOS bug with full screen nav bars
        width += 1;
        height += 1;
    }

    element.style.top = y + 'px';
    element.style.width = width + 'px';
    element.style.height = height + 'px';
}







let ENCRYPTED_SEEDS_ARRAY = []
const TOTAL_SEEDS = 1000000;


// Function to generate random numbers
function linearCongruentialMethod(Xo, m, a, c) {


    let arr = new Array(TOTAL_SEEDS).fill(0);
    arr[0] = Xo; 

    // Initialize the seed state
    ENCRYPTED_SEEDS_ARRAY.push({
        "seed": 0,
        "value": Xo,
    });

    // Traverse to generate required
    // numbers of random numbers
    for (let i = 1; i < TOTAL_SEEDS; i++) {

        const value = ((arr[i - 1] * a) + c) % m;
        arr[i] = value;

        ENCRYPTED_SEEDS_ARRAY.push({
            "seed": i,
            "value": value,
        });

    }
}


const setEncryptSeeds = () => {

    let Xo = 84393220;

    let a = 7;

    let c = 13;

    let m = 256203221;        // m = prime

    // Function Call
    linearCongruentialMethod(Xo, m, a, c);
}


// get encrypted value for a seed
const getEncryptedSeed = (seed) => {
    setEncryptSeeds();

    const res = ENCRYPTED_SEEDS_ARRAY.find(data => data.seed === seed);
    if(typeof(res) !== "undefined"){
        return res.value;        
    }
    return -1;
}

// get decrypted value i.e get seed from ecrypted value
const getDecryptedSeed = (decryptedValue) => {
    setEncryptSeeds();
    const res = ENCRYPTED_SEEDS_ARRAY.find(data => data.value === decryptedValue);
    
    if(typeof(res) !== "undefined"){
        return res.seed;        
    }
    return -1;
}



// Main seeding function
const generateSeed = (flattenString, credits, CGPA) => {

    let cummulativeSeed = 0;

    for (let i = 0; i < flattenString.length; i++) {
        cummulativeSeed += (flattenString.charCodeAt(i) * flattenString.charCodeAt(i) * 100);
    }

    credits %= 10;

    let cgpaStr = String((CGPA * 1000));
    
    let sum = 0;
    [...cgpaStr].forEach(s => {
        sum += s.charCodeAt(0);
    })

    sum = sum.toString();
    sum = sum[sum.length - 1];

    let seed_str = (String(credits) + String(sum) + String(cummulativeSeed)).substring(0, 6);
    let seed_int = Math.abs(parseInt(seed_str));

    return seed_int;

}



export {
    isFloatingNumber, getUniqueItems, isInteger, isMobileDevice,
    delay, getDecile, generateSeed, resizeCanvas,
    scrollToSection, getBestContrast, setEncryptSeeds, getEncryptedSeed, getDecryptedSeed
}

