// import react and chartjs components
import React, { useEffect, useRef, useState } from 'react';
import { Chart, Scatter } from "react-chartjs-2";


// import custome components
import ChartSlider from './ChartSlider/ChartSlider';
import { isMobileDevice, getDecile } from '../../../global';
import { HighSchool_Data, MedicalSchool_Data, LawSchool_Data, PhDProgram_Data } from './ChartData';      // import chart data

// import custom stylesheet
import './ScatterPlot.scss';
import '../Calculator/Semester/CourseRow/CourseRow.scss'

import M from 'materialize-css';



// set default font in charts
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";

/*
    :::::PROPS:::::
    title => title of the chart
    CGPA => a hook, to store cumulative gpa(cgpa) of a student
*/
const ScatterPlot = ({ isLandscape, title, CGPA, dropDownList, isForCollege, maxRangeValue, setMaxRangeValue, isWeighted, rangeValue, setRangeValue, shouldRemoveChartTitle, minRangeValue, setMinRangeValue, tempTargetGPA, setTempTargetGPA, setIsMaxValidReached }) => {
    const [chartData, setChartData] = useState([]);         // contains actual data of the chart i.e all (x, y) points
    const [data, setData] = useState(null);                 // data prop for Chart Tag
    const [selectedValue, setSelectedValue] = useState("Medical School"); // selected value of drop down list
    const [currentDataList, setCurrentDataList] = useState([]); // current data list i.e high school, law school data etc 
    const [decileArr, setDecileArr] = useState([]);
    const [chartDataMax, setChartDataMax] = useState(100);
    const [chartDataMin, setChartDataMin] = useState(0);
    const chartRef = useRef(null);              // reference to the chart
    const rangeSliderRef = useRef(null);        // reference to the range slider
    const [scatterPlot, setScatterPlot] = useState();
    const [count, setCount] = useState(0);      // to update key of chart
    const [shouldUpdateChart, setShouldUpdateChart] = useState(true);



    // tooltip data and position
    const [positionAndData, setPositionAndData] = useState({
        top: 0, left: 0, school: null,
    });
    const [tooltipTableData, setTooltipTableData] = useState(null);     // data of tooltip to be displayed in table format
    const [showTooltip, setShowToolTip] = useState(false);              // flag for displaying/hiding tooltip


    // copy elements of data list 
    const getChartData = (dataList, xKey, yKey) => {

        /** To display unique chart points **/
        /*
            let tempChartData = [];         // to store chart data
            // populate chart data
            dataList.forEach(school => {
                const searchedSchoolIndex = tempChartData.findIndex(
                    s => Math.round(s["x"] * 100) / 100 === Math.round(school[xKey] * 100) / 100
                );

                if (searchedSchoolIndex === -1) {
                    tempChartData.push({
                        "x": Math.round(school[xKey] * 100) / 100,
                        "y": school[yKey],
                        "acceptanceRate": +parseFloat(school.AcceptanceRate).toFixed(2)
                    });
                }
                else if (tempChartData[searchedSchoolIndex]["y"] < school[yKey]) {
                    tempChartData[searchedSchoolIndex]["y"] = school[yKey];
                    tempChartData[searchedSchoolIndex]["acceptanceRate"] = +parseFloat(school.AcceptanceRate).toFixed(2);
                }
            });


            let tempAcceptanceRates = [];
            let tempDataPoints = [];

            tempChartData.forEach((data) => {
                tempDataPoints.push({
                    "x": data.x,
                    "y": data.y,
                })

                tempAcceptanceRates.push(data.acceptanceRate)
            })

            setDecileArr(getDecile(tempAcceptanceRates));
            setChartDataMax(parseFloat(Math.max.apply(Math, tempAcceptanceRates)).toFixed(2));
            setChartDataMin(parseFloat(Math.min.apply(Math, tempAcceptanceRates)).toFixed(2));

            return tempDataPoints;
        */


        /** To display all chart points including duplicates as well **/
        let tempChartData = [];         // to store chart data        
        let tempAcceptanceRates = [];

        // populate chart data
        dataList.forEach(school => {
                tempChartData.push({
                    "x": Math.round(school[xKey] * 100) / 100,
                    "y": school[yKey]
                });
                tempAcceptanceRates.push(+parseFloat(school.AcceptanceRate).toFixed(2))
        });

        setDecileArr(getDecile(tempAcceptanceRates));
        setChartDataMax(parseFloat(Math.max.apply(Math, tempAcceptanceRates)).toFixed(2));
        setChartDataMin(parseFloat(Math.min.apply(Math, tempAcceptanceRates)).toFixed(2));

        return tempChartData;
    }

    // add remaining (x, y) points
    const fillRemainingChartData = (dataList, tempChartData) => {
        const MIN_RANGE = Math.round(minRangeValue * 100);
        const MAX_RANGE = Math.round(maxRangeValue * 100);
        // entries for the remaining GPA's
        for (var i = MIN_RANGE; i <= MAX_RANGE; ++i) {
            if (dataList.some(school => Math.round(school.AverageGPA * 100) === Math.round(i)) === false) {
                tempChartData.push({
                    "x": parseFloat(parseFloat(i / 100).toFixed(2)),
                    "y": 0.00,
                });
            }
        }
    }

    useEffect(() => {
        const getCurrentDataList = () => {

            if (isForCollege) {
                return HighSchool_Data;
            }
            if (selectedValue === "Medical School") {
                return MedicalSchool_Data;
            }
            if (selectedValue === "PhD Programs") {
                return PhDProgram_Data;
            }
            if (selectedValue === "Law School") {
                return LawSchool_Data;
            }
        }
        setCurrentDataList(getCurrentDataList());
    }, [selectedValue])


    useEffect(() => {
        let xKey = "AverageGPA", yKey = "";
        if (isForCollege) {
            yKey = "AverageSAT";
        }
        else {
            if (selectedValue === "Medical School") {
                yKey = "AverageMCAT";
            } else if (selectedValue === "PhD Programs") {
                yKey = "AverageGRE";
            }
            else if (selectedValue === "Law School") {
                yKey = "AverageLSAT";
            }
        }

        let tempChartData = getChartData(currentDataList, xKey, yKey);
        fillRemainingChartData(currentDataList, tempChartData);         // add other points that are not included in chart data list

        //tempChartData = getUniqueItems(tempChartData, "x");        // remove objects with duplicate averageGPAs
        tempChartData.sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0)); // sort chart data array

        setChartData(tempChartData);
    }, [currentDataList]);

    // list of colors for points based on their acceptance rate
    const COLORS_LIST = [
        "rgba(255,0,128,1)",
        "rgba(240,33,160,1)",
        "rgba(214,55,193,1)",
        "rgba(169,65,169,1)",
        "rgba(199,89,189,1)",
        "rgba(171,141,202,1)",
        "rgba(82,140,177,1)",
        "rgba(82,140,177,1)",
        "rgba(9,101,162,1)",
        "rgba(5,79,145,1)",
    ];


    // return list of tooltip data based on selected value
    const getToolTipTableData = (school) => {
        let tempTooltipData = [];
        if (!isForCollege) {
            if (selectedValue !== "Medical School") {
                tempTooltipData.push({
                    "title": "GPA",
                    "25th_Per": school["UndergraduateGPA_25th_Percentile"],
                    "50th_Per": school["UndergraduateGPA_50th_Percentile"],
                    "75th_Per": school["UndergraduateGPA_75th_Percentile"],
                });
            }
            if (selectedValue === "PhD Programs") {
                tempTooltipData.push({
                    "title": "GRE Verbal",
                    "25th_Per": school["GREVerbal_25th_Percentile"],
                    "50th_Per": school["GREVerbal_50th_Percentile"],
                    "75th_Per": school["GREVerbal_75th_Percentile"],
                })
                tempTooltipData.push({
                    "title": "GRE Quant",
                    "25th_Per": school["GREQuant_25th_Percentile"],
                    "50th_Per": school["GREQuant_50th_Percentile"],
                    "75th_Per": school["GREQuant_75th_Percentile"],
                })
            }
            else if (selectedValue === "Law School") {
                tempTooltipData.push({
                    "title": "LSAT",
                    "25th_Per": school["LSAT_25th_Percentile"],
                    "50th_Per": school["LSAT_50th_Percentile"],
                    "75th_Per": school["LSAT_75th_Percentile"],
                });
            }
        }

        setTooltipTableData(tempTooltipData);
    }


    const customToolTip = (tooltipModel) => {
        // if chart is not defined, return early
        let chart = chartRef.current;
        if (!chart) {
            return;
        }

        // hide the tooltip when chartjs determines you've hovered out
        if (tooltipModel.opacity === 0) {
            setShowToolTip(false);
            return;
        }

        const left = tooltipModel.caretX - 100;
        const top = tooltipModel.caretY;

        const avgGPA = tooltipModel.dataPoints[0]?.xLabel;
        const marks = tooltipModel.dataPoints[0]?.yLabel;

        const matchSchools = currentDataList.filter(school => parseFloat(school.AverageGPA).toFixed(2) === parseFloat(avgGPA).toFixed(2) && 
        +parseFloat(school[getYAxisLabel().replace(/\s/g, '')]).toFixed(2) === +parseFloat(marks).toFixed(2));

        if (!matchSchools || typeof (matchSchools) === "undefined" || matchSchools.length <= 0) {
            setShowToolTip(false);
            return;
        }

        setPositionAndData({ top, left, school: matchSchools[0] });
        getToolTipTableData(matchSchools[0]);
        setShowToolTip(true)
    }

    // this function returns decile index corresponding to given value
    const getDecileIndex = (value) => {
        for (var i = decileArr.length - 1; i >= 0; --i) {
            if (decileArr[i] <= value) return i + 1;
        }
        return 0;
    }

    useEffect(() => {
        // data props for the ChartJS scatter plot chart
        chartData && setData({
            datasets: [
                {
                    label: "Acceptance Rate",                       // label for values on y-axis
                    data: chartData,                                // values on y-axis
                    fill: false,                                    // to remove color below the chart points
                    pointBorderColor: "rgba(75,192,192,0)",         // set border color of the points on the chart
                    pointRadius: isMobileDevice() ? 4 : 3,                                 // set radius of the points on the chart
                    pointBackgroundColor: function (context) {
                        var index = context.dataIndex;
                        var value = context.dataset.data[index];

                        const school = currentDataList?.find(school => school.AverageGPA === value.x && +parseFloat(school[getYAxisLabel().replace(/\s/g, '')]).toFixed(2) === +parseFloat(value.y).toFixed(2));

                        return (typeof (school) === "undefined" || school === null || school.AverageGPA === 0) ? "rgba(0, 0, 0, 0)" :
                            COLORS_LIST[getDecileIndex(school.AcceptanceRate)];
                    }
                }
            ]
        })
    }, [chartData, decileArr]);

    // to get max and min value on y-axis
    const getYAxisMinMax = () => {
        if (isForCollege) {
            return {
                "min": 1000,
                "max": 1600,
            };
        }
        if (selectedValue === "Medical School") {
            return {
                "min": 495,
                "max": 525,
            };
        }
        if (selectedValue === "PhD Programs") {
            return {
                "min": 310,
                "max": 335,
            };
        }
        if (selectedValue === "Law School") {
            return {
                "min": 130,
                "max": 180,
            };
        }
    }

    // to update max and min values at x-axis of the chart
    const updateXAxisMinMax = () => {
        if (!CGPA) return;

        const cgpaValue = parseFloat(CGPA).toFixed(2);

        const max = (!isForCollege && cgpaValue > 4.00)
        ? 4.35 : (!isForCollege && cgpaValue <= 4.00)
        ? 4.00 : ((!isWeighted) || (cgpaValue <= 4.35))
        ? 4.35 :  cgpaValue;

        let min = 0.0;
        if (cgpaValue < 2.90) min = cgpaValue - 0.05 < 0 ? 0 : cgpaValue - 0.05;
        else {
            if (isForCollege) {
                min = 3.00;
            }
            else if (selectedValue === "Medical School") {
                min = cgpaValue > 3.50 ? 3.50 : cgpaValue - 0.05;
            }
            else if (selectedValue === "PhD Programs") {
                min = 3.30;
            }
            else if (selectedValue === "Law School") {
                min = 2.90;
            }
        }
        setMinRangeValue(parseFloat(min).toFixed(2));
        setMaxRangeValue(parseFloat(max).toFixed(2));
    }
    useEffect(() => {
        updateXAxisMinMax();
    }, [CGPA, selectedValue]);


    // to get title of the chart based on selected value
    const getChartTitle = () => {
        let title = "";
        if (isForCollege) {
            title = "Average GPA for Accepted Undergraduate Students";
        }
        else if (selectedValue === "Medical School") {
            title = "College GPA Averages for Accepted Medical School Students";
        }
        else if (selectedValue === "PhD Programs") {
            title = "College GPA Averages for Accepted PhD Students";
        }
        else if (selectedValue === "Law School") {
            title = "College GPA Averages for Accepted Law School Students";
        }
        return title;
    }

    // return y axis label based on selected value
    const getYAxisLabel = () => {
        let yLabel = "";
        if (isForCollege) {
            yLabel = "Average SAT";
        }
        else if (selectedValue === "Medical School") {
            yLabel = "Average MCAT";
        }
        else if (selectedValue === "PhD Programs") {
            yLabel = "Average GRE";
        }
        else if (selectedValue === "Law School") {
            yLabel = "Average LSAT";
        }
        return yLabel;
    }

    // configurations i.e custom settings for the chart
    const myOptions = {
        responsive: true,               // set responsiveness to true
        maintainAspectRatio: true,
        title: {
            display: !isMobileDevice(),
            text: getChartTitle(),
            fontSize: 16,
            fontColor: `${shouldRemoveChartTitle ? "rgba(0, 0, 0, 0)" : "white"}`,
        },
        legend: {
            display: false,              // to hide legends
        },
        layout: {
            padding: {
                top: shouldRemoveChartTitle ? 10 : 2,                 // padding from the top
                left: 5,                // padding from the left
                right: 5,              // padding from the right
                bottom: 2               // padding from the bottom
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    display: true,                  // to hide x-axis values i.e labels in case of mobile devices
                    min: minRangeValue,             // minimum will be 0, unless there is a lower value.
                    max: maxRangeValue,
                    callback: function (value, index, values) {
                        return parseFloat(value).toFixed(2);
                    },
                    fontSize: isMobileDevice() ? 8 : 12,
                },
                scaleLabel: {
                    display: !isMobileDevice() || isLandscape,
                    labelString: !isForCollege && selectedValue === "Medical School" ? "Median GPA" : 'Average GPA',
                    fontColor: "gray",
                },
                gridLines: {
                    display: false,     // to hide vertical grid lines
                    drawOnChartArea: false,
                }
            }],
            yAxes: [{
                ticks: {
                    padding: 20,        // padding from the y-axis
                    min: getYAxisMinMax().min,
                    max: getYAxisMinMax().max,
                    fontSize: isMobileDevice() ? 8 : 12,
                },
                scaleLabel: {
                    display: !isMobileDevice() || isLandscape,
                    labelString: !isForCollege && selectedValue === "Medical School" ? "Median MCAT" : getYAxisLabel(),
                    fontColor: "gray",
                },
                gridLines: {
                    display: false,
                    drawBorder: false,  // to hide y-axis line
                },
            }],
        },
        elements: {
            line: {
                tension: 0,             // to make a smooth curve
            }
        },
        tooltips: {    
            // setting for tooltips
            interaction: "nearest",
            enabled: false,
            custom: customToolTip,
            displayColors: false,
            filter: function (tooltipItem, data) {
                const value = parseFloat(tooltipItem.value);
                return (value !== 0.00)
            },
        },
        events: ['mousemove'],
        animation: false,
        devicePixelRatio: 2,
    };

    // plugin for displaying vertical line for cgpa on the chart
    const CGPAVerticalLinePlugin = {
        afterDraw: function (chart, easing) {
            if (CGPA !== null || CGPA !== "undefined") {
                const cgpaValue = parseFloat(CGPA).toFixed(2);
                if (!isNaN(cgpaValue)) {
                    const ctx = chart.ctx;
                    const x = chart.scales['x-axis-1'].getPixelForValue(cgpaValue);

                    const topY = chart.scales['y-axis-1'].top;
                    const bottomY = chart.scales['y-axis-1'].bottom;

                    // draw a dotted vertical line on the canvas
                    drawVerticalLine(ctx, x, topY, bottomY);
                }
            }
        }
    }


    // plugin for displaying vertical line for current range value on the chart
    const rangeVerticalLinePlugin = {
        afterDraw: function (chart, easing) {
            if (rangeValue !== null || rangeValue !== "undefined") {
                const range = parseFloat(rangeValue).toFixed(2);
                if (!isNaN(range)) {
                    const ctx = chart.ctx;
                    const x = chart.scales['x-axis-1'].getPixelForValue(range);

                    const topY = chart.scales['y-axis-1'].top;
                    const bottomY = chart.scales['y-axis-1'].bottom;

                    // draw a dotted vertical line on the canvas
                    drawVerticalLine(ctx, x, topY, bottomY);
                }
            }
        }
    }

    // this plugin does nothing 
    const doNothingPlugin = {
        afterDraw: function (chart, easing) {
        }
    }



    // to draw a vertical line on a canvas
    const drawVerticalLine = (ctx, x, topY, bottomY, color = "#6339FF") => {
        ctx.setLineDash([10, 10]);      // to make line dotted with gaps of 10
        ctx.beginPath();
        ctx.moveTo(x, topY);            // line top point
        ctx.lineTo(x, bottomY);         // line bottom point
        ctx.lineWidth = 3;              // width of the line
        ctx.strokeStyle = color;        // color of the line
        ctx.stroke();
    }
    // // to display a vertical line on the points of the chart line
    const chartHoverLinePlugin = {        // register a custom plugin to Chart to display a vertical line
        afterDraw: function (chart, easing) {
            if (chart.tooltip._active && chart.tooltip._active.length) {

                const activePoint = chart.controller.tooltip._active[0];
                const ctx = chart.ctx;

                const x = activePoint.tooltipPosition().x;
                const topY = chart.scales['y-axis-1'].top;
                const bottomY = chart.scales['y-axis-1'].bottom;

                // draw a dotted vertical line on the canvas
                drawVerticalLine(ctx, x, topY, bottomY);
            }
        }
    }


    // to hover on a specific point of chart programatically
    const hoverOnChart = (gpaValue) => {
        let myChart = chartRef?.current?.chartInstance;       // get reference to chart instance
        if (myChart) {
            const index = chartData.findIndex(point => point.x === parseFloat(gpaValue));
            let meta = myChart.getDatasetMeta(0),
                rect = myChart.canvas.getBoundingClientRect(),
                point = meta.data[index]?.getCenterPoint();

            if (point) {
                let evt = new MouseEvent('mousemove', {
                    clientX: rect.left + point.x,
                    clientY: rect.top + point.y
                }),
                    node = myChart.canvas;
                node?.dispatchEvent(evt);
            }
        }
    }

    useEffect(() => {
        CGPA && setRangeValue(CGPA)
    }, [CGPA])

    // update vertical line on the chart, if range value is changed
    useEffect(() => {
        if (scatterPlot) {
            let pluginList = scatterPlot.props.plugins;
            if (pluginList.length !== 3) {
                setScatterPlot(
                    <Scatter
                        key={count} ref={chartRef}
                        width={600} height={300}
                        plugins={[CGPAVerticalLinePlugin, chartHoverLinePlugin, doNothingPlugin]}
                        data={data}
                        options={myOptions}
                    />
                );        
            }

            // update occurs only if range value is valid
            if (chartData && rangeValue && !isNaN(parseFloat(rangeValue))) {
                let gpaValue = parseFloat(rangeValue).toFixed(2);
                hoverOnChart(gpaValue);
            } 
            setShowToolTip(false);
        }
        setShouldUpdateChart(true);

    }, [rangeValue]);


    useEffect(() => {
        if(shouldUpdateChart){
            data && setScatterPlot(
                <Scatter
                    key={count} ref={chartRef}
                    width={600} height={300}
                    plugins={[CGPAVerticalLinePlugin, rangeVerticalLinePlugin]}
                    data={data}
                    options={myOptions}
                />
            );
        }
    }, [shouldUpdateChart])
    

    useEffect(() => {

        const updateChart = () => {
            if(parseFloat(rangeValue) < parseFloat(minRangeValue))
            {
                setShouldUpdateChart(false)
                setRangeValue(parseFloat(minRangeValue).toFixed(2))
            }
            else if(parseFloat(rangeValue) > parseFloat(maxRangeValue)){
                setShouldUpdateChart(false)
                setRangeValue(parseFloat(maxRangeValue).toFixed(2))
                setIsMaxValidReached(true);
            }
            else{
                data && setScatterPlot(
                    <Scatter
                        key={count} ref={chartRef}
                        width={600} height={300}
                        plugins={[CGPAVerticalLinePlugin, rangeVerticalLinePlugin]}
                        data={data}
                        options={myOptions}
                    />
                );
            }
        }

        updateChart();


    }, [data, tempTargetGPA, rangeValue, isLandscape]);



    // to draw a vertical line at cgpa point of the chart, if cgpa has been updated
    useEffect(() => {
        M.AutoInit();
    }, [CGPA, data]);

    // to increase count
    useEffect(() => {
        setCount(c => c + 1);
    }, [scatterPlot, CGPA]);

    const handleChartMouseMove = () => {

        if (scatterPlot) {
            let pluginList = scatterPlot.props.plugins;
            if(pluginList.length === 2) return

            setScatterPlot(
                <Scatter
                    key={count} ref={chartRef}
                    width={600} height={300}
                    plugins={[CGPAVerticalLinePlugin, rangeVerticalLinePlugin]}
                    data={data}
                    options={myOptions}
                />
            );
        }
    }


    const dropDownClick = (e) => {
        let targetDD = document.getElementById(e.currentTarget.getAttribute('data-target'));

        if (targetDD.classList.contains('hide')) {
            targetDD.classList.remove('hide');
        }
        else {
            targetDD.classList.add('hide');
        }
    }


    const optionClick = (e) => {
        let parentDD = document.getElementById(e.target.getAttribute('data-target'));

        const value = e.target.innerHTML.toString().trim();


        setSelectedValue(value);

        document.querySelector('.gdd--trigger--content').innerHTML = value;

        parentDD.classList.add('hide');
    }

    const dropDown = dropDownList.length > 1 ?
        <div className="gdd--container" data-target="graph-dd">

            <a className="gdd--trigger" data-target="graph-dd" onClick={dropDownClick}>
                <span className="gdd--trigger--content">Medical School</span>
                <i className="material-icons gdd-chevron">expand_more</i>
            </a>

            <ul className="gdd--content hide" id="graph-dd">

                {
                    dropDownList.map(type => (
                        <li key={type} data-target="graph-dd" onClick={optionClick} className="gdd--opt">{type}</li>
                    ))
                }
            </ul>
        </div> : "";



    useEffect(() => {
        let ele = document.querySelector(".chart-wrapper");
        document.addEventListener('click', function (event) {
            var isClickInsideElement = ele.contains(event.target);
            if (!isClickInsideElement) {
                setShowToolTip(false);
            }
        });
    }, [])


    return (
        <div>
            <div className='chart-container'>
                <p className='title'>{title}</p>
                <div className='school-list-container'>

                    {dropDown}

                </div>
                <div className='chart-keybar-wrapper'>
                    <div className='chart-wrapper-container'>
                        <div
                            className='chart-wrapper'
                            // onMouseMove={handleChartMouseMove}
                        >
                            {scatterPlot}
                            {
                                showTooltip ?
                                    <div className="tooltip" style={{ position: 'absolute', color: 'white', top: positionAndData.top, left: positionAndData.left }}>
                                        <p className='tooltip-university'>{positionAndData.school.University}</p>
                                        <div className='acceptance-rate'>
                                            <p>Acceptance Rate: </p>
                                            <p>{(positionAndData.school.AcceptanceRate).toFixed(2)}%</p>
                                        </div>
                                        <div className='acceptance-rate'>
                                            <p>Average GPA: </p>
                                            <p>{(positionAndData.school.AverageGPA).toFixed(2)}</p>
                                        </div>
                                        <div className='acceptance-rate'>
                                            <p>
                                                {
                                                    isForCollege ? "Average SAT" : (selectedValue === "Medical School") ?
                                                        "Average MCAT" : (selectedValue === "PhD Programs") ? "Average GRE"
                                                            : "Average LSAT"
                                                }:
                                            </p>
                                            <p>{
                                                (positionAndData.school[
                                                    isForCollege ? "AverageSAT" : (selectedValue === "Medical School") ?
                                                        "AverageMCAT" : (selectedValue === "PhD Programs") ? "AverageGRE"
                                                            : "AverageLSAT"
                                                ])
                                            }
                                            </p>
                                        </div>
                                        {
                                            (tooltipTableData && tooltipTableData.length > 0) ?
                                                <div className='chart-tooltip-table'>
                                                    <div className="tooltip-header">
                                                        <p></p>
                                                        <p>25th</p>
                                                        <p>50th</p>
                                                        <p>75th</p>
                                                    </div>
                                                    {
                                                        tooltipTableData ? tooltipTableData.map(obj => (
                                                            <div key={obj["title"]} className="tooltip-row">
                                                                <p>{obj["title"]}</p>
                                                                <p>{obj["25th_Per"]}</p>
                                                                <p>{obj["50th_Per"]}</p>
                                                                <p>{obj["75th_Per"]}</p>
                                                            </div>
                                                        )) : null
                                                    }
                                                </div> : null
                                        }
                                    </div> : null
                            }
                        </div>
                        <ChartSlider isForCollege={isForCollege} minRangeValue={minRangeValue} maxRangeValue={maxRangeValue} rangeSliderRef={rangeSliderRef} rangeValue={rangeValue} setRangeValue={setRangeValue} setIsMaxValidReached={setIsMaxValidReached} />
                        <div className="max-val">{chartDataMin}%</div>
                        <div className="min-val">{chartDataMax}%</div>
                    </div>
                    <div className="key-grad">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScatterPlot
