'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rafLoop = require('raf-loop');

var _rafLoop2 = _interopRequireDefault(_rafLoop);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _createConfig = require('./createConfig');

var _createConfig2 = _interopRequireDefault(_createConfig);

var _createRenderer = require('./createRenderer');

var _createRenderer2 = _interopRequireDefault(_createRenderer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Art = function (_Component) {
  _inherits(Art, _Component);

  function Art() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Art);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Art.__proto__ || Object.getPrototypeOf(Art)).call.apply(_ref, [this].concat(args))), _this), _this.refresh = function (config) {
      if (_this.loop) _this.loop.stop();

      _this.loop = (0, _rafLoop2.default)();

      var context = _this.canvas.getContext('2d');
      var background = new window.Image();

      var opts = Object.assign({}, {
        backgroundImage: background,
        context: context
      }, config);

      var pixelRatio = typeof opts.pixelRatio === 'number' ? opts.pixelRatio : 1;

      _this.canvas.width = opts.width * pixelRatio;
      _this.canvas.height = opts.height * pixelRatio;

      background.onload = function () {
        var renderer = (0, _createRenderer2.default)(opts);

        if (opts.debugLuma) renderer.debugLuma();else {
          renderer.clear();

          // let stepCount = 0;

          _this.loop.on('tick', function () {
            renderer.step(opts.interval);

            // stepCount += 1;

            // if (!opts.endlessBrowser && stepCount > opts.steps) this.loop.stop();
          });

          _this.loop.start();
        }
      };

      background.src = config.backgroundSrc;
    }, _this.letterbox = function (element, parent) {
      var el = element;

      var aspect = el.width / el.height;
      var pwidth = parent[0];
      var pheight = parent[1];

      var width = pwidth;
      var height = Math.round(width / aspect);
      var y = Math.floor(pheight - height) / 2;

      el.style.top = y + 'px';
      el.style.width = width + 'px';
      el.style.height = height + 'px';
    }, _this.draw = function () {
      var config = (0, _createConfig2.default)({
        maps: [_this.props.map],
        palettes: [_this.props.palette],
        seed: _this.props.seed,
        height: _this.props.height,
        width: _this.props.width
      });

      _this.refresh(config);

      _this.letterbox(_this.canvas, [_this.props.width, _this.props.height]); // resize

      _this.seed = config.seedName;
      _this.map = config.backgroundSrc;
      _this.palette = config.palette;
    }, _this.stop = function () {
      _this.loop.stop();
    }, _this.ref = function () {
      return _this.canvas;
    }, _this.data = function () {
      return _this.canvas.toDataURL('image/png');
    }, _this.metadata = function () {
      var _this2 = _this,
          seed = _this2.seed,
          map = _this2.map,
          palette = _this2.palette;

      return { seed: seed, map: map, palette: palette };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  // resize and reposition canvas to form a letterbox view


  _createClass(Art, [{
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement('canvas', { ref: function ref(_ref2) {
          return _this3.canvas = _ref2;
        } });
    }
  }]);

  return Art;
}(_react.Component);

Art.propTypes = {
  map: _propTypes2.default.string.isRequired,
  palette: _propTypes2.default.arrayOf(_propTypes2.default.string).isRequired,
  seed: _propTypes2.default.string,
  height: _propTypes2.default.number,
  width: _propTypes2.default.number
};
Art.defaultProps = {
  seed: undefined,
  height: window.innerHeight,
  width: window.innerWidth
};
exports.default = Art;