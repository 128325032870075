// import react
import React from 'react'

// import custom stylesheet
import './AddButton.scss'

/*
    :::::PROPS:::::
    clickHandler => to update states/variables, when a button is clicked
    text => text of the button
*/
const AddButton = ({clickHandler, text}) => {
    return (
        <button onClick={clickHandler} className='add-btn btn-flat transparent'>
            <i className="material-icons left">add</i>
            <p>{text}</p>
        </button>
    )
}

export default AddButton
