export const COLORS_LIST = [
    ["#69D2E7", "#A7DBD8", "#E0E4CC", "#F38630", "#FA6900"],
    ["#FE4365", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#83AF9B"],
    ["#556270", "#4ECDC4", "#C7F464", "#FF6B6B", "#C44D58"],
    ["#490A3D", "#BD1550", "#E97F02", "#F8CA00", "#8A9B0F"],
    ["#00A0B0", "#6A4A3C", "#CC333F", "#EB6841", "#EDC951"],
    ["#E94E77", "#D68189", "#C6A49A", "#C6E5D9", "#F4EAD5"],
    ["#3FB8AF", "#7FC7AF", "#DAD8A7", "#FF9E9D", "#FF3D7F"],
    ["#343838", "#005F6B", "#008C9E", "#00B4CC", "#00DFFC"],
    ["#FF4E50", "#FC913A", "#F9D423", "#EDE574", "#E1F5C4"],
    ["#554236", "#F77825", "#D3CE3D", "#F1EFA5", "#60B99A"],
    ["#351330", "#424254", "#64908A", "#E8CAA4", "#CC2A41"],
    ["#00A8C6", "#40C0CB", "#F9F2E7", "#AEE239", "#8FBE00"],
    ["#FF4242", "#F4FAD2", "#D4EE5E", "#E1EDB9", "#F0F2EB"],
    ["#655643", "#80BCA3", "#F6F7BD", "#E6AC27", "#BF4D28"],
    ["#8C2318", "#5E8C6A", "#88A65E", "#BFB35A", "#F2C45A"],
    ["#FAD089", "#FF9C5B", "#F5634A", "#ED303C", "#3B8183"],
    ["#BCBDAC", "#CFBE27", "#F27435", "#F02475", "#3B2D38"],
    ["#D1E751", "#FFFFFF", "#000000", "#4DBCE9", "#26ADE4"],
    ["#FF9900", "#424242", "#E9E9E9", "#BCBCBC", "#3299BB"],
    ["#5E412F", "#FCEBB6", "#78C0A8", "#F07818", "#F0A830"],
    ["#1B676B", "#519548", "#88C425", "#BEF202", "#EAFDE6"],
    ["#F8B195", "#F67280", "#C06C84", "#6C5B7B", "#355C7D"],
    ["#452632", "#91204D", "#E4844A", "#E8BF56", "#E2F7CE"],
    ["#F04155", "#FF823A", "#F2F26F", "#FFF7BD", "#95CFB7"],
    ["#2A044A", "#0B2E59", "#0D6759", "#7AB317", "#A0C55F"],
    ["#67917A", "#170409", "#B8AF03", "#CCBF82", "#E33258"],
    ["#A3A948", "#EDB92E", "#F85931", "#CE1836", "#009989"],
    ["#E8D5B7", "#0E2430", "#FC3A51", "#F5B349", "#E8D5B9"],
    ["#B3CC57", "#ECF081", "#FFBE40", "#EF746F", "#AB3E5B"],
    ["#515151", "#FFFFFF", "#00B4FF", "#EEEEEE"],
    ["#300030", "#480048", "#601848", "#C04848", "#F07241"],
    ["#1C2130", "#028F76", "#B3E099", "#FFEAAD", "#D14334"],
    ["#CC0C39", "#E6781E", "#C8CF02", "#F8FCC1", "#1693A7"],
    ["#FC354C", "#29221F", "#13747D", "#0ABFBC", "#FCF7C5"],
    ["#230F2B", "#F21D41", "#EBEBBC", "#BCE3C5", "#82B3AE"],
    ["#5C323E", "#A82743", "#E15E32", "#C0D23E", "#E5F04C"],
    ["#4E395D", "#827085", "#8EBE94", "#CCFC8E", "#DC5B3E"],
    ["#DAD6CA", "#1BB0CE", "#4F8699", "#6A5E72", "#563444"],
    ["#D1313D", "#E5625C", "#F9BF76", "#8EB2C5", "#615375"],
    ["#1C0113", "#6B0103", "#A30006", "#C21A01", "#F03C02"],
    ["#CFFFDD", "#B4DEC1", "#5C5863", "#A85163", "#FF1F4C"],
    ["#382F32", "#FFEAF2", "#FCD9E5", "#FBC5D8", "#F1396D"],
    ["#9DC9AC", "#FFFEC7", "#F56218", "#FF9D2E", "#919167"],
    ["#413D3D", "#040004", "#C8FF00", "#FA023C", "#4B000F"],
    ["#A8A7A7", "#CC527A", "#E8175D", "#474747", "#363636"],
    ["#FFEDBF", "#F7803C", "#F54828", "#2E0D23", "#F8E4C1"],
    ["#5E9FA3", "#DCD1B4", "#FAB87F", "#F87E7B", "#B05574"],
    ["#FFFBB7", "#A6F6AF", "#66B6AB", "#5B7C8D", "#4F2958"],
    ["#000000", "#9F111B", "#B11623", "#292C37", "#CCCCCC"],
    ["#0CA5B0", "#4E3F30", "#FEFEEB", "#F8F4E4", "#A5B3AA"],
    ["#4D3B3B", "#DE6262", "#FFB88C", "#FFD0B3", "#F5E0D3"],
    ["#B5AC01", "#ECBA09", "#E86E1C", "#D41E45", "#1B1521"],
    ["#379F7A", "#78AE62", "#BBB749", "#E0FBAC", "#1F1C0D"],
    ["#A70267", "#F10C49", "#FB6B41", "#F6D86B", "#339194"],
    ["#2D2D29", "#215A6D", "#3CA2A2", "#92C7A3", "#DFECE6"],
    ["#11766D", "#410936", "#A40B54", "#E46F0A", "#F0B300"],
    ["#AAFF00", "#FFAA00", "#FF00AA", "#AA00FF", "#00AAFF"],
    ["#6DA67A", "#77B885", "#86C28B", "#859987", "#4A4857"],
    ["#1B325F", "#9CC4E4", "#E9F2F9", "#3A89C9", "#F26C4F"],
    ["#046D8B", "#309292", "#2FB8AC", "#93A42A", "#ECBE13"],
    ["#82837E", "#94B053", "#BDEB07", "#BFFA37", "#E0E0E0"],
    ["#312736", "#D4838F", "#D6ABB1", "#D9D9D9", "#C4FFEB"],
    ["#E5EAA4", "#A8C4A2", "#69A5A4", "#616382", "#66245B"],
    ["#395A4F", "#432330", "#853C43", "#F25C5E", "#FFA566"],
    ["#331327", "#991766", "#D90F5A", "#F34739", "#FF6E27"],
    ["#E21B5A", "#9E0C39", "#333333", "#FBFFE3", "#83A300"],
    ["#EC4401", "#CC9B25", "#13CD4A", "#7B6ED6", "#5E525C"],
    ["#F1396D", "#FD6081", "#F3FFEB", "#ACC95F", "#8F9924"],
    ["#F6F6F6", "#E8E8E8", "#333333", "#990100", "#B90504"],
    ["#261C21", "#6E1E62", "#B0254F", "#DE4126", "#EB9605"],
    ["#FFAB07", "#E9D558", "#72AD75", "#0E8D94", "#434D53"],
    ["#59B390", "#F0DDAA", "#E47C5D", "#E32D40", "#152B3C"],
    ["#FDE6BD", "#A1C5AB", "#F4DD51", "#D11E48", "#632F53"],
    ["#512B52", "#635274", "#7BB0A8", "#A7DBAB", "#E4F5B1"],
    ["#E65540", "#F8ECC2", "#65A8A6", "#79896D"],
    ["#CAFF42", "#EBF7F8", "#D0E0EB", "#88ABC2", "#49708A"],
    ["#595643", "#4E6B66", "#ED834E", "#EBCC6E", "#EBE1C5"],
    ["#058789", "#503D2E", "#D54B1A", "#E3A72F", "#F0ECC9"],
    ["#FF003C", "#FF8A00", "#FABE28", "#88C100", "#00C176"],
    ["#111625", "#341931", "#571B3C", "#7A1E48", "#9D2053"],
    ["#EFEECC", "#FE8B05", "#FE0557", "#400403", "#0AABBA"],
    ["#CCF390", "#E0E05A", "#F7C41F", "#FC930A", "#FF003D"],
    ["#E0EFF1", "#7DB4B5", "#FFFFFF", "#680148", "#000000"],
    ["#F06D61", "#DA825F", "#C4975C", "#A8AB7B", "#8CBF99"],
    ["#2D1B33", "#F36A71", "#EE887A", "#E4E391", "#9ABC8A"],
    ["#2B2726", "#0A516D", "#018790", "#7DAD93", "#BACCA4"],
    ["#95A131", "#C8CD3B", "#F6F1DE", "#F5B9AE", "#EE0B5B"],
    ["#360745", "#D61C59", "#E7D84B", "#EFEAC5", "#1B8798"],
    ["#FC284F", "#FF824A", "#FEA887", "#F6E7F7", "#D1D0D7"],
    ["#027B7F", "#FFA588", "#D62957", "#BF1E62", "#572E4F"],
    ["#80A8A8", "#909D9E", "#A88C8C", "#FF0D51", "#7A8C89"],
    ["#A1DBB2", "#FEE5AD", "#FACA66", "#F7A541", "#F45D4C"],
    ["#641F5E", "#676077", "#65AC92", "#C2C092", "#EDD48E"],
    ["#D4F7DC", "#DBE7B4", "#DBC092", "#E0846D", "#F51441"],
    ["#85847E", "#AB6A6E", "#F7345B", "#353130", "#CBCFB4"],
    ["#11644D", "#A0B046", "#F2C94E", "#F78145", "#F24E4E"],
    ["#23192D", "#FD0A54", "#F57576", "#FEBF97", "#F5ECB7"],
    ["#452E3C", "#FF3D5A", "#FFB969", "#EAF27E", "#3B8C88"],
    ["#041122", "#259073", "#7FDA89", "#C8E98E", "#E6F99D"],
    ["#B1E6D1", "#77B1A9", "#3D7B80", "#270A33", "#451A3E"],
    ["#FDCFBF", "#FEB89F", "#E23D75", "#5F0D3B", "#742365"],
    ["#540045", "#C60052", "#FF714B", "#EAFF87", "#ACFFE9"],
    ["#280904", "#680E34", "#9A151A", "#C21B12", "#FC4B2A"],
    ["#F0FFC9", "#A9DA88", "#62997A", "#72243D", "#3B0819"],
    ["#44749D", "#C6D4E1", "#FFFFFF", "#EBE7E0", "#BDB8AD"],
    ["#FA6A64", "#7A4E48", "#4A4031", "#F6E2BB", "#9EC6B8"],
    ["#1D1313", "#24B694", "#D22042", "#A3B808", "#30C4C9"]
    ["#F6D76B", "#FF9036", "#D6254D", "#FF5475", "#FDEBA9"],
    ["#E7EDEA", "#FFC52C", "#FB0C06", "#030D4F", "#CEECEF"],
    ["#373737", "#8DB986", "#ACCE91", "#BADB73", "#EFEAE4"],
    ["#161616", "#C94D65", "#E7C049", "#92B35A", "#1F6764"],
    ["#26251C", "#EB0A44", "#F2643D", "#F2A73D", "#A0E8B7"],
    ["#4B3E4D", "#1E8C93", "#DBD8A2", "#C4AC30", "#D74F33"],
    ["#F2E8C4", "#98D9B6", "#3EC9A7", "#2B879E", "#616668"],
    ["#5CACC4", "#8CD19D", "#CEE879", "#FCB653", "#FF5254"],
]