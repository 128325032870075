"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var arrayShuffle = function arrayShuffle(arr, random) {
  var rand = void 0;
  var tmp = void 0;

  var len = arr.length;
  var ret = arr.slice();

  while (len) {
    rand = Math.floor(random(1) * len);
    len -= 1;

    tmp = ret[len];
    ret[len] = ret[rand];
    ret[rand] = tmp;
  }

  return ret;
};

exports.default = function (palettes, random) {
  var colors = palettes[Math.floor(random() * palettes.length)];
  // return arrayShuffle(colors, random);
  return colors;
};