"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (out) {
  var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1.0;
  var randFunc = arguments[2];

  var r = randFunc() * 2.0 * Math.PI;

  var s = out;

  s[0] = Math.cos(r) * scale;
  s[1] = Math.sin(r) * scale;

  return s;
};