'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _clamp = require('clamp');

var _clamp2 = _interopRequireDefault(_clamp);

var _lerp = require('lerp');

var _lerp2 = _interopRequireDefault(_lerp);

var _newArray = require('new-array');

var _newArray2 = _interopRequireDefault(_newArray);

var _simplexNoise = require('simplex-noise');

var _simplexNoise2 = _interopRequireDefault(_simplexNoise);

var _glVec = require('gl-vec2');

var _glVec2 = _interopRequireDefault(_glVec);

var _createPixels = require('./createPixels');

var _createPixels2 = _interopRequireDefault(_createPixels);

var _createRange = require('./createRange');

var _createRange2 = _interopRequireDefault(_createRange);

var _createSphere = require('./createSphere');

var _createSphere2 = _interopRequireDefault(_createSphere);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function () {
  var opt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var randFunc = opt.random || Math.random;
  var random = (0, _createRange2.default)(randFunc);

  var simplex = new _simplexNoise2.default(randFunc);
  var ctx = opt.context;
  var dpr = typeof opt.pixelRatio === 'number' ? opt.pixelRatio : 1;
  var canvas = ctx.canvas;
  var width = canvas.width,
      height = canvas.height;

  var count = opt.count || 0;
  var palette = opt.palette || ['#fff', '#000'];
  var backgroundImage = opt.backgroundImage;


  var maxRadius = typeof opt.maxRadius === 'number' ? opt.maxRadius : 10;
  var startArea = typeof opt.startArea === 'number' ? opt.startArea : 0.5;
  var pointilism = (0, _lerp2.default)(0.000001, 0.5, opt.pointilism);
  var noiseScalar = opt.noiseScalar || [0.00001, 0.0001];
  var globalAlpha = typeof opt.globalAlpha === 'number' ? opt.globalAlpha : 1;

  var heightMapImage = (0, _createPixels2.default)(ctx, backgroundImage, {
    scale: opt.backgroundScale,
    fillStyle: opt.backgroundFill
  });

  var heightMap = heightMapImage.data;
  var time = 0;

  var resetParticle = function resetParticle() {
    var particle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var p = particle;
    var scale = Math.min(width, height) / 2;

    p.position = (0, _createSphere2.default)([], random(0, scale * startArea), randFunc);
    p.position[0] += width / 2;
    p.position[1] += height / 2;
    p.radius = random(0.01, maxRadius);
    p.duration = random(1, 500);
    p.time = random(0, p.duration);
    p.velocity = [random(-1, 1), random(-1, 1)];
    p.speed = random(0.5, 2) * dpr;

    // we actually include the background color here
    // this means some strokes may seem to "erase" the other
    // colors, which can add a nice effect
    p.color = palette[Math.floor(random(palette.length))];

    return p;
  };

  var particles = (0, _newArray2.default)(count).map(function () {
    return resetParticle();
  });

  var clear = function clear() {
    ctx.fillStyle = palette[0];
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  };

  var step = function step(dt) {
    time += dt;

    particles.forEach(function (particle) {
      var p = particle;

      var x = p.position[0];
      var y = p.position[1];

      var fx = (0, _clamp2.default)(Math.round(x), 0, canvas.width - 1);
      var fy = (0, _clamp2.default)(Math.round(y), 0, canvas.height - 1);

      var heightIndex = fx + fy * canvas.width;
      var heightValue = heightMap[heightIndex * 4] / 255;

      var pS = (0, _lerp2.default)(noiseScalar[0], noiseScalar[1], heightValue);
      var n = simplex.noise3D(fx * pS, fy * pS, p.duration + time);

      var angle = n * Math.PI * 2;
      var speed = p.speed + (0, _lerp2.default)(0.0, 2, 1 - heightValue);

      _glVec2.default.add(p.velocity, p.velocity, [Math.cos(angle), Math.sin(angle)]);
      _glVec2.default.normalize(p.velocity, p.velocity);

      var move = _glVec2.default.scale([], p.velocity, speed);

      _glVec2.default.add(p.position, p.position, move);

      var s2 = pointilism;

      var r = p.radius * simplex.noise3D(x * s2, y * s2, p.duration + time);
      r *= (0, _lerp2.default)(0.01, 1.0, heightValue);

      ctx.beginPath();
      ctx.lineTo(x, y);
      ctx.lineTo(p.position[0], p.position[1]);
      ctx.lineWidth = r * (p.time / p.duration);
      ctx.lineCap = opt.lineStyle || 'square';
      ctx.lineJoin = opt.lineStyle || 'square';
      ctx.strokeStyle = p.color;

      ctx.globalAlpha = globalAlpha;
      ctx.stroke();

      p.time += dt;

      if (p.time > p.duration) resetParticle(p);
    });
  };

  return {
    clear: clear,
    step: step,
    debugLuma: ctx.putImageData(heightMapImage, 0, 0)
  };
};