import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { SERVER_IP } from '../../IP';

import Artwork from './Artwork';

const ArtworkPage = () => {
    const [artwork, setArtwork] = useState(null);

    window.addEventListener("load", () => {
        setArtwork(<Artwork />)
    })

    return (
        <div className='artwork-page-container'>
            <Helmet>
                <title>Artwork | Memory Maps</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Memory Maps GPA Calculator | Animated Artwork" />
                <meta property="og:description" content="Enjoy algorithmically generated artwork seeded by your unique GPA Calculator inputs" />
                <meta property="og:site_name" content="Memory Maps" />
            </Helmet>
            {
                artwork
            }

        </div>
    )
}

export default ArtworkPage;