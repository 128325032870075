// import react modules
import { useState, useEffect } from 'react';

// import custom modules from the global.js file
import { isFloatingNumber } from '../../../../../global';

// import custom stylesheet
import './CourseRow.scss'

// Materialize CSS import
import M from 'materialize-css';

// UUID for unique IDs;
import { v4 as uuidv4 } from 'uuid';

/*
    :::::PROPS:::::
    course => an object to store detail of a specific course
    updateCourseHandler => a handler to update detail of a specific course of a specific semester
    isWeighted => true, if a user has checked the 'Weighted' switch
*/
const CourseRow = ({ course, updateCourseHandler, isWeighted, isCollegeGPABtnActive }) => {
    // a list of possible grades and their values
    const GRADES = {
        "Grade": -1,
        "A+" : 4.3,
        "A"  : 4.0,
        "A-" : 3.7,
        "B+" : 3.3,
        "B"  : 3.0,
        "B-" : 2.7,
        "C+" : 2.3,
        "C"  : 2.0,
        "C-" : 1.7,
        "D+" : 1.3,
        "D"  : 1.0,
        "D-" : 0.7,
        "F"  : 0.0,
    };

    // a list of possible weights
    const WEIGHTS = ["Weight", "Regular", "Honors", "Enriched", "AP/IB", "College"];

    // to store state of a specific course of a specific semester
    const [courseState, setCourseState] = useState(course);

    // to set value of a specific course field
    const setCourseFields = (e) => {
        const { name, value } = e.target;

        // do not update course, if changes has been made in 'courseCredits' field
        // and its value is not a positive floating point number or exceeding 
        // precsion of 1-decimal place
        if(name === "courseCredits" && !isFloatingNumber(value, 1)){
            return;
        }

        updateCourseHandler(courseState._id, name, value);
        setCourseState({
            ...courseState,
            [name]: value
        })

    };


    
    // to update value of weight field of a specific course,
    // if a user switches between weighted/unweighted modes 
    useEffect(() => {

        var name = "courseWeight", value = "";
        let isCourseUpdated = false;
        if(courseState.courseWeight === "" && isWeighted){
            value = "-1";
            isCourseUpdated = true;
        }
        if(isCourseUpdated){
            updateCourseHandler(courseState._id, name, value);
            setCourseState({
                ...courseState,
                [name]: value
            })
        }

        M.AutoInit();


    }, [isWeighted]);


    
    // Custom dropdown functionality
    let courseGradeID = uuidv4();
    let courseWeightID = uuidv4();

    const handleCustomDropDownClick = (e)=> {

        let allDDS = document.querySelectorAll('.custom-dropdown-content');
        allDDS.forEach(dd => {
            dd.classList.add('hide');
        })

        
        let dataTarget = e.currentTarget.getAttribute('data-target');
        let dd = document.getElementById(dataTarget);
        
        if (dd.classList.contains('hide')) {
            dd.classList.remove('hide');
        }
        else {
            dd.classList.add('hide');
        }
        
    }

    const handleCustomDropDownSelect = (e)=> {

        let dataTarget = e.currentTarget.getAttribute('data-target');

        // hide the dropdown
        let dd = document.getElementById(dataTarget);
        dd.classList.add('hide');


        // change the value in the trigger
        let parentTriggerText = document.querySelectorAll('.custom-dropdown-trigger-text[data-target=' + dataTarget +']');

        // set element value
        parentTriggerText[0].innerHTML = e.currentTarget.innerHTML;
        parentTriggerText[0].classList.add('white-text');

        let parentDropDown = document.querySelectorAll('.custom-dropdown-trigger[data-target=' + dataTarget + ']');

        parentDropDown[0].classList.remove('italic');
        parentDropDown[0].classList.add('white-text');

        let name = e.currentTarget.getAttribute('data-name');
        let value = null;

        // const value = GRADES[e.currentTarget.innerHTML];
        
        if (name === "courseGrade" ) {
            value = GRADES[e.currentTarget.innerHTML];
        }
        else {
            value = e.currentTarget.getAttribute('data-value');
        }


        if(value === -1 || value === "Weight"){
            parentDropDown[0].classList.add('italic');
            parentDropDown[0].classList.remove('white-text');
            parentTriggerText[0].classList.remove('white-text');
        }

        // update these values in the state and remove the previous dropdown
        updateCourseHandler(courseState._id, name, value);
        setCourseState({
            ...courseState,
            [name]: value
        })


    }


    useEffect(() => {
        if(!isWeighted){

        const name = "courseWeight", value = "-1";
            // update these values in the state and remove the previous dropdown
            updateCourseHandler(courseState._id, name, value);
            setCourseState({
                ...courseState,
                [name]: value
            })

        }
    }, [isWeighted])

    return (
        <div className="course-row-wrapper row" >

            <div className={isWeighted ? "course-row row col m9 s9 no-margin no-padding" : "course-row col s12 row no-margin no-padding"}>
                <input
                    type="text" name="courseName"
                    value={courseState.CourseName} onChange={setCourseFields}
                    className='course-name col m5 s5'
                    placeholder='Course Name (Optional)' style={{
                        border: 'none'
                    }}
                />
                <div className='grades-wrapper col m4 s4 grey-text'>
                    <div className="custom-dropdown">
                        <a className="custom-dropdown-trigger italic btn-flat" data-target={"custom-dropdown-" + courseGradeID} onClick={handleCustomDropDownClick}>
                            {/* deal with the on click out */}
                            <span className="custom-dropdown-trigger-text left truncate" data-target={"custom-dropdown-" + courseGradeID}>
                                Grade
                            </span>
                            <i className="material-icons right dd-chevron">expand_more</i>
                        
                        </a>
                        
                        <ul className="custom-dropdown-content hide z-depth-4" id={"custom-dropdown-" + courseGradeID}>
                            {
                                Object.keys(GRADES).map((grade, index) => (
                                    <li className={`${index === 0 ? "grey-text italic": ""}`} data-name="courseGrade" key={grade} data-target={"custom-dropdown-" + courseGradeID} value={GRADES[grade]} onClick={handleCustomDropDownSelect}>{grade}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div>

                <input
                    type="text" className='credits col m3 s3'
                    value={courseState.courseCredits ? courseState.courseCredits : ""} onChange={setCourseFields}
                    name='courseCredits' placeholder="Credits"  style={{
                        border: 'none'
                    }}
                />
            </div>

            {isWeighted && <div className='weights-wrapper col m3 s3'>                
                <div className="custom-dropdown">
                        <a className="custom-dropdown-trigger italic btn-flat" data-target={"custom-dropdown-" + courseWeightID} onClick={handleCustomDropDownClick}>
                            {/* deal with the on click out */}
                            <span className="custom-dropdown-trigger-text left truncate" data-target={"custom-dropdown-" + courseWeightID}>
                                Weight
                            </span>
                            <i className="material-icons right dd-chevron">expand_more</i>                        
                        </a>
                        <ul className="custom-dropdown-content hide z-depth-4" id={"custom-dropdown-" + courseWeightID}>
                            {
                                WEIGHTS.map((weight, index) => (
                                    <li className={`${index === 0 ? "grey-text italic": ""}`} data-name="courseWeight" key={weight} data-target={"custom-dropdown-" + courseWeightID} data-value={weight} onClick={handleCustomDropDownSelect}>{weight}</li>
                                ))
                            }
                        </ul>
                    </div>
            </div>
            }
        </div>
    )
}

export default CourseRow;
